import React, { useState, useCallback, memo } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { findAllParents } from '../../helpers/sharedFunction';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';

const TreeSelector = ({ tree, selected, onCheck, ...props }) => {
  const [checked, setChecked] = useState(selected || []);
  const [expanded, setExpanded] = useState([]);

  const checkModel = props.checkModel || 'all';

  const handleOnCheck = useCallback(
    (checked) => {
      const values = !checked.length
        ? []
        : checkModel === 'leaf'
        ? checked
        : findAllParents(tree, checked);
      setChecked(values);
      onCheck(values);
    },
    [onCheck],
  );

  return (
    <CheckboxTree
      nodes={tree}
      checkModel={checkModel}
      checked={checked}
      expanded={expanded}
      iconsClass="fa5"
      expandOnClick
      showExpandAll
      onCheck={handleOnCheck}
      onExpand={(expanded) => setExpanded(expanded)}
      {...props}
    />
  );
};

export default memo(TreeSelector);
