import types from './actionTypes';
import { addOrReplaceObjectInArray } from '../../../helpers/reducerHelper';

const initialState = {
  loading: false,
  articleContents: [],
  error: '',
};

const articleContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ARTICLE_CONTENT_STATE:
      return initialState;
    case types.CREATE_ARTICLE_CONTENT.REQUEST:
    case types.UPDATE_ARTICLE_CONTENT.REQUEST:
    case types.FETCH_ARTICLE_CONTENT.REQUEST:
    case types.SYNC_ARTICLE_CONTENTS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_ARTICLE_CONTENT.SUCCESS:
    case types.UPDATE_ARTICLE_CONTENT.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.FETCH_ARTICLE_CONTENT.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        articleContents: action.articleContent
          ? addOrReplaceObjectInArray(
              state.articleContents,
              action.articleContent,
              'id',
            )
          : state.articleContents,
      };
    case types.SYNC_ARTICLE_CONTENTS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        articleContents: [...action.articleContents],
      };
    case types.CREATE_ARTICLE_CONTENT.FAILURE:
    case types.UPDATE_ARTICLE_CONTENT.FAILURE:
    case types.FETCH_ARTICLE_CONTENT.FAILURE:
    case types.SYNC_ARTICLE_CONTENTS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default articleContentReducer;
