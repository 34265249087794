const types = {
  RESET_ARTICLE_CONTENT_STATE: 'RESET_ARTICLE_CONTENT_STATE',
  CREATE_ARTICLE_CONTENT: {
    REQUEST: 'CREATE_ARTICLE_CONTENT.REQUEST',
    SUCCESS: 'CREATE_ARTICLE_CONTENT.SUCCESS',
    FAILURE: 'CREATE_ARTICLE_CONTENT.FAILURE',
  },
  UPDATE_ARTICLE_CONTENT: {
    REQUEST: 'UPDATE_ARTICLE_CONTENT.REQUEST',
    SUCCESS: 'UPDATE_ARTICLE_CONTENT.SUCCESS',
    FAILURE: 'UPDATE_ARTICLE_CONTENT.FAILURE',
  },
  SYNC_ARTICLE_CONTENTS: {
    REQUEST: 'SYNC_ARTICLE_CONTENTS.REQUEST',
    SUCCESS: 'SYNC_ARTICLE_CONTENTS.SUCCESS',
    FAILURE: 'SYNC_ARTICLE_CONTENTS.FAILURE',
  },
  FETCH_ARTICLE_CONTENT: {
    REQUEST: 'FETCH_ARTICLE_CONTENT.REQUEST',
    SUCCESS: 'FETCH_ARTICLE_CONTENT.SUCCESS',
    FAILURE: 'FETCH_ARTICLE_CONTENT.FAILURE',
  },
};

export default types;
