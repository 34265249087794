const types = {
  RESET_SYMPTOM_STATE: 'RESET_SYMPTOM_STATE',
  CREATE_SYMPTOM: {
    REQUEST: 'CREATE_SYMPTOM.REQUEST',
    SUCCESS: 'CREATE_SYMPTOM.SUCCESS',
    FAILURE: 'CREATE_SYMPTOM.FAILURE',
  },
  UPDATE_SYMPTOM: {
    REQUEST: 'UPDATE_SYMPTOM.REQUEST',
    SUCCESS: 'UPDATE_SYMPTOM.SUCCESS',
    FAILURE: 'UPDATE_SYMPTOM.FAILURE',
  },
  SYNC_SYMPTOMS: {
    REQUEST: 'SYNC_SYMPTOMS.REQUEST',
    SUCCESS: 'SYNC_SYMPTOMS.SUCCESS',
    FAILURE: 'SYNC_SYMPTOMS.FAILURE',
  },
  FETCH_SYMPTOM: {
    REQUEST: 'FETCH_SYMPTOM.REQUEST',
    SUCCESS: 'FETCH_SYMPTOM.SUCCESS',
    FAILURE: 'FETCH_SYMPTOM.FAILURE',
  },
};

export default types;
