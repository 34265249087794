const blue = '#1446a0';
const indigo = '#00487c';
const purple = '#554971';
const pink = '#dd8e89';
const red = '#a31621';
const orange = '#fe7f2d';
const yellow = '#ebd149';
const green = '#556f44';
const teal = '#050505';
const cyan = '#4797b0';
const gray = '#50514F';
const dark = '#262626';

const white = '#fff';
const gray_100 = '#212529';
const gray_200 = '#2a3042';
const gray_300 = '#32394e';
const gray_400 = '#a6b0cf';
const gray_500 = '#bfc8e2';
const gray_600 = '#c3cbe4';
const gray_700 = '#f6f6f6';
const gray_800 = '#eff2f7';
const gray_900 = '#f8f9fa';
const black = '#000';

const colors = {
  primary: purple,
  secondary: gray,
  success: green,
  info: blue,
  warning: yellow,
  danger: red,
  light: gray_200,
  dark: dark,
  blue,
  indigo,
  purple,
  pink,
  red,
  orange,
  yellow,
  green,
  teal,
  cyan,
  gray,
  grayLight: gray_600,
};

export default colors;
