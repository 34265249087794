import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import 'react-dates/lib/css/_datepicker.css';
import '../../CommonStyle/react_dates_overrides.css';
import { DateRangePicker } from 'react-dates';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';
import isBeforeDay from 'react-dates/lib/utils/isBeforeDay';
import DatePresetButton from './DatePresetButton';
import { datePresets, MIN_DATE } from '../../../config/datePresets';
import FilterLangBox from '../FilterLangBox';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const DateRageFilter = ({
  loading,
  startDate,
  endDate,
  initialFilters,
  minDate,
  maxDate,
  onUpdate,
  showFilters,
}) => {
  const [filters, setFilters] = useState({
    ...initialFilters,
    startDate,
    endDate,
  });
  const [dateFocusInput, setDateFocusInput] = useState(null);

  const handleDatesChange = (ranges) => {
    let { startDate, endDate } = ranges;
    if (!endDate) endDate = startDate;
    if (!startDate) startDate = endDate;
    setFilters((prev) => ({
      ...prev,
      startDate,
      endDate,
    }));
  };

  const onPresetClick = (presetName) => {
    const { startDate, endDate } = datePresets.filter(
      (preset) => preset.name === presetName,
    )[0];
    setFilters((prev) => ({
      ...prev,
      startDate,
      endDate,
    }));
  };

  const handleFilters = (lang) => {
    setFilters(({ startDate, endDate }) => ({
      startDate,
      endDate,
      lang,
    }));
  };

  const { t } = useTranslation();

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody className="date-range-filter text-right">
            {datePresets.map((preset) => (
              <DatePresetButton
                key={preset.name}
                color={
                  moment(preset.startDate).isSame(filters.startDate, 'day') &&
                  moment(preset.endDate).isSame(filters.endDate, 'day')
                    ? 'info'
                    : 'secondary'
                }
                loading={loading}
                range={preset.name}
                title={t(preset.label)}
                onClick={() => onPresetClick(preset.name)}
              />
            ))}
            <DateRangePicker
              //renderCalendarInfo={this.renderDatePresets}
              onDatesChange={(ranges) => handleDatesChange(ranges)}
              onFocusChange={(input) => setDateFocusInput(input)}
              focusedInput={dateFocusInput}
              startDate={filters.startDate}
              endDate={filters.endDate}
              displayFormat="DD/MM/YYYY"
              small={true}
              startDateId="startDateFilter"
              endDateId="endDateIdFilter"
              minDate={minDate || MIN_DATE}
              maxDate={maxDate || moment.utc()}
              disabled={loading}
              minimumNights={0}
              firstDayOfWeek={1}
              numberOfMonths={2}
              hideKeyboardShortcutsPanel={true}
              showDefaultInputIcon={true}
              isOutsideRange={(day) =>
                isAfterDay(day, maxDate) || isBeforeDay(day, minDate)
              }
            />
            <Button
              color="info"
              type="button"
              className="btn btn-primary waves-effect waves-light"
              disabled={loading}
              onClick={() => onUpdate(filters)}
              style={{ marginLeft: '10px' }}>
              {t('Update')}
            </Button>
          </CardBody>
          {showFilters && (
            <FilterLangBox
              onUpdate={handleFilters}
              initialFilters={initialFilters}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default DateRageFilter;
