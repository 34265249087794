import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  InputGroup,
  Alert,
  Collapse,
} from 'reactstrap';
import Select from '@availity/select';
import { Form, Input } from '@availity/form';
import { FieldArray } from 'formik';
import FormErrorMessage from '../Common/FormErrorMessage';
import FileUpload from '../Common/FileUpload';
import HTMLEditor from '../Common/HTMLEditor';
import formValidation from './formValidation';

import * as countriesActions from '../../store/countries/actions';
import { useDispatch } from 'react-redux';

import langs from '../../config/allowedLanguages';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { isEmptyObj } from '../../helpers/sharedFunction';
import utilityLinkTypes from '../../config/utilityLinks';

const FormCountry = ({ loading, country, create, closeModal }) => {
  const [files, setFiles] = useState(null);
  const [errorFile, setErrorFile] = useState('');

  const handleFiles = (name, files) => {
    setFiles((state) => ({ ...state, [name]: files[0] }));
    setErrorFile('');
  };

  const handleRemoveFiles = (name) => {
    let newFiles = { ...files };
    delete newFiles[name];
    setFiles(isEmptyObj(newFiles) ? null : newFiles);
  };

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(
      create
        ? countriesActions.createCountry({
            ...values,
            active: values.active ?? false,
            walkthroughs: values.walkthroughs || [],
            privacies: values.privacies || [],
            files,
          })
        : countriesActions.updateCountry({
            id: country.id,
            ...values,
            ...(files && { files }),
          }),
    );
    closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={country || {}}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values, setFieldValue }) => (
            <>
              <Collapse isOpen={!!errorFile}>
                <Alert color="danger">{errorFile}</Alert>
              </Collapse>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input type="text" name="name" id="name" />
                    <FormErrorMessage name="name" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="lang">Language</Label>
                    <Select name="lang" id="lang" options={langs} />
                    <FormErrorMessage name="lang" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="playerBridId">Player Brid ID</Label>
                    <Input
                      type="number"
                      name="playerBridId"
                      id="playerBridId"
                    />
                    <FormErrorMessage name="playerBridId" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="active"
                        name="active"
                        checked={!!values.active}
                      />
                      <label className="custom-control-label" htmlFor="active">
                        Active?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12" className="mt-2">
                  <FieldArray
                    name="walkthroughs"
                    render={(arrayHelpers) => {
                      const { walkthroughs } = values;
                      return (
                        <FormGroup>
                          <Label
                            htmlFor="walkthroughs"
                            className="font-size-16">
                            Walkthroughs
                          </Label>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline btn-light ml-4"
                            onClick={() =>
                              arrayHelpers.push({
                                title: '',
                                text: '',
                                icon: null,
                              })
                            }>
                            <i className="bx bx-plus font-size-11 align-middle"></i>{' '}
                            Add
                          </button>
                          {Array.isArray(walkthroughs) &&
                            walkthroughs.map((walkthrough, index) => (
                              <Row key={index} className="mb-2">
                                <Col md="4">
                                  <FormGroup>
                                    <Label
                                      htmlFor={`walkthroughs.${index}.title`}>
                                      Title
                                    </Label>
                                    <InputGroup>
                                      <div className="input-group-append">
                                        <button
                                          className="btn btn-danger p-0"
                                          style={{ width: '31px' }}
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }>
                                          <i className="bx bx-trash d-block font-size-15" />
                                        </button>
                                      </div>
                                      <Input
                                        type="text"
                                        name={`walkthroughs.${index}.title`}
                                        id={`walkthroughs.${index}.title`}
                                      />
                                      <FormErrorMessage
                                        name={`walkthroughs.${index}.title`}
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label
                                      htmlFor={`walkthroughs.${index}.text`}>
                                      Text
                                    </Label>
                                    <HTMLEditor
                                      lang="it"
                                      initContents={walkthrough.text || ''}
                                      onChange={(contents) =>
                                        setFieldValue(
                                          `walkthroughs.${index}.text`,
                                          contents,
                                        )
                                      }
                                      height="150px"
                                    />
                                    <FormErrorMessage
                                      name={`walkthroughs.${index}.text`}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="3">
                                  <FormGroup>
                                    <Label
                                      htmlFor={`walkthroughs.${index}.icon`}>
                                      Icon
                                    </Label>
                                    <FileUpload
                                      name={`walkthroughs.${index}.icon`}
                                      accept={{
                                        'image/*': ['.png', '.jpeg', '.jpg'],
                                      }}
                                      maxFiles={1}
                                      uploadMessage="Drop image here or click to upload."
                                      handleFiles={handleFiles}
                                      handleRemoveFiles={handleRemoveFiles}
                                      showPreview
                                      existingFile={walkthrough?.icon}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            ))}
                        </FormGroup>
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12" className="mt-2">
                  <FieldArray
                    name="privacies"
                    render={(arrayHelpers) => {
                      const { privacies } = values;
                      return (
                        <FormGroup>
                          <Label htmlFor="privacies" className="font-size-16">
                            Privacies
                          </Label>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline btn-light ml-4"
                            onClick={() =>
                              arrayHelpers.push({
                                id: '',
                                text: '',
                                confirmLabel: '',
                                declineLabel: '',
                              })
                            }>
                            <i className="bx bx-plus font-size-11 align-middle"></i>{' '}
                            Add
                          </button>
                          {Array.isArray(privacies) &&
                            privacies.map((privacy, index) => (
                              <Row key={index} className="mb-2">
                                <Col md="2">
                                  <FormGroup>
                                    <Label htmlFor={`privacies.${index}.id`}>
                                      ID
                                    </Label>
                                    <InputGroup>
                                      <div className="input-group-append">
                                        <button
                                          className="btn btn-danger p-0"
                                          style={{ width: '31px' }}
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }>
                                          <i className="bx bx-trash d-block font-size-15" />
                                        </button>
                                      </div>
                                      <Input
                                        type="text"
                                        name={`privacies.${index}.id`}
                                        id={`privacies.${index}.id`}
                                      />
                                      <FormErrorMessage
                                        name={`privacies.${index}.id`}
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup>
                                    <Label htmlFor={`privacies.${index}.text`}>
                                      Text
                                    </Label>
                                    <HTMLEditor
                                      lang="it"
                                      initContents={privacy.text || ''}
                                      onChange={(contents) =>
                                        setFieldValue(
                                          `privacies.${index}.text`,
                                          contents,
                                        )
                                      }
                                      height="150px"
                                    />
                                    <FormErrorMessage
                                      name={`privacies.${index}.text`}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="2">
                                  <FormGroup>
                                    <Label
                                      htmlFor={`privacies.${index}.confirmLabel`}>
                                      Confirm Label
                                    </Label>
                                    <InputGroup>
                                      <Input
                                        type="text"
                                        name={`privacies.${index}.confirmLabel`}
                                        id={`privacies.${index}.confirmLabel`}
                                      />
                                      <FormErrorMessage
                                        name={`privacies.${index}.confirmLabel`}
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                </Col>
                                <Col md="2">
                                  <FormGroup>
                                    <Label
                                      htmlFor={`privacies.${index}.declineLabel`}>
                                      Decline Label
                                    </Label>
                                    <Input
                                      type="text"
                                      name={`privacies.${index}.declineLabel`}
                                      id={`privacies.${index}.declineLabel`}
                                    />

                                    <FormErrorMessage
                                      name={`privacies.${index}.declineLabel`}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            ))}
                        </FormGroup>
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12" className="mt-2">
                  <FieldArray
                    name="utilityLinks"
                    render={(arrayHelpers) => {
                      const { utilityLinks } = values;
                      return (
                        <FormGroup>
                          <Label
                            htmlFor="utilityLinks"
                            className="font-size-16">
                            Utility Links
                          </Label>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline btn-light ml-4"
                            onClick={() =>
                              arrayHelpers.push({
                                label: '',
                                link: '',
                              })
                            }>
                            <i className="bx bx-plus font-size-11 align-middle"></i>{' '}
                            Add
                          </button>
                          {Array.isArray(utilityLinks) &&
                            utilityLinks.map((utilityLink, index) => (
                              <Row key={index} className="mb-2">
                                <Col md="4">
                                  <FormGroup>
                                    <Label
                                      htmlFor={`utilityLinks.${index}.label`}>
                                      Label
                                    </Label>
                                    <InputGroup>
                                      <div className="input-group-append">
                                        <button
                                          className="btn btn-danger p-0"
                                          style={{ width: '31px' }}
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }>
                                          <i className="bx bx-trash d-block font-size-15" />
                                        </button>
                                      </div>
                                      <Input
                                        type="text"
                                        name={`utilityLinks.${index}.label`}
                                        id={`utilityLinks.${index}.label`}
                                      />
                                      <FormErrorMessage
                                        name={`utilityLinks.${index}.label`}
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup>
                                    <Label
                                      htmlFor={`utilityLinks.${index}.link`}>
                                      Link
                                    </Label>
                                    <Input
                                      type="text"
                                      name={`utilityLinks.${index}.link`}
                                      id={`utilityLinks.${index}.link`}
                                    />
                                    <FormErrorMessage
                                      name={`utilityLinks.${index}.link`}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="3">
                                  <FormGroup>
                                    <Label htmlFor="type">Type</Label>
                                    <Select
                                      name={`utilityLinks.${index}.type`}
                                      id={`utilityLinks.${index}.type`}
                                      options={utilityLinkTypes}
                                    />
                                    <FormErrorMessage
                                      name={`utilityLinks.${index}.type`}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            ))}
                        </FormGroup>
                      );
                    }}
                  />
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton disabled={!isValid || loading} create={create} />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormCountry;
