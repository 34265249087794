import React, { useState, useEffect, useMemo } from 'react';

import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import * as contestsActions from '../../store/contests/actions';
import { useDispatch, useSelector } from 'react-redux';

import FormContest from '../../components/Contests/FormContest';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import createTableColumns from '../../components/Contests/tableColumns';
import { CreateButton } from '../../components/Common/DataTable/tableActionButtons';

const List = (props) => {
  const loading = useSelector((state) => state.Contest.loading);
  const contests = useSelector((state) => state.Contest.contests);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedContest, setSelectedContest] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(contestsActions.syncContests());
  }, []);

  const openEditModal = (contest = null) => {
    setSelectedContest(contest);
    setEditModal(true);
  };

  const openViewModal = (contest) => {
    setSelectedContest(contest);
    setViewModal(true);
  };

  const columns = useMemo(
    () => createTableColumns(openViewModal, openEditModal),
    [],
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Contests" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <CreateButton
                      label="Create new Contest"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={contests}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            title="Contest"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement element={selectedContest} icon="bx bx-world" />
          </Modal>
          <Modal
            size="xl"
            title="Contest"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormContest
              closeModal={() => setEditModal(false)}
              create={!selectedContest}
              loading={loading}
              contest={selectedContest}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
