import types from './actionTypes';
import initialFilters from '../../config/filters';
import { addOrReplaceObjectInArray } from '../../helpers/reducerHelper';

const initialState = {
  loading: false,
  members: [],
  startDate: null,
  endDate: null,
  filters: { ...initialFilters },
  error: '',
};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_MEMBER_STATE:
      return initialState;
    case types.CREATE_MEMBER.REQUEST:
    case types.FETCH_MEMBERS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_MEMBER.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        members: [...state.members, action.member],
      };
    case types.UPDATE_MEMBER.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        members: addOrReplaceObjectInArray(
          state.members,
          action.member,
          'id',
          'merge',
        ),
      };
    case types.FETCH_MEMBERS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        members: action.members,
        startDate: action.startDate,
        endDate: action.endDate,
        filters: { ...action.filters },
      };
    case types.CREATE_MEMBER.FAILURE:
    case types.UPDATE_MEMBER.FAILURE:
    case types.FETCH_MEMBERS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default memberReducer;
