import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as webContentsActions from '../../store/webContents/actions';
import { useDispatch, useSelector } from 'react-redux';
import FormWebContent from '../../components/WebContents/FormWebContent';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/WebContents/tableColumns';
import {
  CreateButton,
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const List = (props) => {
  const loading = useSelector((state) => state.WebContent.loading);
  const webContents = useSelector((state) => state.WebContent.webContents);

  const [selectedWebContent, setSelectedWebContent] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const startDate = useSelector((state) => state.WebContent.startDate);
  const endDate = useSelector((state) => state.WebContent.endDate);
  const dataFilters = useSelector((state) => state.WebContent.filters);
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('month'),
    endDate: momentEndDate || moment.utc(),
    lang: dataFilters?.lang || '',
  });

  const dispatch = useDispatch();

  const refresh = useCallback(({ startDate, endDate, lang }) => {
    const selectedStartDate = startDate.utc().startOf('day').toDate();
    const selectedEndDate = endDate.utc().endOf('day').toDate();
    dispatch(
      webContentsActions.fetchWebContents(selectedStartDate, selectedEndDate, {
        lang,
      }),
    );
    setSelectedFilters({ startDate, endDate, lang });
  });

  useEffect(() => {
    webContents.length === 0 && refresh(selectedFilters);
  }, []);

  const openViewModal = (webContent) => {
    setSelectedWebContent(webContent);
    setViewModal(true);
  };

  const openEditModal = (webContent = null) => {
    setSelectedWebContent(webContent);
    setEditModal(true);
  };

  const { t } = useTranslation();

  const columns = useMemo(
    () => createTableColumns(openViewModal, openEditModal, t),
    [],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Web Contents"
            breadcrumbItem="List"
            onRefresh={() => refresh(selectedFilters)}
            loading={loading}
          />
          <DateRangeFilter
            onUpdate={refresh}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
            showFilters
            initialFilters={selectedFilters}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Web Content"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={webContents}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Web Content"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedWebContent}
              icon="bx bx-user-pin"
            />
          </Modal>
          <Modal
            size="xl"
            title="WebContent"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormWebContent
              closeModal={() => setEditModal(false)}
              create={!selectedWebContent}
              loading={loading}
              webContent={selectedWebContent}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
