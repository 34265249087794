export const GENDERS = [
  {
    label: 'Maschio',
    value: 1,
  },
  {
    label: 'Femmina',
    value: 2,
  },
  {
    label: 'Maschio e Femmina',
    value: 3,
  },
];

export const SYMPTOM_TYPES = [
  {
    label: 'Sintomo / Malattia',
    value: 1,
  },
  {
    label: 'Emergenza',
    value: 2,
  },
];

export const TIME_PERIODS = [
  {
    label: 'Primo anno',
    value: 1,
  },
  {
    label: 'Età prescolare (2-5 anni)',
    value: 2,
  },
  {
    label: 'Età scolare (6-14 anni)',
    value: 3,
  },
  {
    label: 'Adolescenza (14+)',
    value: 4,
  },
];

export const BODY_PARTS = [
  { label: 'Testa', value: 1 },
  { label: 'Occhi', value: 2 },
  { label: 'Naso', value: 3 },
  { label: 'Bocca', value: 4 },
  { label: 'Orecchie', value: 5 },
  { label: 'Collo', value: 6 },
  { label: 'Braccia e mani', value: 7 },
  { label: 'Torace', value: 8 },
  { label: 'Addome', value: 9 },
  { label: 'Genitali', value: 10 },
  { label: 'Vie urinarie', value: 11 },
  { label: 'Gambe e piedi', value: 12 },
  { label: 'Pelle', value: 13 },
  { label: 'Vie respiratorie', value: 14 },
  { label: 'Sistema gastrointestinale', value: 15 },
  { label: 'Sistema nervoso', value: 16 },
  { label: 'Cuore e vasi sanguigni', value: 17 },
];
