import React, { useState, useEffect } from 'react';

import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';

import * as articlesActions from '../../store/articles/actions';
import { useDispatch, useSelector } from 'react-redux';

import FormArticle from '../../components/Articles/FormArticle';
import FormArticleContent from '../../components/Articles/contents/FormArticleContent';
import FormPushNotification from '../../components/PushNotifications/FormPushNotification';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import createTableColumns from '../../components/Articles/tableColumns';
import { CreateButton } from '../../components/Common/DataTable/tableActionButtons';

const List = (props) => {
  const loading = useSelector((state) => state.Article.loading);
  const articles = useSelector((state) => state.Article.articles);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [langModal, setLangModal] = useState(false);
  const [pushNotificationModal, setPushNotificationModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedLang, setSelectedLang] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(articlesActions.syncArticles());
  }, []);

  const openEditModal = (article = null) => {
    setSelectedArticle(article);
    setEditModal(true);
  };

  const openViewModal = (article) => {
    setSelectedArticle(article);
    setViewModal(true);
  };

  const openLangModal = (article, lang) => {
    setSelectedArticle(article);
    setSelectedLang(lang);
    setLangModal(true);
  };

  const openPushNotificationModal = (article) => {
    setSelectedArticle(article);
    setPushNotificationModal(true);
  };

  const columns = createTableColumns(
    openViewModal,
    openEditModal,
    openLangModal,
    openPushNotificationModal,
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Articles" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="text-sm-right">
                    <CreateButton
                      label="Create new Article"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                  </div>
                  <DataTableNext
                    loading={loading}
                    rows={articles}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            title="Article"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedArticle}
              collection="articles"
              icon="dripicons-article"
            />
          </Modal>
          <Modal
            size="xl"
            title="Article"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormArticle
              closeModal={() => setEditModal(false)}
              create={!selectedArticle}
              loading={loading}
              article={selectedArticle}
            />
          </Modal>
          <Modal
            size="xl"
            title={`${selectedArticle?.name} - ${selectedLang?.label}`}
            isOpen={langModal}
            toggle={() => setLangModal(!langModal)}
            scrollable={false}>
            <FormArticleContent
              closeModal={() => setLangModal(false)}
              article={selectedArticle}
              lang={selectedLang?.value}
            />
          </Modal>
          <Modal
            size="xl"
            title="Push Notification"
            isOpen={pushNotificationModal}
            toggle={() => setPushNotificationModal(!pushNotificationModal)}
            scrollable={false}>
            <FormPushNotification
              closeModal={() => setPushNotificationModal(false)}
              create
              loading={loading}
              content={selectedArticle}
              contentType="article"
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
