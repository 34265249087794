import React from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Breadcrumb = (props) => {
  const onRefresh = () => {
    props.onRefresh();
  };

  const onImportRevenues = () => {
    props.onImportRevenues();
  };

  const onImportVast = () => {
    props.onImportVast();
  };

  const onClickButtons = (index) => {
    const onClick = props.onClickButtons[index];
    onClick();
  };

  const { t } = useTranslation();

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center">
          <h4 className="mb-0 font-size-18">{props.title}</h4>
          {props.onRefresh && (
            <button
              type="button"
              disabled={props.loading}
              className="btn btn-sm btn-outline-dark waves-effect"
              style={{ marginLeft: '10px' }}
              onClick={onRefresh}>
              {props.loading ? (
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
              ) : (
                <i className="bx bx-refresh font-size-16 align-middle mr-2"></i>
              )}
              {t('Refresh')}
            </button>
          )}
          {props.onImportRevenues && (
            <button
              type="button"
              disabled={props.loading}
              className="btn btn-sm btn-outline-primary waves-effect"
              style={{ marginLeft: '10px' }}
              onClick={onImportRevenues}>
              <i className="bx bx-import font-size-16 align-middle mr-2"></i>
              {t('Import Revenues')}
            </button>
          )}
          {props.onClickButtons &&
            props.onClickButtons.map((btn, index) => (
              <button
                key={'ClickButton_' + index}
                type="button"
                disabled={props.loading}
                className="btn btn-sm btn-outline-info waves-effect"
                style={{ marginLeft: '10px' }}
                onClick={() => onClickButtons(index)}>
                <i
                  className={`${props.iconButtons[index]} font-size-16 align-middle mr-2`}></i>
                {props.titleButtons[index]}
              </button>
            ))}
          {props.onImportVast && (
            <button
              type="button"
              disabled={props.loading}
              className="btn btn-sm btn-outline-primary waves-effect"
              style={{ marginLeft: '10px' }}
              onClick={onImportVast}>
              <i className="bx bx-import font-size-16 align-middle mr-2"></i>
              {t('Import Vast URLs')}
            </button>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;
