import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as countriesActions from './actions';

import {
  getFirestore,
  query,
  collection,
  doc,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import { uploadFileToS3 } from '../../helpers/file';

import toastr from 'toastr';
import { toDateFirebase } from '../../helpers/sharedFunction';

const STORAGE_BASE_PATH = 'countries';
const db = getFirestore(rsf.app);

const checkArrayFieldExists = (obj, key) => {
  const [arrayField, index] = key.split('.');
  return obj[arrayField] && obj[arrayField][index];
};

function* uploadFile(file, path, name) {
  const url = yield call(uploadFileToS3, file, path);
  return {
    name,
    url,
  };
}

function* createCountrySaga({ country }) {
  try {
    const countryRef = collection(db, 'countries');
    const id = countryRef.id;

    let iconURLs;
    if (country.files) {
      iconURLs = yield all(
        Object.keys(country.files)
          .filter((name) => checkArrayFieldExists(country, name))
          .map((name) => {
            const path = `${STORAGE_BASE_PATH}/${id}/${country.files[name].path}`;
            return call(uploadFile, country.files[name], path, name);
          }),
      );

      iconURLs.forEach(({ name, url }) => {
        const [arrayField, index, field] = name.split('.');
        country[arrayField][index][field] = url;
      });
    }

    delete country.files;

    yield call(rsf.firestore.addDocument, countryRef, {
      ...country,
      createdAt: serverTimestamp(),
    });
    yield put(countriesActions.createCountrySuccess(country));
    toastr.success('Country created!', '');
  } catch (error) {
    yield put(countriesActions.createCountryFailure(error));
  }
}

function* updateCountrySaga({ country }) {
  try {
    const { id, files } = country;
    const countryRef = doc(db, 'countries', id);

    let iconURLs;
    if (files) {
      iconURLs = yield all(
        Object.keys(country.files)
          .filter((name) => checkArrayFieldExists(country, name))
          .map((name) => {
            const path = `${STORAGE_BASE_PATH}/${id}/${country.files[name].path}`;
            return call(uploadFile, country.files[name], path, name);
          }),
      );
      iconURLs.forEach(({ name, url }) => {
        const [arrayField, index, field] = name.split('.');
        country[arrayField][index][field] = url;
      });
    }

    delete country.id;
    delete country.files;
    delete country.createdAt;

    yield call(
      rsf.firestore.setDocument,
      countryRef,
      {
        ...country,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(countriesActions.updateCountrySuccess(country));
    toastr.success('Country updated!', '');
  } catch (error) {
    yield put(countriesActions.updateCountryFailure(error));
  }
}

const countryTransformer = (payload) => {
  let countries = [];

  payload.forEach((country) => {
    const data = country.data();
    countries.push({
      id: country.id,
      ...data,
      ...(data.createdAt && {
        createdAt: toDateFirebase(country, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(country, data, 'updatedAt').toDate(),
      }),
    });
  });

  return countries;
};

function* syncCountriesSaga() {
  const countriesRef = query(
    collection(db, 'countries'),
    orderBy('createdAt', 'desc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, countriesRef, {
    successActionCreator: countriesActions.syncCountriesSuccess,
    failureActionCreator: countriesActions.syncCountriesFailure,
    transform: (payload) => countryTransformer(payload),
  });

  yield take(types.RESET_COUNTRY_STATE);
  yield cancel(task);
}

function* countrySaga() {
  yield all([
    takeLatest(types.SYNC_COUNTRIES.REQUEST, syncCountriesSaga),
    takeLeading(types.CREATE_COUNTRY.REQUEST, createCountrySaga),
    takeLeading(types.UPDATE_COUNTRY.REQUEST, updateCountrySaga),
  ]);
}

export default countrySaga;
