import React, { useEffect, useState } from 'react';
import { Col, CardBody } from 'reactstrap';
import { Form } from '@availity/form';
import Select from '@availity/select';
import '@availity/yup';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import * as countriesActions from '../../store/countries/actions';
import { useTranslation } from 'react-i18next';

const FilterLangBox = ({ initialFilters, onUpdate }) => {
  const [lang, setLang] = useState(initialFilters?.lang || '');
  const countries = useSelector((state) => state.Country.countries);

  const countriesForSelect = countries.map(({ name, lang }) => ({
    label: name,
    value: lang,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(countriesActions.syncCountries());
  }, []);

  const handleFilters = (values) => {
    setLang(values);
    onUpdate(values);
  };

  const { t } = useTranslation();

  return (
    <CardBody className="pt-0">
      <Form
        initialValues={{ lang }}
        validationSchema={yup.object().shape({
          lang: yup.string(),
        })}
        className="d-flex">
        <Col md="3" className="d-flex align-items-center px-0">
          <h5 className="text-muted m-0 mr-2">{t('Language')}:</h5>
          <div className="flex-fill">
            <Select
              name="lang"
              id="lang"
              isMulti={false}
              value={lang}
              onChange={handleFilters}
              options={[{ label: t('All'), value: '' }, ...countriesForSelect]}
            />
          </div>
        </Col>
      </Form>
    </CardBody>
  );
};

export default FilterLangBox;
