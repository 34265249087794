import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_WEB_CONTENT_STATE,
});

export const createWebContent = (webContent) => ({
  type: types.CREATE_WEB_CONTENT.REQUEST,
  webContent,
});

export const createWebContentSuccess = (webContent) => ({
  type: types.CREATE_WEB_CONTENT.SUCCESS,
  webContent,
});

export const createWebContentFailure = (error) => ({
  type: types.CREATE_WEB_CONTENT.FAILURE,
  error,
});

export const updateWebContent = (webContent) => ({
  type: types.UPDATE_WEB_CONTENT.REQUEST,
  webContent,
});

export const updateWebContentSuccess = (webContent) => ({
  type: types.UPDATE_WEB_CONTENT.SUCCESS,
  webContent,
});

export const updateWebContentFailure = (error) => ({
  type: types.UPDATE_WEB_CONTENT.FAILURE,
  error,
});

export const fetchWebContents = (startDate, endDate, filters = null) => ({
  type: types.FETCH_WEB_CONTENTS.REQUEST,
  startDate,
  endDate,
  filters,
});

export const fetchWebContentsSuccess = (
  webContents,
  startDate,
  endDate,
  filters,
) => ({
  type: types.FETCH_WEB_CONTENTS.SUCCESS,
  webContents,
  startDate,
  endDate,
  filters,
});

export const fetchWebContentsFailure = (error) => ({
  type: types.FETCH_WEB_CONTENTS.FAILURE,
  error,
});
