import '@availity/yup';
import * as yup from 'yup';

// //https://gist.githubusercontent.com/stephenway/19f46c2dca4cca601ed296e1ceeaec3d/raw/1073dbe434554b293a6bc04c283b57b8e64573ff/uniqueValidationMethod.js
// yup.addMethod(yup.array, 'unique', function (message, mapper = (a) => a) {
//   return this.test('unique', message, function (list) {
//     if (!Array.isArray(list)) return true;
//     return list.length === new Set(list.map(mapper)).size;
//   });
// });

const formValidation = yup.object().shape({
  title: yup.string().label('video title').required(),
  videoBridId: yup.number(),
  published: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  videoUrl: yup
    .string()
    .matches(/(?:((?:https|http):\/\/)|(?:\/)).+(?:.mpeg|mpg|mp4|m4v|m3u8)/, {
      message: 'file format not supported',
      excludeEmptyString: true,
    }),
  ballonTags: yup.array().of(
    yup.object({
      order: yup.number().label('ballon tag order').required().min(1).max(8),
      title: yup.string().label('ballon tag title').required(),
    }),
  ),
  //.unique('duplicate order', (a) => a.order),
});

export default formValidation;
