import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_MEMBER_STATE,
});

export const createMember = (member) => ({
  type: types.CREATE_MEMBER.REQUEST,
  member,
});

export const createMemberSuccess = (member) => ({
  type: types.CREATE_MEMBER.SUCCESS,
  member,
});

export const createMemberFailure = (error) => ({
  type: types.CREATE_MEMBER.FAILURE,
  error,
});

export const updateMember = (member) => ({
  type: types.UPDATE_MEMBER.REQUEST,
  member,
});

export const updateMemberSuccess = (member) => ({
  type: types.UPDATE_MEMBER.SUCCESS,
  member,
});

export const updateMemberFailure = (error) => ({
  type: types.UPDATE_MEMBER.FAILURE,
  error,
});

export const fetchMembers = (startDate, endDate, filters = null) => ({
  type: types.FETCH_MEMBERS.REQUEST,
  startDate,
  endDate,
  filters,
});

export const fetchMembersSuccess = (members, startDate, endDate, filters) => ({
  type: types.FETCH_MEMBERS.SUCCESS,
  members,
  startDate,
  endDate,
  filters,
});

export const fetchMembersFailure = (error) => ({
  type: types.FETCH_MEMBERS.FAILURE,
  error,
});
