import React from 'react';
import { Row, Col, Card } from 'reactstrap';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { isLoading } from '../../selectors/dashboard';
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { useTranslation } from 'react-i18next';

const AdminDashboard = (props) => {
  const loading = useSelector(isLoading);

  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs title={t('Dashboard')} loading={loading} />
      <Row>
        <Col md="4">
          <Card>
            <div className="text-center p-4 border-end">
              <div className="avatar-sm mx-auto mb-3 mt-1">
                <span className="avatar-title rounded-circle bg-soft bg-primary primary text-white font-size-16">
                  <i className="dripicons-article font-size-20"></i>
                </span>
              </div>
              <h4 className="text-truncate pb-1">Article</h4>
            </div>
            <div className="text-center text-xl-start mb-2">
              <Link
                to="/articles/list"
                className="text-decoration-underline text-reset font-size-14">
                See all Articles{' '}
                <i className="mdi mdi-arrow-right font-size-14"></i>
              </Link>
            </div>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <div className="text-center p-4 border-end">
              <div className="avatar-sm mx-auto mb-3 mt-1">
                <span className="avatar-title rounded-circle bg-soft bg-secondary secondary text-white font-size-16">
                  <i className="bx bx-video font-size-20"></i>
                </span>
              </div>
              <h4 className="text-truncate pb-1">Video</h4>
            </div>
            <div className="text-center text-xl-start mb-2">
              <Link
                to="/videos/list"
                className="text-decoration-underline text-reset font-size-14">
                See all Videos{' '}
                <i className="mdi mdi-arrow-right font-size-14"></i>
              </Link>
            </div>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <div className="text-center p-4 border-end">
              <div className="avatar-sm mx-auto mb-3 mt-1">
                <span className="avatar-title rounded-circle bg-soft bg-info info text-white font-size-16">
                  <i className="mdi mdi-hospital-box-outline font-size-20"></i>
                </span>
              </div>
              <h4 className="text-truncate pb-1">Symptom</h4>
            </div>
            <div className="text-center text-xl-start mb-2">
              <Link
                to="/symptoms/list"
                className="text-decoration-underline text-reset font-size-14">
                See all Symptoms{' '}
                <i className="mdi mdi-arrow-right font-size-14"></i>
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Card>
            <div className="text-center p-4 border-end">
              <div className="avatar-sm mx-auto mb-3 mt-1">
                <span className="avatar-title rounded-circle bg-soft bg-warning info text-white font-size-16">
                  <i className="dripicons-broadcast font-size-20"></i>
                </span>
              </div>
              <h4 className="text-truncate pb-1">Contest</h4>
            </div>
            <div className="text-center text-xl-start mb-2">
              <Link
                to="/contests/list"
                className="text-decoration-underline text-reset font-size-14">
                See all Contests{' '}
                <i className="mdi mdi-arrow-right font-size-14"></i>
              </Link>
            </div>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <div className="text-center p-4 border-end">
              <div className="avatar-sm mx-auto mb-3 mt-1">
                <span className="avatar-title rounded-circle bg-soft bg-primary info text-white font-size-16">
                  <i className="bx bx-receipt font-size-20"></i>
                </span>
              </div>
              <h4 className="text-truncate pb-1">Web Content</h4>
            </div>
            <div className="text-center text-xl-start mb-2">
              <Link
                to="/webContents/list"
                className="text-decoration-underline text-reset font-size-14">
                See all Web Contents{' '}
                <i className="mdi mdi-arrow-right font-size-14"></i>
              </Link>
            </div>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <div className="text-center p-4 border-end">
              <div className="avatar-sm mx-auto mb-3 mt-1">
                <span className="avatar-title rounded-circle bg-soft bg-info info text-white font-size-16">
                  <i className="bx bx-user-pin font-size-20"></i>
                </span>
              </div>
              <h4 className="text-truncate pb-1">Member</h4>
            </div>
            <div className="text-center text-xl-start mb-2">
              <Link
                to="/members/list"
                className="text-decoration-underline text-reset font-size-14">
                See all Members{' '}
                <i className="mdi mdi-arrow-right font-size-14"></i>
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AdminDashboard;
