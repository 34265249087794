const types = {
  RESET_VIDEO_CONTENT_STATE: 'RESET_VIDEO_CONTENT_STATE',
  CREATE_VIDEO_CONTENT: {
    REQUEST: 'CREATE_VIDEO_CONTENT.REQUEST',
    SUCCESS: 'CREATE_VIDEO_CONTENT.SUCCESS',
    FAILURE: 'CREATE_VIDEO_CONTENT.FAILURE',
  },
  UPDATE_VIDEO_CONTENT: {
    REQUEST: 'UPDATE_VIDEO_CONTENT.REQUEST',
    SUCCESS: 'UPDATE_VIDEO_CONTENT.SUCCESS',
    FAILURE: 'UPDATE_VIDEO_CONTENT.FAILURE',
  },
  SYNC_VIDEO_CONTENTS: {
    REQUEST: 'SYNC_VIDEO_CONTENTS.REQUEST',
    SUCCESS: 'SYNC_VIDEO_CONTENTS.SUCCESS',
    FAILURE: 'SYNC_VIDEO_CONTENTS.FAILURE',
  },
  FETCH_VIDEO_CONTENT: {
    REQUEST: 'FETCH_VIDEO_CONTENT.REQUEST',
    SUCCESS: 'FETCH_VIDEO_CONTENT.SUCCESS',
    FAILURE: 'FETCH_VIDEO_CONTENT.FAILURE',
  },
};

export default types;
