import types from './actionTypes';
import { addOrReplaceObjectInArray } from '../../../helpers/reducerHelper';

const initialState = {
  loading: false,
  symptomContents: [],
  error: '',
};

const symptomContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_SYMPTOM_CONTENT_STATE:
      return initialState;
    case types.CREATE_SYMPTOM_CONTENT.REQUEST:
    case types.UPDATE_SYMPTOM_CONTENT.REQUEST:
    case types.FETCH_SYMPTOM_CONTENT.REQUEST:
    case types.SYNC_SYMPTOM_CONTENTS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_SYMPTOM_CONTENT.SUCCESS:
    case types.UPDATE_SYMPTOM_CONTENT.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.FETCH_SYMPTOM_CONTENT.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        symptomContents: action.symptomContent
          ? addOrReplaceObjectInArray(
              state.symptomContents,
              action.symptomContent,
              'id',
            )
          : state.symptomContents,
      };
    case types.SYNC_SYMPTOM_CONTENTS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        symptomContents: [...action.symptomContents],
      };
    case types.CREATE_SYMPTOM_CONTENT.FAILURE:
    case types.UPDATE_SYMPTOM_CONTENT.FAILURE:
    case types.FETCH_SYMPTOM_CONTENT.FAILURE:
    case types.SYNC_SYMPTOM_CONTENTS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default symptomContentReducer;
