import React, { useState, useEffect } from 'react';

import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';

import * as videosActions from '../../store/videos/actions';
import { useDispatch, useSelector } from 'react-redux';

import FormVideo from '../../components/Videos/FormVideo';
import FormVideoContent from '../../components/Videos/contents/FormVideoContent';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import createTableColumns from '../../components/Videos/tableColumns';
import { CreateButton } from '../../components/Common/DataTable/tableActionButtons';

const List = (props) => {
  const loading = useSelector((state) => state.Video.loading);
  const videos = useSelector((state) => state.Video.videos);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [langModal, setLangModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedLang, setSelectedLang] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(videosActions.syncVideos());
  }, []);

  const openEditModal = (video = null) => {
    setSelectedVideo(video);
    setEditModal(true);
  };

  const openViewModal = (video) => {
    setSelectedVideo(video);
    setViewModal(true);
  };

  const openLangModal = (video, lang) => {
    setSelectedVideo(video);
    setSelectedLang(lang);
    setLangModal(true);
  };

  const columns = createTableColumns(
    openViewModal,
    openEditModal,
    openLangModal,
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Videos" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="text-sm-right">
                    <CreateButton
                      label="Create new Video"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                  </div>
                  <DataTableNext
                    loading={loading}
                    rows={videos}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            title="Video"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement element={selectedVideo} icon="bx bx-video" />
          </Modal>
          <Modal
            size="xl"
            title="Video"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormVideo
              closeModal={() => setEditModal(false)}
              create={!selectedVideo}
              loading={loading}
              video={selectedVideo}
            />
          </Modal>
          <Modal
            size="xl"
            title={`${selectedVideo?.name} - ${selectedLang?.label}`}
            isOpen={langModal}
            toggle={() => setLangModal(!langModal)}
            scrollable={false}>
            <FormVideoContent
              closeModal={() => setLangModal(false)}
              video={selectedVideo}
              lang={selectedLang?.value}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
