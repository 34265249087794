import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_ARTICLE_CONTENT_STATE,
});

export const createArticleContent = (articleContent, article) => ({
  type: types.CREATE_ARTICLE_CONTENT.REQUEST,
  articleContent,
  article,
});

export const createArticleContentSuccess = () => ({
  type: types.CREATE_ARTICLE_CONTENT.SUCCESS,
});

export const createArticleContentFailure = (error) => ({
  type: types.CREATE_ARTICLE_CONTENT.FAILURE,
  error,
});

export const updateArticleContent = (articleContent) => ({
  type: types.UPDATE_ARTICLE_CONTENT.REQUEST,
  articleContent,
});

export const updateArticleContentSuccess = () => ({
  type: types.UPDATE_ARTICLE_CONTENT.SUCCESS,
});

export const updateArticleContentFailure = (error) => ({
  type: types.UPDATE_ARTICLE_CONTENT.FAILURE,
  error,
});

export const syncArticleContents = () => ({
  type: types.SYNC_ARTICLE_CONTENTS.REQUEST,
});

export const syncArticleContentsSuccess = (articleContents) => ({
  type: types.SYNC_ARTICLE_CONTENTS.SUCCESS,
  articleContents,
});

export const syncArticleContentsFailure = (error) => ({
  type: types.SYNC_ARTICLE_CONTENTS.FAILURE,
  error,
});

export const fetchArticleContent = (articleContentId) => ({
  type: types.FETCH_ARTICLE_CONTENT.REQUEST,
  articleContentId,
});

export const fetchArticleContentSuccess = (articleContent) => ({
  type: types.FETCH_ARTICLE_CONTENT.SUCCESS,
  articleContent,
});

export const fetchArticleContentFailure = (error) => ({
  type: types.FETCH_ARTICLE_CONTENT.FAILURE,
  error,
});
