export default [
  {
    value: 'gravidanza',
    label: 'Gravidanza',
    children: [
      {
        value: '0-settimana-gravidanza',
        label: 'Settimana 0',
      },
      {
        value: '1-settimana-gravidanza',
        label: 'Settimana 1',
      },
      {
        value: '2-settimana-gravidanza',
        label: 'Settimana 2',
      },
      {
        value: '3-settimana-gravidanza',
        label: 'Settimana 3',
      },
      {
        value: '4-settimana-gravidanza',
        label: 'Settimana 4',
      },
      {
        value: '5-settimana-gravidanza',
        label: 'Settimana 5',
      },
      {
        value: '6-settimana-gravidanza',
        label: 'Settimana 6',
      },
      {
        value: '7-settimana-gravidanza',
        label: 'Settimana 7',
      },
      {
        value: '8-settimana-gravidanza',
        label: 'Settimana 8',
      },
      {
        value: '9-settimana-gravidanza',
        label: 'Settimana 9',
      },
      {
        value: '10-settimana-gravidanza',
        label: 'Settimana 10',
      },
      {
        value: '11-settimana-gravidanza',
        label: 'Settimana 11',
      },
      {
        value: '12-settimana-gravidanza',
        label: 'Settimana 12',
      },
      {
        value: '13-settimana-gravidanza',
        label: 'Settimana 13',
      },
      {
        value: '14-settimana-gravidanza',
        label: 'Settimana 14',
      },
      {
        value: '15-settimana-gravidanza',
        label: 'Settimana 15',
      },
      {
        value: '16-settimana-gravidanza',
        label: 'Settimana 16',
      },
      {
        value: '17-settimana-gravidanza',
        label: 'Settimana 17',
      },
      {
        value: '18-settimana-gravidanza',
        label: 'Settimana 18',
      },
      {
        value: '19-settimana-gravidanza',
        label: 'Settimana 19',
      },
      {
        value: '20-settimana-gravidanza',
        label: 'Settimana 20',
      },
      {
        value: '21-settimana-gravidanza',
        label: 'Settimana 21',
      },
      {
        value: '22-settimana-gravidanza',
        label: 'Settimana 22',
      },
      {
        value: '23-settimana-gravidanza',
        label: 'Settimana 23',
      },
      {
        value: '24-settimana-gravidanza',
        label: 'Settimana 24',
      },
      {
        value: '25-settimana-gravidanza',
        label: 'Settimana 25',
      },
      {
        value: '26-settimana-gravidanza',
        label: 'Settimana 26',
      },
      {
        value: '27-settimana-gravidanza',
        label: 'Settimana 27',
      },
      {
        value: '28-settimana-gravidanza',
        label: 'Settimana 28',
      },
      {
        value: '29-settimana-gravidanza',
        label: 'Settimana 29',
      },
      {
        value: '30-settimana-gravidanza',
        label: 'Settimana 30',
      },
      {
        value: '31-settimana-gravidanza',
        label: 'Settimana 31',
      },
      {
        value: '32-settimana-gravidanza',
        label: 'Settimana 32',
      },
      {
        value: '33-settimana-gravidanza',
        label: 'Settimana 33',
      },
      {
        value: '34-settimana-gravidanza',
        label: 'Settimana 34',
      },
      {
        value: '35-settimana-gravidanza',
        label: 'Settimana 35',
      },
      {
        value: '36-settimana-gravidanza',
        label: 'Settimana 36',
      },
      {
        value: '37-settimana-gravidanza',
        label: 'Settimana 37',
      },
      {
        value: '38-settimana-gravidanza',
        label: 'Settimana 38',
      },
      {
        value: '39-settimana-gravidanza',
        label: 'Settimana 39',
      },
      {
        value: '40-settimana-gravidanza',
        label: 'Settimana 40',
      },
      {
        value: '41-settimana-gravidanza-in-poi',
        label: 'Settimana 41 in poi',
      },
    ],
  },
  {
    value: 'postparto',
    label: 'Post parto',
    children: [
      {
        value: '0-settimana-postparto',
        label: 'Settimana 0',
      },
      {
        value: '1-settimana-postparto',
        label: 'Settimana 1',
      },
      {
        value: '2-settimana-postparto',
        label: 'Settimana 2',
      },
      {
        value: '3-settimana-postparto',
        label: 'Settimana 3',
      },
      {
        value: '4-settimana-postparto',
        label: 'Settimana 4',
      },
      {
        value: '5-settimana-postparto',
        label: 'Settimana 5',
      },
      {
        value: '6-settimana-postparto',
        label: 'Settimana 6',
      },
      {
        value: '7-settimana-postparto',
        label: 'Settimana 7',
      },
      {
        value: '8-settimana-postparto',
        label: 'Settimana 8',
      },
      {
        value: '3-mese-postparto',
        label: 'Mese 3',
        showCheckbox: false,
        children: [
          {
            value: '3-mese-postparto-settimana1',
            label: 'Settimana 1',
          },
          {
            value: '3-mese-postparto-settimana2',
            label: 'Settimana 2',
          },
          {
            value: '3-mese-postparto-settimana3',
            label: 'Settimana 3',
          },
          {
            value: '3-mese-postparto-settimana4',
            label: 'Settimana 4',
          },
        ],
      },
      {
        value: '4-mese-postparto',
        label: 'Mese 4',
        showCheckbox: false,
        children: [
          {
            value: '4-mese-postparto-settimana1',
            label: 'Settimana 1',
          },
          {
            value: '4-mese-postparto-settimana2',
            label: 'Settimana 2',
          },
          {
            value: '4-mese-postparto-settimana3',
            label: 'Settimana 3',
          },
          {
            value: '4-mese-postparto-settimana4',
            label: 'Settimana 4',
          },
        ],
      },
      {
        value: '5-mese-postparto',
        label: 'Mese 5',
        showCheckbox: false,
        children: [
          {
            value: '5-mese-postparto-settimana1',
            label: 'Settimana 1',
          },
          {
            value: '5-mese-postparto-settimana2',
            label: 'Settimana 2',
          },
          {
            value: '5-mese-postparto-settimana3',
            label: 'Settimana 3',
          },
          {
            value: '5-mese-postparto-settimana4',
            label: 'Settimana 4',
          },
        ],
      },
      {
        value: '6-mese-postparto',
        label: 'Mese 6',
        showCheckbox: false,
        children: [
          {
            value: '6-mese-postparto-settimana1',
            label: 'Settimana 1',
          },
          {
            value: '6-mese-postparto-settimana2',
            label: 'Settimana 2',
          },
          {
            value: '6-mese-postparto-settimana3',
            label: 'Settimana 3',
          },
          {
            value: '6-mese-postparto-settimana4',
            label: 'Settimana 4',
          },
        ],
      },
      {
        value: '7-mese-postparto',
        label: 'Mese 7',
        showCheckbox: false,
        children: [
          {
            value: '7-mese-postparto-settimana1',
            label: 'Settimana 1',
          },
          {
            value: '7-mese-postparto-settimana2',
            label: 'Settimana 2',
          },
          {
            value: '7-mese-postparto-settimana3',
            label: 'Settimana 3',
          },
          {
            value: '7-mese-postparto-settimana4',
            label: 'Settimana 4',
          },
        ],
      },
      {
        value: '8-mese-postparto',
        label: 'Mese 8',
        showCheckbox: false,
        children: [
          {
            value: '8-mese-postparto-settimana1',
            label: 'Settimana 1',
          },
          {
            value: '8-mese-postparto-settimana2',
            label: 'Settimana 2',
          },
          {
            value: '8-mese-postparto-settimana3',
            label: 'Settimana 3',
          },
          {
            value: '8-mese-postparto-settimana4',
            label: 'Settimana 4',
          },
        ],
      },
      {
        value: '9-mese-postparto',
        label: 'Mese 9',
        showCheckbox: false,
        children: [
          {
            value: '9-mese-postparto-settimana1',
            label: 'Settimana 1',
          },
          {
            value: '9-mese-postparto-settimana2',
            label: 'Settimana 2',
          },
          {
            value: '9-mese-postparto-settimana3',
            label: 'Settimana 3',
          },
          {
            value: '9-mese-postparto-settimana4',
            label: 'Settimana 4',
          },
        ],
      },
      {
        value: '10-mese-postparto',
        label: 'Mese 10',
        showCheckbox: false,
        children: [
          {
            value: '10-mese-postparto-settimana1',
            label: 'Settimana 1',
          },
          {
            value: '10-mese-postparto-settimana2',
            label: 'Settimana 2',
          },
          {
            value: '10-mese-postparto-settimana3',
            label: 'Settimana 3',
          },
          {
            value: '10-mese-postparto-settimana4',
            label: 'Settimana 4',
          },
        ],
      },
      {
        value: '11-mese-postparto',
        label: 'Mese 11',
        showCheckbox: false,
        children: [
          {
            value: '11-mese-postparto-settimana1',
            label: 'Settimana 1',
          },
          {
            value: '11-mese-postparto-settimana2',
            label: 'Settimana 2',
          },
          {
            value: '11-mese-postparto-settimana3',
            label: 'Settimana 3',
          },
          {
            value: '11-mese-postparto-settimana4',
            label: 'Settimana 4',
          },
        ],
      },
      {
        value: '12-mese-postparto',
        label: 'Mese 12',
        showCheckbox: false,
        children: [
          {
            value: '12-mese-postparto-settimana1',
            label: 'Settimana 1',
          },
          {
            value: '12-mese-postparto-settimana2',
            label: 'Settimana 2',
          },
          {
            value: '12-mese-postparto-settimana3',
            label: 'Settimana 3',
          },
          {
            value: '12-mese-postparto-settimana4',
            label: 'Settimana 4',
          },
        ],
      },
      {
        value: '13-mese-postparto',
        label: 'Mese 13',
        showCheckbox: false,
        children: [
          {
            value: '13-mese-postparto-settimana1',
            label: 'Settimana 1',
          },
          {
            value: '13-mese-postparto-settimana2',
            label: 'Settimana 2',
          },
          {
            value: '13-mese-postparto-settimana3',
            label: 'Settimana 3',
          },
          {
            value: '13-mese-postparto-settimana4',
            label: 'Settimana 4',
          },
        ],
      },
    ],
  },
];
