import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_DASHBOARD_STATE,
});

export const setFilters = (startDate, endDate, filters) => ({
  type: types.SET_DASHBOARD_FILTERS.REQUEST,
  startDate,
  endDate,
  filters,
});

export const setFiltersSuccess = (startDate, endDate, filters) => ({
  type: types.SET_DASHBOARD_FILTERS.SUCCESS,
  startDate,
  endDate,
  filters,
});

export const setFiltersFailure = (error) => ({
  type: types.SET_DASHBOARD_FILTERS.FAILURE,
  error,
});
