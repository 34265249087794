import React from 'react';

import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

// Import Routes all
import { userRoutes, authRoutes } from './routes/allRoutes';
import { getAllowedRoutes } from './helpers/authHelper';

// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware';

// layouts Format
import VerticalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';

// Import scss
import './assets/scss/theme.scss';
import 'toastr/build/toastr.min.css';

const App = (props) => {
  const admin = useSelector((state) => state.Auth.admin);

  const privateRoutes = getAllowedRoutes(userRoutes, admin);

  const NonAuthmiddleware = ({
    component: Component,
    layout: VerticalLayout,
  }) => (
    <Route
      render={(props) => {
        return (
          <VerticalLayout>
            <Component {...props} />
          </VerticalLayout>
        );
      }}
    />
  );

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <NonAuthmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
            />
          ))}

          {privateRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
