import * as yup from 'yup';

const formValidation = yup.object().shape({
  url: yup.string().required(),
  lang: yup.string().required(),
  title: yup.string().required(),
  source: yup.string().required(),
  image: yup.string().required(),
  isAdvertisement: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  favicon: yup.string(),
  publishingStatus: yup.string().required(),
  publishedAt: yup.date().when('publishingStatus', {
    is: 'published',
    then: () => yup.date().required(),
  }),
});

export default formValidation;
