import '@availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  lang: yup.string().required(),
  playerBridId: yup.number().required(),
  walkthroughs: yup.array().of(
    yup.object({
      title: yup.string().label('walkthrough title'),
      text: yup.string().label('walkthrough text').required(),
    }),
  ),
  privacies: yup.array().of(
    yup.object({
      id: yup.string().label('privacy ID').required(),
      text: yup.string().label('privacy text').required(),
      confirmLabel: yup.string().label('privacy confirm label').required(),
      declineLabel: yup.string().label('privacy decline label').required(),
    }),
  ),
  utilityLinks: yup.array().of(
    yup.object({
      label: yup.string().label('utility links label').required(),
      link: yup.string().url().label('utility links link').required(),
      type: yup.string().label('utility links type'),
    }),
  ),
});

export default formValidation;
