import React from 'react';
import {
  ViewButton,
  EditButton,
  OpenUrlButton,
} from '../Common/DataTable/tableActionButtons';
import { getFilter } from '../Common/DataTable/tableHelpers';
import { dateIsInRange } from '../../helpers/sharedFunction';
import { cdnUrl } from '../../helpers/file';
import moment from 'moment';

const createTableColumns = (openViewModal, openEditModal) => [
  {
    title: 'Banner',
    name: 'image',
    width: 140,
    formatterComponent: ({ row, value }) => (
      <a href={cdnUrl(value)} target="_blank" rel="noreferrer">
        <img
          src={cdnUrl(value)}
          alt={row.name}
          style={{ maxWidth: '120px', maxHeight: '60px' }}
        />
      </a>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
  {
    title: 'Name',
    name: 'name',
    sort: true,
  },
  {
    title: 'Language',
    name: 'lang',
    width: 140,
    sort: true,
  },
  {
    title: 'Start Date',
    name: 'startDate',
    width: 120,
    formatterComponent: ({ value }) => moment(value).format('DD/MM/YYYY'),
    filter: (value, filter) =>
      getFilter(moment(value).format('DD/MM/YYYY'), filter.value),
  },
  {
    title: 'End Date',
    name: 'endDate',
    width: 120,
    formatterComponent: ({ value }) => moment(value).format('DD/MM/YYYY'),
    filter: (value, filter) =>
      getFilter(moment(value).format('DD/MM/YYYY'), filter.value),
  },
  {
    title: 'Active',
    name: 'active',
    width: 120,
    formatterComponent: ({ row }) =>
      dateIsInRange(
        new Date(),
        new Date(row.startDate),
        new Date(row.endDate),
      ) ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-warning font-size-12">NO</span>
      ),
    filter: (value, filter, row) =>
      getFilter(
        dateIsInRange(
          new Date(),
          new Date(row.startDate),
          new Date(row.endDate),
        )
          ? 'YES'
          : 'NO',
        filter.value,
      ),
  },
  {
    title: 'Action',
    name: 'action',
    width: 140,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        <EditButton row={row} onPress={openEditModal} />
        <OpenUrlButton row={row} url={row.link} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
