import React from 'react';
import {
  ViewButton,
  EditButton,
  SendButton,
} from '../Common/DataTable/tableActionButtons';
import {
  pushNotificationStatus,
  getFilter,
  getSort,
} from '../Common/DataTable/tableHelpers';
import { cdnUrl } from '../../helpers/file';
import { STATUS } from '../../config/pushNotifications';
import moment from 'moment';

const createTableColumns = (openViewModal, openEditModal, openSendAlert) => [
  {
    title: 'Image',
    name: 'image',
    width: 140,
    formatterComponent: ({ row, value }) => (
      <a href={cdnUrl(value)} target="_blank" rel="noreferrer">
        <img
          src={cdnUrl(value)}
          alt={row.name}
          style={{ maxWidth: '120px', maxHeight: '60px' }}
        />
      </a>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
  {
    title: 'Name',
    name: 'name',
  },
  {
    title: 'Title',
    name: 'title',
    width: 150,
  },
  {
    title: 'Message',
    name: 'message',
  },
  {
    title: 'Status',
    name: 'status',
    formatterComponent: ({ value }) => pushNotificationStatus(value, true),
  },
  {
    title: 'Send Date',
    name: 'sendDate',
    formatterComponent: ({ value }) => moment(value).format('DD/MM/YYYY HH:mm'),
    filter: (value, filter) =>
      getFilter(moment(value).format('DD/MM/YYYY'), filter.value),
    sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
  },

  {
    title: 'Time Tags',
    name: 'timeTags',
    width: 200,
    wordWrapEnabled: true,
    formatterComponent: ({ value }) =>
      Array.isArray(value) && value.length
        ? `${value.join(', ').substring(0, 100)}...`
        : '',
  },
  {
    title: 'Language',
    name: 'lang',
    width: 80,
    hidden: true,
  },
  {
    title: 'Action',
    name: 'action',
    width: 130,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        <EditButton row={row} onPress={openEditModal} />
        {openSendAlert && row.status === STATUS.DRAFT && (
          <SendButton row={row} onPress={openSendAlert} />
        )}
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
