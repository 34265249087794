import { createSelector } from 'reselect';
import colors from '../components/CommonStyle/colors';
import { getDates, dateIsInRange } from '../helpers/sharedFunction';
import moment from 'moment';

export const selectPerson = (id) =>
  createSelector(
    (state) => state.Member.members,
    (members) => {
      if (!id) return null;
      return members.find((member) => member.id === id);
    },
  );

export const countMember = createSelector(
  (state) => state.Member.members,
  (members) => members.length,
);

export const membersWithPushToken = createSelector(
  (state) => state.Member.members,
  (members) =>
    members.filter(
      ({ oneSignalExternalId }) =>
        oneSignalExternalId && oneSignalExternalId !== 'null',
    ),
);

export const countMemberWithPushToken = createSelector(
  membersWithPushToken,
  (members) => members.length,
);

export const membersWithChildren = createSelector(
  (state) => state.Member.members,
  (members) => members.filter(({ children }) => children > 0),
);

export const countMemberWithChildren = createSelector(
  membersWithChildren,
  (members) => members.length,
);

export const membersPregnant = createSelector(
  (state) => state.Member.members,
  (members) => members.filter(({ isPregnant }) => isPregnant),
);

export const countMemberPregnant = createSelector(
  membersPregnant,
  (members) => members.length,
);

export const membersPregnantWithChildren = createSelector(
  (state) => state.Member.members,
  (members) =>
    members.filter(({ isPregnant, children }) => isPregnant && children > 0),
);

export const countMemberPregnantWithChildren = createSelector(
  membersPregnantWithChildren,
  (members) => members.length,
);

export const membersSeeking = createSelector(
  (state) => state.Member.members,
  (members) => members.filter(({ isLooking }) => isLooking),
);

export const countMemberSeeking = createSelector(
  membersSeeking,
  (members) => members.length,
);

export const membersPregnantByQuarter = createSelector(
  membersPregnant,
  (members) => {
    const now = moment().utc();

    const firstQuarters = [],
      secondQuarters = [],
      thirdQuarters = [];

    members.forEach((member) => {
      const ddp = member.childrenBirthdates.find(
        (date) => new Date(date) > now.toDate(),
      );

      if (!ddp) return;

      const threeMonthsAgo = moment(ddp).utc().subtract(3, 'months');
      const sixMonthsAgo = moment(ddp).utc().subtract(6, 'months');

      if (now.isAfter(threeMonthsAgo)) thirdQuarters.push(member);
      else if (now.isAfter(sixMonthsAgo)) secondQuarters.push(member);
      else firstQuarters.push(member);
    });

    return { firstQuarters, secondQuarters, thirdQuarters };
  },
);

export const countMemberPregnantByQuarter = createSelector(
  membersPregnantByQuarter,
  (members) => {
    const { firstQuarters, secondQuarters, thirdQuarters } = members;
    return {
      firstQuarters: firstQuarters.length,
      secondQuarters: secondQuarters.length,
      thirdQuarters: thirdQuarters.length,
    };
  },
);

export const membersWithChildrenByAge = createSelector(
  membersWithChildren,
  (members) => {
    const now = moment().utc();

    const twelveMonths = [],
      firstYear = [],
      secondYear = [],
      thirdYear = [];

    members.forEach((member) => {
      //get younger children from member.childrenBirthdates
      const younger = member.childrenBirthdates.sort((a, b) => b - a)[0];

      if (now.diff(moment(younger).utc(), 'months') < 12)
        twelveMonths.push(member);
      else if (now.diff(moment(younger).utc(), 'months') < 24)
        firstYear.push(member);
      else if (now.diff(moment(younger).utc(), 'months') < 36)
        secondYear.push(member);
      else thirdYear.push(member);
    });

    return { twelveMonths, firstYear, secondYear, thirdYear };
  },
);

export const countMemberWithChildrenByAge = createSelector(
  membersWithChildrenByAge,
  (members) => {
    const { twelveMonths, firstYear, secondYear, thirdYear } = members;
    return {
      twelveMonths: twelveMonths.length,
      firstYear: firstYear.length,
      secondYear: secondYear.length,
      thirdYear: thirdYear.length,
    };
  },
);

export const drawSeriesDailyGraph = (dateRange, t) =>
  createSelector(
    (state) => state.Member.members,
    (members) => {
      const { startDate, endDate } = dateRange;

      const dates = getDates(startDate.toDate(), endDate.toDate());

      let dataGraph = {
        series: [
          {
            name: t('Members'),
            data: [],
            type: 'line',
            color: colors.dark,
          },
        ],
        max: -9999999999,
        min: 99999999999,
      };

      dates.forEach((day) => {
        const startDay = moment(day).utc().startOf('day').toDate();
        const endDay = moment(day).utc().endOf('day').toDate();

        const countMembers = members.filter(({ createdAt }) =>
          dateIsInRange(new Date(createdAt), startDay, endDay),
        ).length;

        dataGraph.series[0].data.push([day.getTime(), countMembers]);

        dataGraph.max = Math.max(dataGraph.max, countMembers);
        dataGraph.min = Math.min(dataGraph.min, countMembers);
      });
      return dataGraph;
    },
  );
