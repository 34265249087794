import React from 'react';
import { Container } from 'reactstrap';

import { useSelector } from 'react-redux';
import {
  isAdmin as isAdminSelector,
  isManager as isManagerSelector,
  isEditor as isEditorSelector,
} from '../../selectors/auth';

import AdminDashboard from './admin';

const Dashboard = (props) => {
  const isAdmin = useSelector(isAdminSelector);
  const isManager = useSelector(isManagerSelector);
  const isEditor = useSelector(isEditorSelector);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <>{(isAdmin || isManager || isEditor) && <AdminDashboard />}</>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
