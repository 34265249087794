import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_SYMPTOM_STATE,
});

export const createSymptom = (symptom) => ({
  type: types.CREATE_SYMPTOM.REQUEST,
  symptom,
});

export const createSymptomSuccess = (symptom) => ({
  type: types.CREATE_SYMPTOM.SUCCESS,
  symptom,
});

export const createSymptomFailure = (error) => ({
  type: types.CREATE_SYMPTOM.FAILURE,
  error,
});

export const updateSymptom = (symptom) => ({
  type: types.UPDATE_SYMPTOM.REQUEST,
  symptom,
});

export const updateSymptomSuccess = () => ({
  type: types.UPDATE_SYMPTOM.SUCCESS,
});

export const updateSymptomFailure = (error) => ({
  type: types.UPDATE_SYMPTOM.FAILURE,
  error,
});

export const syncSymptoms = () => ({
  type: types.SYNC_SYMPTOMS.REQUEST,
});

export const syncSymptomsSuccess = (symptoms) => ({
  type: types.SYNC_SYMPTOMS.SUCCESS,
  symptoms,
});

export const syncSymptomsFailure = (error) => ({
  type: types.SYNC_SYMPTOMS.FAILURE,
  error,
});

export const fetchSymptom = (symptomId) => ({
  type: types.FETCH_SYMPTOM.REQUEST,
  symptomId,
});

export const fetchSymptomSuccess = (symptom) => ({
  type: types.FETCH_SYMPTOM.SUCCESS,
  symptom,
});

export const fetchSymptomFailure = (error) => ({
  type: types.FETCH_SYMPTOM.FAILURE,
  error,
});
