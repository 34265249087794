import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as videosActions from './actions';
import { selectVideo } from '../../selectors/video';

import {
  getFirestore,
  query,
  collection,
  doc,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import { uploadFileToS3 } from '../../helpers/file';

import toastr from 'toastr';
import { toDateFirebase, isEqualObjs } from '../../helpers/sharedFunction';

const STORAGE_BASE_PATH = 'videos';
const db = getFirestore(rsf.app);

function* createVideoSaga({ video }) {
  try {
    const videosRef = doc(collection(db, 'videos'));
    const id = videosRef.id;

    let coverImageURL;
    if (video.files) {
      const { coverImage } = video.files;
      [coverImageURL] = yield all(
        Object.values({ coverImage }).map((file) => {
          const path = `${STORAGE_BASE_PATH}/${id}/coverImages/${file.path}`;
          return call(uploadFileToS3, file, path);
        }),
      );
    }

    delete video.files;

    yield call(rsf.firestore.setDocument, videosRef, {
      ...video,
      langs: [],
      ...(coverImageURL && { coverImage: coverImageURL }),
      createdAt: serverTimestamp(),
    });
    yield put(videosActions.createVideoSuccess(video));
    toastr.success('Video created!', '');
  } catch (error) {
    yield put(videosActions.createVideoFailure(error));
  }
}

function* updateVideoSaga({ video }) {
  try {
    const { id, files } = video;
    const videosRef = doc(db, 'videos', id);

    let coverImageURL;
    if (files?.coverImage) {
      const path = `${STORAGE_BASE_PATH}/${id}/coverImages/${files.coverImage.path}`;
      coverImageURL = yield call(uploadFileToS3, files.coverImage, path);
    }

    delete video.id;
    delete video.files;
    delete video.createdAt;

    const prevVideo = yield select(selectVideo(id));
    const newVideo = {
      ...video,
      ...(coverImageURL && { coverImage: coverImageURL }),
      updatedAt: serverTimestamp(),
    };

    if (isEqualObjs(prevVideo, newVideo))
      return yield put(videosActions.updateVideoSuccess(video));

    yield call(rsf.firestore.setDocument, videosRef, newVideo, { merge: true });
    yield put(videosActions.updateVideoSuccess(video));
    toastr.success('Video updated!', '');
  } catch (error) {
    yield put(videosActions.updateVideoFailure(error));
  }
}

const videoTransformer = (payload) => {
  let videos = [];

  payload.forEach((video) => {
    const data = video.data();
    videos.push({
      id: video.id,
      ...data,
      ...(data.createdAt && {
        createdAt: toDateFirebase(video, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(video, data, 'updatedAt').toDate(),
      }),
    });
  });

  return videos;
};

function* syncVideosSaga() {
  const videosRef = query(
    collection(db, 'videos'),
    orderBy('createdAt', 'desc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, videosRef, {
    successActionCreator: videosActions.syncVideosSuccess,
    failureActionCreator: videosActions.syncVideosFailure,
    transform: (payload) => videoTransformer(payload),
  });

  yield take(types.RESET_VIDEO_STATE);
  yield cancel(task);
}

function* videoSaga() {
  yield all([
    takeLatest(types.SYNC_VIDEOS.REQUEST, syncVideosSaga),
    takeLeading(types.CREATE_VIDEO.REQUEST, createVideoSaga),
    takeLeading(types.UPDATE_VIDEO.REQUEST, updateVideoSaga),
  ]);
}

export default videoSaga;
