import { createSelector } from 'reselect';

export const selectArticle = (id) =>
  createSelector(
    (state) => state.Article.articles,
    (articles) => {
      if (!id) return null;
      return articles.find((article) => article.id === id);
    },
  );

export const selectArticleContent = (id) =>
  createSelector(
    (state) => state.ArticleContent.articleContents,
    (articleContents) => {
      if (!id) return null;
      return articleContents.find((articleContent) => articleContent.id === id);
    },
  );
