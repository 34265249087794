import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Alert,
  Collapse,
  Spinner,
} from 'reactstrap';
import Select from '@availity/select';
import { Form, Input } from '@availity/form';
import { Switch } from '../../lib/form-field';
import FormErrorMessage from '../Common/FormErrorMessage';
import TreeSelector from '../Common/TreeSelector';
import formValidation from './formValidation';

import articleCategories from '../../config/articleCategories';
import timeTags from '../../config/timeTags';

import * as webContentsActions from '../../store/webContents/actions';
import { useDispatch, useSelector } from 'react-redux';

import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { extract } from '@extractus/article-extractor';
import { deleteField } from 'firebase/firestore';
import moment from 'moment';

const URL_REGEX =
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;

const FormWebContent = ({ loading, webContent, create, closeModal }) => {
  const [loadingMetadata, setLoadingMetadata] = useState(false);
  const [error, setError] = useState('');
  const [categories, setCategories] = useState(webContent?.categories || []);
  const [tags, setTags] = useState(webContent?.timeTags || []);

  const categoryLabel = categories.length ? `(${categories.join(', ')})` : '';
  const tagsLabel = tags.length ? `(${tags.join(', ')})` : '';

  const countries = useSelector((state) => state.Country.countries);

  const dispatch = useDispatch();

  const handleGetContent = async (link, values, setValues) => {
    try {
      setLoadingMetadata(true);
      setError('');
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/get-web-content-metadata`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ url: link }),
        },
      );
      const data = await response.json();
      const { title, image, favicon, url, source } = data;
      setValues({
        ...values,
        title,
        image,
        favicon,
        url,
        source,
      });
    } catch (error) {
      setError('Error getting content:', error.message);
      console.error(error);
    } finally {
      setLoadingMetadata(false);
    }
  };

  const handleSubmit = (values) => {
    if (!categories.length)
      return setError('Insert at least one category, please!');
    if (!tags.length) return setError('Insert at least one time tag, please!');

    const { publishingStatus, publishedAt } = values;
    delete values.publishedAt;

    dispatch(
      create
        ? webContentsActions.createWebContent({
            ...values,
            categories,
            timeTags: tags,
            ...(publishingStatus === 'published' && {
              publishedAt: new Date(publishedAt),
            }),
          })
        : webContentsActions.updateWebContent({
            id: webContent.id,
            ...values,
            categories,
            timeTags: tags,
            publishedAt:
              publishingStatus === 'draft'
                ? deleteField()
                : new Date(publishedAt),
          }),
    );
    closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={webContent || {}}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values, setValues }) => (
            <>
              <Collapse isOpen={!!error}>
                <Alert color="danger">{error}</Alert>
              </Collapse>
              <Row>
                <Col md="7">
                  <FormGroup>
                    <Label htmlFor="url">Content URL</Label>
                    <Input type="textarea" name="url" id="url" />
                    <FormErrorMessage name="url" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="lang">Language</Label>
                    <Select
                      name="lang"
                      id="lang"
                      isSearchable
                      options={countries.map(({ name, lang }) => ({
                        label: name,
                        value: lang,
                      }))}
                    />
                    <FormErrorMessage name="lang" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <button
                    type="button"
                    className="waves-effect waves-light btn btn-outline-success"
                    style={{ marginTop: '27px' }}
                    onClick={() =>
                      handleGetContent(values.url, values, setValues)
                    }
                    disabled={
                      !(URL_REGEX.test(values.url) && values.lang) ||
                      loadingMetadata
                    }>
                    <i className="bx bx-search-alt align-middle mr-1"></i>
                    Get Content
                  </button>
                  <Spinner
                    color="black"
                    size="sm"
                    hidden={!loadingMetadata}
                    className="ml-3"
                  />
                </Col>
              </Row>
              <Collapse isOpen={!!values.title}>
                <Row>
                  <Col md="2">
                    <FormGroup>
                      <Label htmlFor="image">Image</Label>
                      <img
                        src={values.image}
                        alt={values.title}
                        className="d-block"
                        style={{ maxWidth: '120px', maxHeight: '60px' }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="title">Title</Label>
                      <Input type="textarea" name="title" id="title" />
                      <FormErrorMessage name="title" />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <Switch
                      name="isAdvertisement"
                      label="Advertisement?"
                      defaultChecked={values?.isAdvertisement}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label htmlFor="categories">
                        Categories{' '}
                        <span className="font-size-11 text-muted">
                          {categoryLabel}
                        </span>
                      </Label>
                      <TreeSelector
                        tree={articleCategories}
                        selected={webContent?.categories}
                        onCheck={(checked) => setCategories(checked)}
                        checkModel="leaf"
                        onlyLeafCheckboxes
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label htmlFor="timeTags">
                        Time Tags{' '}
                        <span className="font-size-11 text-muted truncate truncate-2">
                          {tagsLabel}
                        </span>
                      </Label>
                      <TreeSelector
                        tree={timeTags}
                        selected={webContent?.timeTags}
                        onCheck={(checked) => setTags(checked)}
                        checkModel="all"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label htmlFor="publishingStatus">Status</Label>
                      <Select
                        name="publishingStatus"
                        id="publishingStatus"
                        options={[
                          { label: 'Draft', value: 'draft' },
                          { label: 'Published', value: 'published' },
                        ]}
                      />
                      <FormErrorMessage name="publishingStatus" />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <Collapse isOpen={values?.publishingStatus === 'published'}>
                      <FormGroup>
                        <Label htmlFor="publishedAt">
                          Published at
                          {` ${
                            webContent?.publishedAt
                              ? moment(webContent.publishedAt).format(
                                  'DD/MM/YYYY HH:mm',
                                )
                              : ''
                          }`}
                        </Label>
                        <Input
                          id="publishedAt"
                          name="publishedAt"
                          className="form-control"
                          type="datetime-local"
                          value={values?.publishedAt}
                        />
                        <FormErrorMessage name="publishedAt" />
                      </FormGroup>
                    </Collapse>
                  </Col>
                </Row>
              </Collapse>
              <div className="modal-footer">
                <SubmitButton disabled={!isValid || loading} create={create} />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormWebContent;
