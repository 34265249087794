import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Collapse,
  Alert,
} from 'reactstrap';
import Select from '@availity/select';
import { Form, Input } from '@availity/form';
import {
  SortableSelect,
  arrayMove,
  SortableMultiValue,
  SortableMultiValueLabel,
} from '../Common/SortableSelect';
import FormErrorMessage from '../Common/FormErrorMessage';
import TreeSelector from '../Common/TreeSelector';
import formValidation from './formValidation';
import {
  GENDERS,
  SYMPTOM_TYPES,
  TIME_PERIODS,
  BODY_PARTS,
} from '../../config/symptoms';

import * as symptomsActions from '../../store/symptoms/actions';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitButton } from '../Common/Modal/modalActionButtons';

const FormSymptom = (props) => {
  const editingSymptom = props.symptom || {};

  let symptoms = useSelector((state) => state.Symptom.symptoms);
  symptoms = symptoms.filter(({ id }) => editingSymptom.id !== id);

  const [errorFile, setErrorFile] = useState('');
  const [periods, setPeriods] = useState(editingSymptom.periods || []);
  const [bodies, setBodies] = useState(editingSymptom.bodyParts || []);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    if (!periods.length)
      return setErrorFile('Insert at least one period, please!');
    if (!bodies.length)
      return setErrorFile('Insert at least one body part, please!');

    dispatch(
      props.create
        ? symptomsActions.createSymptom({
            ...values,
            periods: periods.map((period) => parseInt(period)),
            bodyParts: bodies.map((body) => parseInt(body)),
            symptomIds: values.symptomIds || [],
          })
        : symptomsActions.updateSymptom({
            id: editingSymptom.id,
            ...values,
            periods: periods.map((period) => parseInt(period)),
            bodyParts: bodies.map((body) => parseInt(body)),
          }),
    );
    props.closeModal();
  };

  const onSortEnd = (values, key, oldIndex, newIndex) => {
    const array = values[key] || [];
    const newValue = arrayMove(array, oldIndex, newIndex);
    values[key] = newValue;
    console.log(
      'Values sorted:',
      newValue.map((i) => i.value),
    );
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={editingSymptom}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ errors, isSubmitting, isValid, status, values }) => (
            <>
              <Collapse isOpen={!!errorFile}>
                <Alert color="danger">{errorFile}</Alert>
              </Collapse>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input type="text" name="name" id="name" />
                    <FormErrorMessage name="name" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="gender">Gender</Label>
                    <Select
                      name="gender"
                      id="gender"
                      isMulti={false}
                      isSearchable
                      options={GENDERS}
                    />
                    <FormErrorMessage name="gender" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="symptomType">Type</Label>
                    <Select
                      name="symptomType"
                      id="symptomType"
                      isMulti={false}
                      isSearchable
                      options={SYMPTOM_TYPES}
                    />
                    <FormErrorMessage name="symptomType" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="periods">Periods</Label>
                    <TreeSelector
                      tree={TIME_PERIODS}
                      selected={editingSymptom.periods}
                      onCheck={(checked) => setPeriods(checked)}
                      checkModel="leaf"
                      showExpandAll={false}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="bodyParts">Body Parts</Label>
                    <TreeSelector
                      tree={BODY_PARTS}
                      selected={editingSymptom.bodyParts}
                      onCheck={(checked) => setBodies(checked)}
                      checkModel="leaf"
                      showExpandAll={false}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label htmlFor="symptomIds">Symptoms</Label>
                    <SortableSelect
                      name="symptomIds"
                      id="symptomIds"
                      useDragHandle
                      axis="xy"
                      onSortEnd={({ oldIndex, newIndex }) =>
                        onSortEnd(values, 'symptomIds', oldIndex, newIndex)
                      }
                      distance={4}
                      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                      getHelperDimensions={({ node }) =>
                        node.getBoundingClientRect()
                      }
                      // react-select props:
                      isMulti
                      options={symptoms.map(({ id, name }) => ({
                        label: name,
                        value: id,
                      }))}
                      components={{
                        // @ts-ignore We're failing to provide a required index prop to SortableElement
                        MultiValue: SortableMultiValue,
                        MultiValueLabel: SortableMultiValueLabel,
                      }}
                      closeMenuOnSelect={false}
                    />
                    <FormErrorMessage name="symptomIds" />
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormSymptom;
