import '@availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  lang: yup.string().required(),
  birthdate: yup.date().required(),
  userSiteId: yup.string().required(),
  userLoginId: yup.string().required(),
  oneSignalExternalId: yup.string().required(),
  childrenBirthdates: yup.array().of(yup.date()).required(),
});

export default formValidation;
