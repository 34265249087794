import { call } from 'redux-saga/effects';
import rsf from '../helpers/firebase';
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';

const BUCKET_S3 = 'static.mammacheapp.it';
const REGION_S3 = 'eu-central-1';
const CDN_ENDPOINT_S3 = 'cdn.mammacheapp.it';

const getFilename = (url) => url.substring(url.lastIndexOf('/') + 1);

const addTimestamp = (path) => {
  const filename = getFilename(path);
  const [name, extension] = filename.split('.');
  return path.replace(filename, `${name}-${new Date().getTime()}.${extension}`);
};

export function* uploadFileToGoogle(file, path) {
  const task = rsf.storage.uploadFile(addTimestamp(path), file, {
    contentType: file.type,
  });

  task.on('state_changed', (snapshot) => {
    const percentage = Math.round(
      (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
    );
    // console.log(`${filePath} - ${percentage}%`);
  });

  yield task; // Wait for upload to complete

  const url = yield call(rsf.storage.getDownloadURL, task.snapshot.ref);
  return url;
}

export const initUploadToS3 = (blob, contentType, path) => {
  const s3 = new S3Client({
    region: REGION_S3,
    credentials: {
      accessKeyId: process.env.REACT_APP_S3_KEY,
      secretAccessKey: process.env.REACT_APP_S3_SECRET,
    },
  });

  const uploadS3 = new Upload({
    client: s3,
    params: {
      //ACL: 'public-read',
      Bucket: BUCKET_S3,
      Key: addTimestamp(`${process.env.REACT_APP_ENVIRONMENT}/${path}`),
      ContentType: contentType,
      Body: blob,
    },
    tags: [], // optional tags
    queueSize: 4, // optional concurrency configuration
    partSize: 1024 * 1024 * 100, // optional size of each part, in bytes, at least 5MB
    leavePartsOnError: false, // optional manually handle dropped parts
  });

  return uploadS3;
};

export function* uploadFileToS3(file, path) {
  let response = yield fetch(file.preview);
  const blob = yield response.blob();

  console.log({ blob });

  const uploadS3 = initUploadToS3(blob, file.type, path);
  uploadS3.on('httpUploadProgress', (progress) => {
    console.log({ progress });
  });

  response = yield uploadS3.done();
  return response.Location;
}

export const cdnUrl = (url) => {
  if (!url) return '';

  return url
    .replace(
      'static.mammacheapp.it.s3.eu-central-1.amazonaws.com',
      CDN_ENDPOINT_S3,
    )
    .replace(
      's3.eu-central-1.amazonaws.com/static.mammacheapp.it',
      CDN_ENDPOINT_S3,
    );
};
