import { put, all, takeLatest } from 'redux-saga/effects';
import types from './actionTypes';
import * as dashboardActions from './actions';

function* setFiltersSaga({ startDate, endDate, filters }) {
  try {
    yield put(dashboardActions.setFiltersSuccess(startDate, endDate, filters));
  } catch (error) {
    yield put(dashboardActions.setFiltersFailure(error));
  }
}

function* dashboardSaga() {
  yield all([takeLatest(types.SET_DASHBOARD_FILTERS.REQUEST, setFiltersSaga)]);
}

export default dashboardSaga;
