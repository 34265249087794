import { createSelector } from 'reselect';

export const selectUser = (id) =>
  createSelector(
    (state) => state.User.users,
    (users) => {
      if (id) {
        const user = users.filter((user) => user.id === id);
        return user.length > 0 ? user[0] : null;
      }
      return null;
    },
  );

export const selectUserByRole = (roles) =>
  createSelector(
    (state) => state.User.users,
    (users) => {
      if (!roles) return null;
      const arrayRoles = Array.isArray(roles) ? roles : [roles];
      const selectedUsers = users.filter((user) =>
        arrayRoles.includes(user.role),
      );
      return selectedUsers.length > 0 ? selectedUsers : null;
    },
  );

export const userName = (id) =>
  createSelector(selectUser(id), (user) => {
    if (!user) return null;
    return `${user.firstName} ${user.lastName}`;
  });

export const userEmailInUse = (excludeUserId = null) =>
  createSelector(
    (state) => state.User.users,
    (users) =>
      users
        .filter((user) => user.id !== excludeUserId)
        .map((user) => user.email),
  );
