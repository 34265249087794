const statsTypes = {
  RESET_WEB_CONTENT_STATE: 'RESET_WEB_CONTENT_STATE',
  CREATE_WEB_CONTENT: {
    REQUEST: 'CREATE_WEB_CONTENT.REQUEST',
    SUCCESS: 'CREATE_WEB_CONTENT.SUCCESS',
    FAILURE: 'CREATE_WEB_CONTENT.FAILURE',
  },
  UPDATE_WEB_CONTENT: {
    REQUEST: 'UPDATE_WEB_CONTENT.REQUEST',
    SUCCESS: 'UPDATE_WEB_CONTENT.SUCCESS',
    FAILURE: 'UPDATE_WEB_CONTENT.FAILURE',
  },
  FETCH_WEB_CONTENTS: {
    REQUEST: 'FETCH_WEB_CONTENTS.REQUEST',
    SUCCESS: 'FETCH_WEB_CONTENTS.SUCCESS',
    FAILURE: 'FETCH_WEB_CONTENTS.FAILURE',
  },
};

export default statsTypes;
