const statsTypes = {
  RESET_PUSH_NOTIFICATION_STATE: 'RESET_PUSH_NOTIFICATION_STATE',
  CREATE_PUSH_NOTIFICATION: {
    REQUEST: 'CREATE_PUSH_NOTIFICATION.REQUEST',
    SUCCESS: 'CREATE_PUSH_NOTIFICATION.SUCCESS',
    FAILURE: 'CREATE_PUSH_NOTIFICATION.FAILURE',
  },
  UPDATE_PUSH_NOTIFICATION: {
    REQUEST: 'UPDATE_PUSH_NOTIFICATION.REQUEST',
    SUCCESS: 'UPDATE_PUSH_NOTIFICATION.SUCCESS',
    FAILURE: 'UPDATE_PUSH_NOTIFICATION.FAILURE',
  },
  FETCH_PUSH_NOTIFICATIONS: {
    REQUEST: 'FETCH_PUSH_NOTIFICATIONS.REQUEST',
    SUCCESS: 'FETCH_PUSH_NOTIFICATIONS.SUCCESS',
    FAILURE: 'FETCH_PUSH_NOTIFICATIONS.FAILURE',
  },
};

export default statsTypes;
