import { createSelector } from 'reselect';

export const selectSymptom = (id) =>
  createSelector(
    (state) => state.Symptom.symptoms,
    (symptoms) => {
      if (!id) return null;
      return symptoms.find((symptom) => symptom.id === id);
    },
  );

export const selectSymptomContent = (id) =>
  createSelector(
    (state) => state.SymptomContent.symptomContents,
    (symptomContents) => {
      if (!id) return null;
      return symptomContents.find((symptomContent) => symptomContent.id === id);
    },
  );
