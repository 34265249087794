import types from './actionTypes';

const initialState = {
  loading: false,
  symptoms: [],
  error: '',
};

const symptomReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_SYMPTOM_STATE:
      return initialState;
    case types.CREATE_SYMPTOM.REQUEST:
    case types.UPDATE_SYMPTOM.REQUEST:
    case types.SYNC_SYMPTOMS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_SYMPTOM.SUCCESS:
    case types.UPDATE_SYMPTOM.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SYNC_SYMPTOMS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        symptoms: [...action.symptoms],
      };
    case types.CREATE_SYMPTOM.FAILURE:
    case types.UPDATE_SYMPTOM.FAILURE:
    case types.SYNC_SYMPTOMS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default symptomReducer;
