import { put, all, call, takeLeading, takeLatest } from 'redux-saga/effects';
import types from './actionTypes';
import * as membersActions from './actions';
import {
  getFirestore,
  collection,
  doc,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import toastr from 'toastr';
import { toDateFirebase } from '../../helpers/sharedFunction';

const db = getFirestore(rsf.app);

export const memberTransformer = (member) => {
  const data = member.data();

  data.childrenBirthdates = Array.isArray(data.childrenBirthdates)
    ? data.childrenBirthdates.map((birthdate) => birthdate.toDate())
    : [];
  data.children = data.childrenBirthdates.filter(
    (birthdate) => birthdate < new Date(),
  ).length;
  data.isPregnant = !!(data.childrenBirthdates.length - data.children);
  data.isLooking = !data.childrenBirthdates.length;

  return {
    id: member.id,
    ...data,
    ...(data.createdAt && {
      createdAt: toDateFirebase(member, data).toDate(),
    }),
    ...(data.updatedAt && {
      updatedAt: toDateFirebase(member, data, 'updatedAt').toDate(),
    }),
    ...(data.birthdate && {
      birthdate: toDateFirebase(member, data, 'birthdate').toDate(),
    }),
  };
};

function* createMemberSaga({ member }) {
  try {
    const membersRef = collection(db, 'members');

    yield call(rsf.firestore.addDocument, membersRef, {
      ...member,
      createdAt: serverTimestamp(),
    });
    yield put(membersActions.createMemberSuccess(member));
    toastr.success('Member created!', '');
  } catch (error) {
    yield put(membersActions.createMemberFailure(error));
  }
}

function* updateMemberSaga({ member }) {
  try {
    const { id, createdAt } = member;
    const memberRef = doc(db, 'members', member.id);
    delete member.id;
    delete member.children;
    delete member.isPregnant;
    delete member.createdAt;

    yield call(
      rsf.firestore.setDocument,
      memberRef,
      {
        ...member,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(membersActions.updateMemberSuccess({ id, ...member, createdAt }));
    toastr.success('Member updated!', '');
  } catch (error) {
    yield put(membersActions.updateMemberFailure(error));
    toastr.error(error.message);
  }
}

function* fetchMembersSaga({ startDate, endDate, filters }) {
  try {
    const membersRef = rsf.firestore.createCollectionRefWithFilters(
      'members',
      startDate,
      endDate,
      filters,
    );
    const membersSnap = yield call(rsf.firestore.getCollection, membersRef);
    const members = membersSnap.docs.map((member) => memberTransformer(member));
    yield put(
      membersActions.fetchMembersSuccess(members, startDate, endDate, filters),
    );
  } catch (error) {
    yield put(membersActions.fetchMembersFailure(error));
  }
}
function* memberSaga() {
  yield all([
    takeLatest(types.FETCH_MEMBERS.REQUEST, fetchMembersSaga),
    takeLeading(types.CREATE_MEMBER.REQUEST, createMemberSaga),
    takeLeading(types.UPDATE_MEMBER.REQUEST, updateMemberSaga),
  ]);
}

export default memberSaga;
