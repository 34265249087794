import React, { useEffect } from 'react';
import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Form, Field } from '@availity/form';
import * as yup from 'yup';
import '@availity/yup';
import * as authActions from '../../store/auth/actions';
import DisclaimerFooter from '../../components/Common/DisclaimerFooter';
import logotype from '../../assets/images/media-data-factory.png';
import logo from '../../assets/images/logo.png';

const LOGIN_WITH_USERNAME_PASSWORD = false;

const Login = (props) => {
  const error = useSelector((state) => state.Auth.error);
  const success = useSelector((state) => state.Auth.success);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.resetMessages());
  }, []);

  const handleValidSubmit = (event, values) => {
    const { email, password } = values;
    dispatch(authActions.loginWithEmail(email, password, props.history));
  };

  const handleLoginWithGoogle = () => {
    dispatch(authActions.loginWithGoogle(props.history));
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back!</h5>
                        <p>Sign in to continue to MammaCheApp CMS.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={logotype} alt="" className="img-fluid p-2" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="75"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      initialValues={{
                        email: '',
                        password: '',
                      }}
                      onSubmit={(e, v) => handleValidSubmit(e, v)}
                      validationSchema={yup.object().shape({
                        email: yup.string().email().required(),
                        password: yup.string().required(),
                      })}>
                      {error !== '' && <Alert color="danger">{error}</Alert>}
                      {success !== '' && (
                        <Alert color="success">{success}</Alert>
                      )}
                      {LOGIN_WITH_USERNAME_PASSWORD && (
                        <>
                          <div className="form-group">
                            <Field
                              name="email"
                              label="Email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                            />
                          </div>

                          <div className="form-group">
                            <Field
                              name="password"
                              label="Password"
                              type="password"
                              placeholder="Enter Password"
                            />
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customControlInline"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customControlInline">
                              Remember me
                            </label>
                          </div>

                          <div className="mt-3">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit">
                              Login
                            </button>
                          </div>
                        </>
                      )}
                      <div className="mt-3">
                        <button
                          className="btn btn-outline-dark btn-block waves-effect waves-light"
                          type="button"
                          onClick={handleLoginWithGoogle}>
                          <i className="bx bxl-google font-size-18 align-middle mr-2"></i>
                          Login with Google
                        </button>
                      </div>
                      {LOGIN_WITH_USERNAME_PASSWORD && (
                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock mr-1"></i> Forgot your
                            password?
                          </Link>
                        </div>
                      )}
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {LOGIN_WITH_USERNAME_PASSWORD && (
                  <p>
                    Don&apos;t have an account ?{' '}
                    <Link
                      to="register"
                      className="font-weight-medium text-primary">
                      {' '}
                      Signup now{' '}
                    </Link>{' '}
                  </p>
                )}
                <DisclaimerFooter />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
