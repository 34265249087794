import React from 'react';
import {
  ViewButton,
  EditButton,
  OpenUrlButton,
} from '../Common/DataTable/tableActionButtons';
import { getFilter, getSort } from '../Common/DataTable/tableHelpers';
import { isDate } from '../../helpers/sharedFunction';
import moment from 'moment';

const createTableColumns = (openViewModal, openEditModal, t) => [
  {
    title: 'Image',
    name: 'image',
    width: 150,
    formatterComponent: ({ row, value }) => (
      <a href={value} target="_blank" rel="noreferrer">
        <img
          src={value}
          alt={row.name}
          style={{ maxWidth: '120px', maxHeight: '60px' }}
        />
      </a>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
  {
    title: 'Title',
    name: 'title',
    wordWrapEnabled: true,
    sort: true,
  },
  {
    title: 'Source',
    name: 'source',
    hidden: true,
  },
  {
    title: 'Categories',
    name: 'categories',
    width: 200,
    wordWrapEnabled: true,
    formatterComponent: ({ row, value }) =>
      Array.isArray(value) && value.length ? value.join(', ') : '',
  },
  {
    title: 'Time Tags',
    name: 'timeTags',
    width: 200,
    wordWrapEnabled: true,
    formatterComponent: ({ value }) =>
      Array.isArray(value) && value.length
        ? `${value.join(', ').substring(0, 100)}...`
        : '',
  },
  {
    title: 'Language',
    name: 'lang',
    width: 140,
    sort: true,
  },
  {
    title: 'Status',
    name: 'publishingStatus',
    width: 120,
    formatterComponent: ({ value, row }) => {
      if (value === 'draft')
        return <span className="badge badge-warning font-size-12">DRAFT</span>;
      if (row.publishedAt >= new Date())
        return (
          <span className="badge badge-primary font-size-12">SCHEDULED</span>
        );
      return (
        <span className="badge badge-success font-size-12">PUBLISHED</span>
      );
    },
    filter: (value, filter, row) =>
      getFilter(
        value === 'draft'
          ? 'DRAFT'
          : row.publishedAt >= new Date()
          ? 'SCHEDULED'
          : 'PUBLISHED',
        filter.value,
      ),
  },
  {
    title: 'Advertisement',
    name: 'isAdvertisement',
    width: 110,
    formatterComponent: ({ value }) =>
      value ? (
        <span className="badge badge-dark font-size-12">YES</span>
      ) : (
        <span className="badge badge-warning font-size-12">NO</span>
      ),
    filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
    sorting: (a, b) => getSort(a ? 'yes' : 'no', b ? 'yes' : 'no'),
  },
  {
    title: 'Published At',
    name: 'publishedAt',
    width: 120,
    formatterComponent: ({ value }) =>
      isDate(value) ? `${moment(value).fromNow()}` : '',
    filter: (value, filter) =>
      getFilter(
        isDate(value) ? `${moment(value).fromNow()}` : '',
        filter.value,
      ),
    sorting: 'disabled',
  },
  {
    title: 'Action',
    name: 'action',
    width: 140,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        <EditButton row={row} onPress={openEditModal} />
        <OpenUrlButton row={row} url={row.url} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
