export default [
  {
    value: 'IT',
    label: 'Italian',
  },
  {
    value: 'ES',
    label: 'Spanish',
  },
];
