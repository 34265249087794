export default [
  {
    label: 'Privacy Notice',
    value: 'privacyNoticeLink',
  },
  {
    label: 'General Terms Link',
    value: 'generalTermsLink',
  },
  {
    label: 'Rules',
    value: 'rulesLink',
  },
];
