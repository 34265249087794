import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const PregnantPie = ({ title, series, labels }) => {
  const { t } = useTranslation();

  const graphPie = {
    series,
    options: {
      chart: {
        width: '100%',
        type: 'pie',
      },
      labels,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-1 float-sm-left">{t(title)}</CardTitle>
        <div className="clearfix"></div>
        <div id="line-chart" className="apex-charts" dir="ltr">
          <ReactApexChart
            series={graphPie.series}
            options={graphPie.options}
            type="pie"
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PregnantPie;
