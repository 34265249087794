import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import packageInfo from '../../../package.json';

const Footer = (props) => {
  const location = useLocation();

  return (
    <React.Fragment>
      {location.pathname !== '/calls/manage' && (
        <footer className="footer">
          <Container fluid={true}>
            <Row>
              <Col md={6}>{new Date().getFullYear()} © Media Data Factory</Col>
              <Col md={6}>
                <div className="text-sm-right d-none d-sm-block">
                  Design & Develop with{' '}
                  <i className="mdi mdi-heart text-danger"></i> by Media Data
                  Factory - v{packageInfo.version}
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      )}
    </React.Fragment>
  );
};

export default Footer;
