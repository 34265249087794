const statsTypes = {
  RESET_MEMBER_STATE: 'RESET_MEMBER_STATE',
  CREATE_MEMBER: {
    REQUEST: 'CREATE_MEMBER.REQUEST',
    SUCCESS: 'CREATE_MEMBER.SUCCESS',
    FAILURE: 'CREATE_MEMBER.FAILURE',
  },
  UPDATE_MEMBER: {
    REQUEST: 'UPDATE_MEMBER.REQUEST',
    SUCCESS: 'UPDATE_MEMBER.SUCCESS',
    FAILURE: 'UPDATE_MEMBER.FAILURE',
  },
  FETCH_MEMBERS: {
    REQUEST: 'FETCH_MEMBERS.REQUEST',
    SUCCESS: 'FETCH_MEMBERS.SUCCESS',
    FAILURE: 'FETCH_MEMBERS.FAILURE',
  },
};

export default statsTypes;
