const types = {
  RESET_SYMPTOM_CONTENT_STATE: 'RESET_SYMPTOM_CONTENT_STATE',
  CREATE_SYMPTOM_CONTENT: {
    REQUEST: 'CREATE_SYMPTOM_CONTENT.REQUEST',
    SUCCESS: 'CREATE_SYMPTOM_CONTENT.SUCCESS',
    FAILURE: 'CREATE_SYMPTOM_CONTENT.FAILURE',
  },
  UPDATE_SYMPTOM_CONTENT: {
    REQUEST: 'UPDATE_SYMPTOM_CONTENT.REQUEST',
    SUCCESS: 'UPDATE_SYMPTOM_CONTENT.SUCCESS',
    FAILURE: 'UPDATE_SYMPTOM_CONTENT.FAILURE',
  },
  SYNC_SYMPTOM_CONTENTS: {
    REQUEST: 'SYNC_SYMPTOM_CONTENTS.REQUEST',
    SUCCESS: 'SYNC_SYMPTOM_CONTENTS.SUCCESS',
    FAILURE: 'SYNC_SYMPTOM_CONTENTS.FAILURE',
  },
  FETCH_SYMPTOM_CONTENT: {
    REQUEST: 'FETCH_SYMPTOM_CONTENT.REQUEST',
    SUCCESS: 'FETCH_SYMPTOM_CONTENT.SUCCESS',
    FAILURE: 'FETCH_SYMPTOM_CONTENT.FAILURE',
  },
};

export default types;
