import types from './actionTypes';

const initialState = {
  loading: false,
  articles: [],
  error: '',
};

const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ARTICLE_STATE:
      return initialState;
    case types.CREATE_ARTICLE.REQUEST:
    case types.UPDATE_ARTICLE.REQUEST:
    case types.SYNC_ARTICLES.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_ARTICLE.SUCCESS:
    case types.UPDATE_ARTICLE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SYNC_ARTICLES.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        articles: [...action.articles],
      };
    case types.CREATE_ARTICLE.FAILURE:
    case types.UPDATE_ARTICLE.FAILURE:
    case types.SYNC_ARTICLES.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default articleReducer;
