const types = {
  RESET_VIDEO_STATE: 'RESET_VIDEO_STATE',
  CREATE_VIDEO: {
    REQUEST: 'CREATE_VIDEO.REQUEST',
    SUCCESS: 'CREATE_VIDEO.SUCCESS',
    FAILURE: 'CREATE_VIDEO.FAILURE',
  },
  UPDATE_VIDEO: {
    REQUEST: 'UPDATE_VIDEO.REQUEST',
    SUCCESS: 'UPDATE_VIDEO.SUCCESS',
    FAILURE: 'UPDATE_VIDEO.FAILURE',
  },
  SYNC_VIDEOS: {
    REQUEST: 'SYNC_VIDEOS.REQUEST',
    SUCCESS: 'SYNC_VIDEOS.SUCCESS',
    FAILURE: 'SYNC_VIDEOS.FAILURE',
  },
  FETCH_VIDEO: {
    REQUEST: 'FETCH_VIDEO.REQUEST',
    SUCCESS: 'FETCH_VIDEO.SUCCESS',
    FAILURE: 'FETCH_VIDEO.FAILURE',
  },
};

export default types;
