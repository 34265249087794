import { combineReducers } from 'redux';

import Layout from './layout/reducer';
import Auth from './auth/reducer';
import Dashboard from './dashboard/reducer';
import User from './users/reducer';
import Article from './articles/reducer';
import ArticleContent from './articles/contents/reducer';
import Video from './videos/reducer';
import VideoContent from './videos/contents/reducer';
import Symptom from './symptoms/reducer';
import SymptomContent from './symptoms/contents/reducer';
import Contest from './contests/reducer';
import WebContent from './webContents/reducer';
import PushNotification from './pushNotifications/reducer';
import Member from './members/reducer';
import Country from './countries/reducer';

const rootReducer = combineReducers({
  Layout,
  Auth,
  Dashboard,
  User,
  Article,
  ArticleContent,
  Video,
  VideoContent,
  Symptom,
  SymptomContent,
  Contest,
  WebContent,
  PushNotification,
  Member,
  Country,
});

export default rootReducer;
