import React, { useState } from 'react';
import {
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const CreateButton = ({ label, onPress, disabled }) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className="btn-rounded waves-effect waves-light mb-2 mr-2 btn btn-success"
      onClick={() => onPress()}
      disabled={disabled}>
      <i className="mdi mdi-plus mr-1"></i>
      {t(label)}
    </button>
  );
};

export const ViewButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'view_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-spreadsheet"></i>
        <UncontrolledTooltip placement="top" target={'view_' + row.id}>
          {t('View')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const EditButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'edit_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-edit"></i>
        <UncontrolledTooltip placement="top" target={'edit_' + row.id}>
          {t('Edit')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const OpenUrlButton = ({ row, icon, url, label }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link
        to="#"
        id={'open_url_' + row.id}
        onClick={() => window.open(url || row.url, '_blank')}>
        <i className={`${icon || 'bx bx-link-external'}`}></i>
        <UncontrolledTooltip placement="top" target={'open_url_' + row.id}>
          {t(label || 'Open')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const ActiveButton = ({ row, onPress, key }) => {
  const { t } = useTranslation();
  const config = row[key || 'active']
    ? { icon: 'bx bx-play-circle', tooltip: 'Active' }
    : { icon: 'bx bx-pause-circle', tooltip: 'Disable' };
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'active_' + row.id} onClick={() => onPress(row)}>
        <i className={config.icon}></i>
        <UncontrolledTooltip placement="top" target={'active_' + row.id}>
          {t(config.tooltip)}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const TableRowButton = ({ name, row, icon, label, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={`${name}_${row.id}`} onClick={() => onPress(row)}>
        <i className={icon}></i>
        <UncontrolledTooltip placement="top" target={`${name}_${row.id}`}>
          {t(label || '')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const CountRowTablePlacement = (props) => (
  <div id="row-counter-table" className="flex-fill"></div>
);

export const ExportButtonPlacement = (props) => (
  <div id="export-button-table"></div>
);

export const RefreshButton = ({ label, onPress, disabled }) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      disabled={disabled}
      className="btn btn-sm btn-outline-dark waves-effect"
      style={{ marginLeft: '10px' }}
      onClick={onPress}>
      {disabled ? (
        <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
      ) : (
        <i className="bx bx-refresh font-size-16 align-middle mr-2"></i>
      )}
      {t(label || 'Refresh')}
    </button>
  );
};

export const ToggleColumnButton = ({
  columns,
  hiddenColumns,
  onToggleColumn,
}) => {
  const [show, setShow] = useState(false);
  columns = columns.filter(({ name }) => name !== 'action');

  return (
    <Dropdown
      isOpen={show}
      toggle={() => setShow(!show)}
      className="d-inline-block">
      <DropdownToggle className="btn header-item waves-effect" tag="button">
        <i className="bx bx-columns font-size-24"></i>
        <i
          className={`mdi mdi-chevron-${
            show ? 'up' : 'down'
          } d-none d-xl-inline-block`}></i>
      </DropdownToggle>
      <DropdownMenu right>
        <div className="mx-1">
          {columns.map(({ name, title }) => (
            <div
              key={name}
              className="form-check form-check-end"
              onClick={() => onToggleColumn(name)}>
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                name={name}
                defaultChecked={!hiddenColumns.includes(name)}
              />
              <label className="form-check-label" htmlFor={name}>
                {title}
              </label>
            </div>
          ))}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export const RemoteButton = ({ label, onPress, disabled }) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className="btn-rounded waves-effect waves-light mr-2 btn btn-primary"
      onClick={() => onPress()}
      disabled={disabled}>
      <i className="bx bx-cloud-download mr-1"></i>
      {t(label)}
    </button>
  );
};

export const SendButton = ({ row, onPress, label }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'send_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-send"></i>
        <UncontrolledTooltip placement="top" target={'send_' + row.id}>
          {t(label || 'Send')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};
