import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as symptomsActions from './actions';
import { selectSymptom } from '../../selectors/symptom';

import {
  getFirestore,
  query,
  collection,
  doc,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';

import toastr from 'toastr';
import { toDateFirebase, isEqualObjs } from '../../helpers/sharedFunction';

const db = getFirestore(rsf.app);

function* createSymptomSaga({ symptom }) {
  try {
    const symptomsRef = doc(collection(db, 'symptoms'));

    yield call(rsf.firestore.setDocument, symptomsRef, {
      ...symptom,
      langs: [],
      createdAt: serverTimestamp(),
    });
    yield put(symptomsActions.createSymptomSuccess(symptom));
    toastr.success('Symptom created!', '');
  } catch (error) {
    yield put(symptomsActions.createSymptomFailure(error));
  }
}

function* updateSymptomSaga({ symptom }) {
  try {
    const { id } = symptom;
    const symptomsRef = doc(db, 'symptoms', id);
    delete symptom.id;

    const prevSymptom = yield select(selectSymptom(id));
    const newSymptom = {
      ...symptom,
      updatedAt: serverTimestamp(),
    };

    if (isEqualObjs(prevSymptom, newSymptom))
      return yield put(symptomsActions.updateSymptomSuccess(symptom));

    yield call(rsf.firestore.setDocument, symptomsRef, newSymptom, {
      merge: true,
    });
    yield put(symptomsActions.updateSymptomSuccess(symptom));
    toastr.success('Symptom updated!', '');
  } catch (error) {
    yield put(symptomsActions.updateSymptomFailure(error));
  }
}

const symptomTransformer = (payload) => {
  let symptoms = [];

  payload.forEach((symptom) => {
    const data = symptom.data();
    symptoms.push({
      id: symptom.id,
      ...data,
      ...(data.createdAt && {
        createdAt: toDateFirebase(symptom, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(symptom, data, 'updatedAt').toDate(),
      }),
    });
  });

  return symptoms;
};

function* syncSymptomsSaga() {
  const symptomsRef = query(
    collection(db, 'symptoms'),
    orderBy('createdAt', 'desc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, symptomsRef, {
    successActionCreator: symptomsActions.syncSymptomsSuccess,
    failureActionCreator: symptomsActions.syncSymptomsFailure,
    transform: (payload) => symptomTransformer(payload),
  });

  yield take(types.RESET_SYMPTOM_STATE);
  yield cancel(task);
}

function* symptomSaga() {
  yield all([
    takeLatest(types.SYNC_SYMPTOMS.REQUEST, syncSymptomsSaga),
    takeLeading(types.CREATE_SYMPTOM.REQUEST, createSymptomSaga),
    takeLeading(types.UPDATE_SYMPTOM.REQUEST, updateSymptomSaga),
  ]);
}

export default symptomSaga;
