const types = {
  RESET_ARTICLE_STATE: 'RESET_ARTICLE_STATE',
  CREATE_ARTICLE: {
    REQUEST: 'CREATE_ARTICLE.REQUEST',
    SUCCESS: 'CREATE_ARTICLE.SUCCESS',
    FAILURE: 'CREATE_ARTICLE.FAILURE',
  },
  UPDATE_ARTICLE: {
    REQUEST: 'UPDATE_ARTICLE.REQUEST',
    SUCCESS: 'UPDATE_ARTICLE.SUCCESS',
    FAILURE: 'UPDATE_ARTICLE.FAILURE',
  },
  SYNC_ARTICLES: {
    REQUEST: 'SYNC_ARTICLES.REQUEST',
    SUCCESS: 'SYNC_ARTICLES.SUCCESS',
    FAILURE: 'SYNC_ARTICLES.FAILURE',
  },
  FETCH_ARTICLE: {
    REQUEST: 'FETCH_ARTICLE.REQUEST',
    SUCCESS: 'FETCH_ARTICLE.SUCCESS',
    FAILURE: 'FETCH_ARTICLE.FAILURE',
  },
};

export default types;
