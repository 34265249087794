import types from './actionTypes';

export const changeLayout = (layout) => ({
  type: types.CHANGE_LAYOUT,
  layout,
});

export const changePreloader = (layout) => ({
  type: types.CHANGE_PRELOADER,
  layout,
});

export const changeLayoutWidth = (width) => ({
  type: types.CHANGE_LAYOUT_WIDTH,
  width,
});

export const changeSidebarTheme = (theme) => ({
  type: types.CHANGE_SIDEBAR_THEME,
  theme,
});

export const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type: types.CHANGE_SIDEBAR_TYPE,
    sidebarType,
    isMobile,
  };
};

export const changeTopbarTheme = (theme) => ({
  type: types.CHANGE_TOPBAR_THEME,
  theme,
});

export const showRightSidebarAction = (isOpen) => ({
  type: types.SHOW_RIGHT_SIDEBAR,
  isOpen,
});

export const showSidebar = (isOpen) => ({
  type: types.SHOW_SIDEBAR,
  isOpen,
});

export const toggleLeftmenu = (isOpen) => ({
  type: types.TOGGLE_LEFT_MENU,
  isOpen,
});

export const toggleSensitiveData = (showData) => ({
  type: types.TOGGLE_SENSITIVE_DATA,
  showData,
});
