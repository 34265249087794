import { put, all, call, takeLeading, takeLatest } from 'redux-saga/effects';
import types from './actionTypes';
import * as webContentsActions from './actions';
import {
  getFirestore,
  collection,
  doc,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import toastr from 'toastr';
import { toDateFirebase, isDate } from '../../helpers/sharedFunction';

const db = getFirestore(rsf.app);

export const webContentTransformer = (webContent) => {
  const data = webContent.data();
  return {
    id: webContent.id,
    ...data,
    ...(data.publishedAt && {
      publishedAt: toDateFirebase(webContent, data, 'publishedAt').toDate(),
    }),
    ...(data.createdAt && {
      createdAt: toDateFirebase(webContent, data).toDate(),
    }),
    ...(data.updatedAt && {
      updatedAt: toDateFirebase(webContent, data, 'updatedAt').toDate(),
    }),
  };
};

function* createWebContentSaga({ webContent }) {
  try {
    const webContentsRef = collection(db, 'webContents');

    const webContentSnap = yield call(
      rsf.firestore.addDocument,
      webContentsRef,
      {
        ...webContent,
        createdAt: serverTimestamp(),
      },
    );
    yield put(
      webContentsActions.createWebContentSuccess({
        id: webContentSnap.id,
        ...webContent,
        createdAt: new Date(),
      }),
    );
    toastr.success('WebContent created!', '');
  } catch (error) {
    yield put(webContentsActions.createWebContentFailure(error));
  }
}

function* updateWebContentSaga({ webContent }) {
  try {
    const { id, publishedAt, createdAt } = webContent;
    const webContentRef = doc(db, 'webContents', webContent.id);
    delete webContent.id;
    delete webContent.publishedAt;
    delete webContent.createdAt;

    yield call(
      rsf.firestore.setDocument,
      webContentRef,
      {
        ...webContent,
        publishedAt: isDate(publishedAt)
          ? Timestamp.fromDate(new Date(publishedAt))
          : publishedAt,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(
      webContentsActions.updateWebContentSuccess({
        id,
        ...webContent,
        publishedAt: isDate(publishedAt) ? new Date(publishedAt) : undefined,
        createdAt,
        updatedAt: new Date(),
      }),
    );
    toastr.success('WebContent updated!', '');
  } catch (error) {
    yield put(webContentsActions.updateWebContentFailure(error));
    toastr.error(error.message);
  }
}

function* fetchWebContentsSaga({ startDate, endDate, filters }) {
  try {
    const webContentsRef = rsf.firestore.createCollectionRefWithFilters(
      'webContents',
      startDate,
      endDate,
      filters,
    );
    const webContentsSnap = yield call(
      rsf.firestore.getCollection,
      webContentsRef,
    );
    const webContents = webContentsSnap.docs.map((webContent) =>
      webContentTransformer(webContent),
    );
    yield put(
      webContentsActions.fetchWebContentsSuccess(
        webContents,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(webContentsActions.fetchWebContentsFailure(error));
  }
}
function* webContentSaga() {
  yield all([
    takeLatest(types.FETCH_WEB_CONTENTS.REQUEST, fetchWebContentsSaga),
    takeLeading(types.CREATE_WEB_CONTENT.REQUEST, createWebContentSaga),
    takeLeading(types.UPDATE_WEB_CONTENT.REQUEST, updateWebContentSaga),
  ]);
}

export default webContentSaga;
