import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_ARTICLE_STATE,
});

export const createArticle = (article) => ({
  type: types.CREATE_ARTICLE.REQUEST,
  article,
});

export const createArticleSuccess = (article) => ({
  type: types.CREATE_ARTICLE.SUCCESS,
  article,
});

export const createArticleFailure = (error) => ({
  type: types.CREATE_ARTICLE.FAILURE,
  error,
});

export const updateArticle = (article) => ({
  type: types.UPDATE_ARTICLE.REQUEST,
  article,
});

export const updateArticleSuccess = () => ({
  type: types.UPDATE_ARTICLE.SUCCESS,
});

export const updateArticleFailure = (error) => ({
  type: types.UPDATE_ARTICLE.FAILURE,
  error,
});

export const syncArticles = () => ({
  type: types.SYNC_ARTICLES.REQUEST,
});

export const syncArticlesSuccess = (articles) => ({
  type: types.SYNC_ARTICLES.SUCCESS,
  articles,
});

export const syncArticlesFailure = (error) => ({
  type: types.SYNC_ARTICLES.FAILURE,
  error,
});

export const fetchArticle = (articleId) => ({
  type: types.FETCH_ARTICLE.REQUEST,
  articleId,
});

export const fetchArticleSuccess = (article) => ({
  type: types.FETCH_ARTICLE.SUCCESS,
  article,
});

export const fetchArticleFailure = (error) => ({
  type: types.FETCH_ARTICLE.FAILURE,
  error,
});
