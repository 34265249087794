import React from 'react';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../helpers/sharedFunction';

const LabelForm = ({ name, label, required }) => {
  const { t } = useTranslation();
  return (
    <Label htmlFor={name}>
      {capitalize(t(label || name))} {required && '*'}
    </Label>
  );
};

export default LabelForm;
