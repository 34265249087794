import React from 'react';

const DisclaimerFooter = () => (
  <p>
    {new Date().getFullYear()} © Media Data Factory
    <i className="dripicons-rocket ml-1 font-size-18 text-info"></i>
  </p>
);

export default DisclaimerFooter;
