import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Collapse,
  Alert,
} from 'reactstrap';
import { Form, Input } from '@availity/form';
import FileUpload from '../Common/FileUpload';
import FormErrorMessage from '../Common/FormErrorMessage';
import TreeSelector from '../Common/TreeSelector';
import formValidation from './formValidation';

import videoCategories from '../../config/videoCategories';
import timeTags from '../../config/timeTags';

import * as videosActions from '../../store/videos/actions';
import { useDispatch } from 'react-redux';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { isEmptyObj } from '../../helpers/sharedFunction';

const FormVideo = (props) => {
  const editingVideo = props.video || {};

  const [files, setFiles] = useState(null);
  const [errorFile, setErrorFile] = useState('');
  const [categories, setCategories] = useState(editingVideo.categories || []);
  const [tags, setTags] = useState(editingVideo.timeTags || []);

  const categoryLabel = categories.length ? `(${categories.join(', ')})` : '';
  const tagsLabel = tags.length ? `(${tags.join(', ')})` : '';

  const handleFiles = (name, files) => {
    setFiles((state) => ({ ...state, [name]: files[0] }));
    setErrorFile('');
  };

  const handleRemoveFiles = (name) => {
    let newFiles = { ...files };
    delete newFiles[name];
    setFiles(isEmptyObj(newFiles) ? null : newFiles);
  };

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    if (!editingVideo.coverImage && !files?.coverImage)
      return setErrorFile('Insert cover image, please!');
    if (!categories.length)
      return setErrorFile('Insert at least one category, please!');
    if (!tags.length)
      return setErrorFile('Insert at least one time tag, please!');
    if (tags.length > 1 && values.featured)
      return setErrorFile('Insert only one time tag, please!');

    dispatch(
      props.create
        ? videosActions.createVideo({
            ...values,
            featured: values.featured || false,
            files,
            categories,
            timeTags: tags,
          })
        : videosActions.updateVideo({
            id: editingVideo.id,
            ...values,
            featured: values.featured || false,
            ...(files && { files }),
            categories,
            timeTags: tags,
          }),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={editingVideo}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values }) => (
            <>
              <Collapse isOpen={!!errorFile}>
                <Alert color="danger">{errorFile}</Alert>
              </Collapse>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input type="text" name="name" id="name" />
                    <FormErrorMessage name="name" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="featured"
                        name="featured"
                        checked={!!values.featured}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="featured">
                        Featured?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="coverImage">Cover Image</Label>
                    <FileUpload
                      name="coverImage"
                      accept={{
                        'image/*': ['.png', '.jpeg', '.jpg'],
                      }}
                      maxFiles={1}
                      uploadMessage="Drop image here or click to upload."
                      handleFiles={handleFiles}
                      handleRemoveFiles={handleRemoveFiles}
                      showPreview
                      existingFile={editingVideo.coverImage}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="categories">
                      Categories{' '}
                      <span className="font-size-11 text-muted">
                        {categoryLabel}
                      </span>
                    </Label>
                    <TreeSelector
                      tree={videoCategories}
                      selected={editingVideo.categories}
                      onCheck={(checked) => setCategories(checked)}
                      noCascade={true}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="timeTags">
                      Time Tags{' '}
                      <span className="font-size-11 text-muted">
                        {tagsLabel}
                      </span>
                    </Label>
                    <TreeSelector
                      tree={timeTags}
                      selected={editingVideo.timeTags}
                      onCheck={(checked) => setTags(checked)}
                      checkModel="leaf"
                      onlyLeafCheckboxes
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormVideo;
