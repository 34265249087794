import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as videoContentsActions from './actions';
import * as videosActions from '../actions';
import { selectVideo, selectVideoContent } from '../../../selectors/video';

import {
  getFirestore,
  query,
  collection,
  doc,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../../helpers/firebase';
import { uploadFileToS3 } from '../../../helpers/file';

import toastr from 'toastr';
import { toDateFirebase, isEqualObjs } from '../../../helpers/sharedFunction';

const STORAGE_BASE_PATH = 'videos';
const db = getFirestore(rsf.app);

function* createVideoContentSaga({ videoContent, video }) {
  try {
    const videoContentsRef = doc(db, 'videoContents', videoContent.id);

    const { id, categories, timeTags, coverImage } = video;
    const { files } = videoContent;

    delete videoContent.id;
    delete videoContent.files;

    let videoUrl = videoContent.videoUrl;
    if (files?.videoFile) {
      const path = `${STORAGE_BASE_PATH}/${id}/videoFiles/${files.videoFile.path}`;
      videoUrl = yield call(uploadFileToS3, files.videoFile, path);
    }

    yield call(rsf.firestore.setDocument, videoContentsRef, {
      categories,
      timeTags,
      coverImage,
      ...videoContent,
      videoUrl,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });

    if (videoContent.published) {
      const video = yield select(selectVideo(videoContent.videoId));
      yield put(
        videosActions.updateVideo({
          id: video.id,
          langs: [...video.langs, videoContent.lang],
        }),
      );
    }

    yield put(videoContentsActions.createVideoContentSuccess());
    toastr.success('VideoContent created!', '');
  } catch (error) {
    yield put(videoContentsActions.createVideoContentFailure(error));
  }
}

function* updateVideoContentSaga({ videoContent }) {
  try {
    const { id, files } = videoContent;
    const videoContentsRef = doc(db, 'videoContents', id);
    delete videoContent.id;
    delete videoContent.files;
    delete videoContent.createdAt;

    let videoUrl = videoContent.videoUrl;
    if (files?.videoFile) {
      console.log(files?.videoFile);
      const path = `${STORAGE_BASE_PATH}/${id}/videoFiles/${files.videoFile.path}`;
      videoUrl = yield call(uploadFileToS3, files.videoFile, path);
    }

    console.log({ videoUrl });

    const prevVideoContent = yield select(selectVideoContent(id));
    const newVideoContent = {
      ...videoContent,
      videoUrl,
      updatedAt: serverTimestamp(),
    };

    console.log({
      prevVideoContent,
      newVideoContent,
      equal: isEqualObjs(prevVideoContent, newVideoContent),
    });

    if (isEqualObjs(prevVideoContent, newVideoContent))
      return yield put(videoContentsActions.updateVideoContentSuccess());

    yield call(rsf.firestore.setDocument, videoContentsRef, newVideoContent, {
      merge: true,
    });

    const video = yield select(selectVideo(videoContent.videoId));
    if (prevVideoContent.published !== newVideoContent.published) {
      const langs = videoContent.published
        ? [...new Set([...video.langs, videoContent.lang])]
        : video.langs.filter((lang) => lang !== videoContent.lang);
      yield put(
        videosActions.updateVideo({
          id: video.id,
          langs,
        }),
      );
    }

    yield put(videoContentsActions.updateVideoContentSuccess());
    toastr.success('VideoContent updated!', '');
  } catch (error) {
    yield put(videoContentsActions.updateVideoContentFailure(error));
  }
}

const videoContentTransformer = (snap) => {
  const data = snap.data();
  return {
    id: snap.id,
    ...data,
    ...(data.createdAt && {
      createdAt: toDateFirebase(snap, data).toDate(),
    }),
    ...(data.updatedAt && {
      updatedAt: toDateFirebase(snap, data, 'updatedAt').toDate(),
    }),
  };
};

function* syncVideoContentsSaga() {
  const videoContentsRef = query(
    collection(db, 'videoContents'),
    orderBy('createdAt', 'desc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, videoContentsRef, {
    successActionCreator: videoContentsActions.syncVideoContentsSuccess,
    failureActionCreator: videoContentsActions.syncVideoContentsFailure,
    transform: (payload) =>
      payload.docs.map((snap) => videoContentTransformer(snap)),
  });

  yield take(types.RESET_VIDEO_CONTENT_STATE);
  yield cancel(task);
}

function* fetchVideoContentSaga({ videoContentId }) {
  try {
    const videoContentRef = doc(db, 'videoContents', videoContentId);

    const videoContentSnap = yield call(
      rsf.firestore.getDocument,
      videoContentRef,
    );

    const videoContent = videoContentSnap.exists()
      ? videoContentTransformer(videoContentSnap)
      : null;

    yield put(videoContentsActions.fetchVideoContentSuccess(videoContent));
  } catch (error) {
    yield put(videoContentsActions.fetchVideoContentFailure(error));
  }
}

function* videoContentSaga() {
  yield all([
    takeLatest(types.SYNC_VIDEO_CONTENTS.REQUEST, syncVideoContentsSaga),
    takeLeading(types.CREATE_VIDEO_CONTENT.REQUEST, createVideoContentSaga),
    takeLeading(types.UPDATE_VIDEO_CONTENT.REQUEST, updateVideoContentSaga),
    takeLeading(types.FETCH_VIDEO_CONTENT.REQUEST, fetchVideoContentSaga),
  ]);
}

export default videoContentSaga;
