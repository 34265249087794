import types from './actionTypes';
import initialFilters from '../../config/filters';

const initialState = {
  loading: false,
  startDate: null,
  endDate: null,
  filters: Object.assign({}, initialFilters),
  insights: null,
  error: '',
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_DASHBOARD_STATE:
      return initialState;
    case types.SET_DASHBOARD_FILTERS.REQUEST:
    case types.SET_DASHBOARD_FILTERS.SUCCESS:
      return {
        ...state,
        loading: false,
        startDate: action.startDate,
        endDate: action.endDate,
        filters: action.filters,
        error: '',
      };
    case types.SET_DASHBOARD_FILTERS.FAILURE:
    default:
      return state;
  }
};

export default dashboardReducer;
