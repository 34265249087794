import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_CONTEST_STATE,
});

export const createContest = (contest) => ({
  type: types.CREATE_CONTEST.REQUEST,
  contest,
});

export const createContestSuccess = (contest) => ({
  type: types.CREATE_CONTEST.SUCCESS,
  contest,
});

export const createContestFailure = (error) => ({
  type: types.CREATE_CONTEST.FAILURE,
  error,
});

export const updateContest = (contest) => ({
  type: types.UPDATE_CONTEST.REQUEST,
  contest,
});

export const updateContestSuccess = () => ({
  type: types.UPDATE_CONTEST.SUCCESS,
});

export const updateContestFailure = (error) => ({
  type: types.UPDATE_CONTEST.FAILURE,
  error,
});

export const syncContests = () => ({
  type: types.SYNC_CONTESTS.REQUEST,
});

export const syncContestsSuccess = (contests) => ({
  type: types.SYNC_CONTESTS.SUCCESS,
  contests,
});

export const syncContestsFailure = (error) => ({
  type: types.SYNC_CONTESTS.FAILURE,
  error,
});
