import '@availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  title: yup.string().required(),
  published: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
});

export default formValidation;
