import { all } from 'redux-saga/effects';

//public
import AuthSaga from './auth/saga';
import DashboardSaga from './dashboard/saga';
import LayoutSaga from './layout/saga';
import UserSaga from './users/saga';
import ArticleSaga from './articles/saga';
import ArticleContentSaga from './articles/contents/saga';
import VideoSaga from './videos/saga';
import VideoContentSaga from './videos/contents/saga';
import SymptomSaga from './symptoms/saga';
import SymptomContentSaga from './symptoms/contents/saga';
import ContestSaga from './contests/saga';
import webContentSaga from './webContents/saga';
import PushNotificationSaga from './pushNotifications/saga';
import MemberSaga from './members/saga';
import CountrySaga from './countries/saga';

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    LayoutSaga(),
    UserSaga(),
    DashboardSaga(),
    ArticleSaga(),
    ArticleContentSaga(),
    VideoSaga(),
    VideoContentSaga(),
    SymptomSaga(),
    SymptomContentSaga(),
    ContestSaga(),
    webContentSaga(),
    PushNotificationSaga(),
    MemberSaga(),
    CountrySaga(),
  ]);
}
