import types from './actionTypes';

const initialState = {
  loading: false,
  videos: [],
  error: '',
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_VIDEO_STATE:
      return initialState;
    case types.CREATE_VIDEO.REQUEST:
    case types.UPDATE_VIDEO.REQUEST:
    case types.SYNC_VIDEOS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_VIDEO.SUCCESS:
    case types.UPDATE_VIDEO.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SYNC_VIDEOS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        videos: [...action.videos],
      };
    case types.CREATE_VIDEO.FAILURE:
    case types.UPDATE_VIDEO.FAILURE:
    case types.SYNC_VIDEOS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default videoReducer;
