const statsTypes = {
  RESET_CONTEST_STATE: 'RESET_CONTEST_STATE',
  CREATE_CONTEST: {
    REQUEST: 'CREATE_CONTEST.REQUEST',
    SUCCESS: 'CREATE_CONTEST.SUCCESS',
    FAILURE: 'CREATE_CONTEST.FAILURE',
  },
  UPDATE_CONTEST: {
    REQUEST: 'UPDATE_CONTEST.REQUEST',
    SUCCESS: 'UPDATE_CONTEST.SUCCESS',
    FAILURE: 'UPDATE_CONTEST.FAILURE',
  },
  SYNC_CONTESTS: {
    REQUEST: 'SYNC_CONTESTS.REQUEST',
    SUCCESS: 'SYNC_CONTESTS.SUCCESS',
    FAILURE: 'SYNC_CONTESTS.FAILURE',
  },
};

export default statsTypes;
