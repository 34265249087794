import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as articleContentsActions from './actions';
import * as articlesActions from '../actions';
import {
  selectArticle,
  selectArticleContent,
} from '../../../selectors/article';

import {
  getFirestore,
  query,
  collection,
  doc,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../../helpers/firebase';
import { uploadFileToS3 } from '../../../helpers/file';

import toastr from 'toastr';
import { toDateFirebase, isEqualObjs } from '../../../helpers/sharedFunction';

const STORAGE_BASE_PATH = 'articles';
const db = getFirestore(rsf.app);

function* createArticleContentSaga({ articleContent, article }) {
  try {
    const { id, files } = articleContent;
    const articleContentsRef = doc(db, 'articleContents', id);

    let buttonTimelineImageURL;
    if (files) {
      const { buttonTimelineImage } = articleContent.files;
      [buttonTimelineImageURL] = yield all(
        Object.values({ buttonTimelineImage }).map((file) => {
          const path = `${STORAGE_BASE_PATH}/${id}/buttonTimelineImages/${file.path}`;
          return call(uploadFileToS3, file, path);
        }),
      );
    }

    delete articleContent.id;
    delete articleContent.files;

    const { categories, timeTags, coverImage } = article;

    yield call(rsf.firestore.setDocument, articleContentsRef, {
      categories,
      timeTags,
      coverImage,
      ...articleContent,
      ...(buttonTimelineImageURL && {
        buttonTimelineImage: buttonTimelineImageURL,
      }),
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });

    if (articleContent.published) {
      const article = yield select(selectArticle(articleContent.articleId));
      yield put(
        articlesActions.updateArticle({
          id: article.id,
          langs: [...article.langs, articleContent.lang],
        }),
      );
    }

    yield put(articleContentsActions.createArticleContentSuccess());
    toastr.success('ArticleContent created!', '');
  } catch (error) {
    yield put(articleContentsActions.createArticleContentFailure(error));
  }
}

function* updateArticleContentSaga({ articleContent }) {
  try {
    const { id, files, buttonTimeline } = articleContent;
    const articleContentsRef = doc(db, 'articleContents', id);

    let buttonTimelineImageURL;
    if (buttonTimeline && files?.buttonTimelineImage) {
      const path = `${STORAGE_BASE_PATH}/${id}/buttonTimelineImages/${files.buttonTimelineImage.path}`;
      buttonTimelineImageURL = yield call(
        uploadFileToS3,
        files.buttonTimelineImage,
        path,
      );
    }

    delete articleContent.id;
    delete articleContent.files;
    delete articleContent.createdAt;

    const prevArticleContent = yield select(selectArticleContent(id));
    const newArticleContent = {
      ...articleContent,
      ...(buttonTimelineImageURL && {
        buttonTimelineImage: buttonTimelineImageURL,
      }),
      updatedAt: serverTimestamp(),
    };

    if (isEqualObjs(prevArticleContent, newArticleContent))
      return yield put(articleContentsActions.updateArticleContentSuccess());

    yield call(
      rsf.firestore.setDocument,
      articleContentsRef,
      newArticleContent,
      { merge: true },
    );

    const article = yield select(selectArticle(articleContent.articleId));
    if (prevArticleContent.published !== newArticleContent.published) {
      const langs = articleContent.published
        ? [...new Set([...article.langs, articleContent.lang])]
        : article.langs.filter((lang) => lang !== articleContent.lang);
      yield put(
        articlesActions.updateArticle({
          id: article.id,
          langs,
        }),
      );
    }

    yield put(articleContentsActions.updateArticleContentSuccess());
    toastr.success('ArticleContent updated!', '');
  } catch (error) {
    yield put(articleContentsActions.updateArticleContentFailure(error));
  }
}

const articleContentTransformer = (snap) => {
  const data = snap.data();
  return {
    id: snap.id,
    ...data,
    ...(data.createdAt && {
      createdAt: toDateFirebase(snap, data).toDate(),
    }),
    ...(data.updatedAt && {
      updatedAt: toDateFirebase(snap, data, 'updatedAt').toDate(),
    }),
  };
};

function* syncArticleContentsSaga() {
  const articleContentsRef = query(
    collection(db, 'articleContents'),
    orderBy('createdAt', 'desc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, articleContentsRef, {
    successActionCreator: articleContentsActions.syncArticleContentsSuccess,
    failureActionCreator: articleContentsActions.syncArticleContentsFailure,
    transform: (payload) =>
      payload.docs.map((snap) => articleContentTransformer(snap)),
  });

  yield take(types.RESET_ARTICLE_CONTENT_STATE);
  yield cancel(task);
}

function* fetchArticleContentSaga({ articleContentId }) {
  try {
    const articleContentRef = doc(db, 'articleContents', articleContentId);

    const articleContentSnap = yield call(
      rsf.firestore.getDocument,
      articleContentRef,
    );

    const articleContent = articleContentSnap.exists()
      ? articleContentTransformer(articleContentSnap)
      : null;

    yield put(
      articleContentsActions.fetchArticleContentSuccess(articleContent),
    );
  } catch (error) {
    yield put(articleContentsActions.fetchArticleContentFailure(error));
  }
}

function* articleContentSaga() {
  yield all([
    takeLatest(types.SYNC_ARTICLE_CONTENTS.REQUEST, syncArticleContentsSaga),
    takeLeading(types.CREATE_ARTICLE_CONTENT.REQUEST, createArticleContentSaga),
    takeLeading(types.UPDATE_ARTICLE_CONTENT.REQUEST, updateArticleContentSaga),
    takeLeading(types.FETCH_ARTICLE_CONTENT.REQUEST, fetchArticleContentSaga),
  ]);
}

export default articleContentSaga;
