import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as pushNotificationsActions from '../../store/pushNotifications/actions';
import { useDispatch, useSelector } from 'react-redux';
import FormPushNotification from '../../components/PushNotifications/FormPushNotification';
import Modal from '../../components/Common/Modal/Modal';
import Alert from '../../components/Common/Modal/Alert';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/PushNotifications/tableColumns';
import {
  ExportButtonPlacement,
  ToggleColumnButton,
  CreateButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import moment from 'moment';
import { STATUS } from '../../config/pushNotifications';

const List = (props) => {
  const loading = useSelector((state) => state.PushNotification.loading);
  const pushNotifications = useSelector(
    (state) => state.PushNotification.pushNotifications,
  );

  const [selectedPushNotification, setSelectedPushNotification] =
    useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [sendAlert, setSendAlert] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const startDate = useSelector((state) => state.PushNotification.startDate);
  const endDate = useSelector((state) => state.PushNotification.endDate);
  const dataFilters = useSelector((state) => state.PushNotification.filters);
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('month'),
    endDate: momentEndDate || moment.utc(),
    lang: dataFilters?.lang || '',
  });

  const dispatch = useDispatch();

  const refresh = useCallback(({ startDate, endDate, lang }) => {
    const selectedStartDate = startDate.utc().startOf('day').toDate();
    const selectedEndDate = endDate.utc().endOf('day').toDate();
    dispatch(
      pushNotificationsActions.fetchPushNotifications(
        selectedStartDate,
        selectedEndDate,
        {
          lang,
        },
      ),
    );
    setSelectedFilters({ startDate, endDate, lang });
  });

  useEffect(() => {
    pushNotifications.length === 0 && refresh(selectedFilters);
  }, []);

  const openViewModal = (pushNotification) => {
    setSelectedPushNotification(pushNotification);
    setViewModal(true);
  };

  const openEditModal = (pushNotification = null) => {
    setSelectedPushNotification(pushNotification);
    setEditModal(true);
  };

  const openSendAlert = (pushNotification) => {
    setSelectedPushNotification(pushNotification);
    setSendAlert(true);
  };

  const handleSendPushNotification = () => {
    const { id, status, sendDate } = selectedPushNotification;
    if (status === STATUS.DRAFT)
      dispatch(
        pushNotificationsActions.updatePushNotification({
          id,
          status: sendDate < new Date() ? STATUS.READY : STATUS.SCHEDULED,
        }),
      );
    setSendAlert(false);
  };

  const columns = useMemo(
    () => createTableColumns(openViewModal, openEditModal, openSendAlert),
    [],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Push Notifications"
            breadcrumbItem="List"
            onRefresh={() => refresh(selectedFilters)}
            loading={loading}
          />
          <DateRangeFilter
            onUpdate={refresh}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
            showFilters
            initialFilters={selectedFilters}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Push Notification"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={pushNotifications}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="PushNotification"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedPushNotification}
              collection="pushNotifications"
              icon="bx bx-bell"
            />
          </Modal>
          <Modal
            size="xl"
            title="Push Notification"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormPushNotification
              closeModal={() => setEditModal(false)}
              create={!selectedPushNotification}
              loading={loading}
              pushNotification={selectedPushNotification}
            />
          </Modal>
          <Alert
            isOpen={sendAlert}
            title="Do you want to send this Push?"
            confirmBtnLabel="Send"
            onCancel={() => setSendAlert(false)}
            onConfirm={handleSendPushNotification}>
            There&apos;s no coming back!
          </Alert>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
