import { put, all, call, takeLeading, takeLatest } from 'redux-saga/effects';
import types from './actionTypes';
import * as pushNotificationsActions from './actions';
import {
  getFirestore,
  collection,
  doc,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import { uploadFileToS3 } from '../../helpers/file';
import toastr from 'toastr';
import { toDateFirebase } from '../../helpers/sharedFunction';
import { STATUS } from '../../config/pushNotifications';

const STORAGE_BASE_PATH = 'pushNotifications';
const db = getFirestore(rsf.app);

export const pushNotificationTransformer = (pushNotification) => {
  const data = pushNotification.data();
  return {
    id: pushNotification.id,
    ...data,
    ...(data.createdAt && {
      createdAt: toDateFirebase(pushNotification, data).toDate(),
    }),
    ...(data.updatedAt && {
      updatedAt: toDateFirebase(pushNotification, data, 'updatedAt').toDate(),
    }),
    ...(data.sendDate && {
      sendDate: toDateFirebase(pushNotification, data, 'sendDate').toDate(),
    }),
  };
};

function* createPushNotificationSaga({ pushNotification }) {
  try {
    const pushNotificationsRef = collection(db, 'pushNotifications');
    const id = pushNotificationsRef.id;

    let imageURL;
    if (pushNotification.files) {
      const { image } = pushNotification.files;
      [imageURL] = yield all(
        Object.values({ image }).map((file) => {
          const path = `${STORAGE_BASE_PATH}/${id}/images/${file.path}`;
          return call(uploadFileToS3, file, path);
        }),
      );
    }

    delete pushNotification.files;

    const newPushNotification = {
      ...pushNotification,
      status: STATUS.DRAFT,
      ...(imageURL && { image: imageURL }),
    };
    yield call(rsf.firestore.addDocument, pushNotificationsRef, {
      ...newPushNotification,
      createdAt: serverTimestamp(),
    });
    yield put(
      pushNotificationsActions.createPushNotificationSuccess({
        id,
        ...newPushNotification,
        createdAt: new Date(),
      }),
    );
    toastr.success('Push Notification created!', '');
  } catch (error) {
    yield put(pushNotificationsActions.createPushNotificationFailure(error));
  }
}

function* updatePushNotificationSaga({ pushNotification }) {
  try {
    const { id, files } = pushNotification;
    const pushNotificationRef = doc(db, 'pushNotifications', id);

    let imageURL;
    if (files?.image) {
      const path = `${STORAGE_BASE_PATH}/${id}/images/${files.image.path}`;
      imageURL = yield call(uploadFileToS3, files.image, path);
    }

    delete pushNotification.id;
    delete pushNotification.files;
    delete pushNotification.createdAt;

    const newPushNotification = {
      ...pushNotification,
      ...(imageURL && { image: imageURL }),
    };

    yield call(
      rsf.firestore.setDocument,
      pushNotificationRef,
      {
        ...newPushNotification,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(
      pushNotificationsActions.updatePushNotificationSuccess({
        id,
        ...newPushNotification,
      }),
    );
    toastr.success('Push Notification updated!', '');
  } catch (error) {
    yield put(pushNotificationsActions.updatePushNotificationFailure(error));
    toastr.error(error.message);
  }
}

function* fetchPushNotificationsSaga({ startDate, endDate, filters }) {
  try {
    const pushNotificationsRef = rsf.firestore.createCollectionRefWithFilters(
      'pushNotifications',
      startDate,
      endDate,
      filters,
    );
    const pushNotificationsSnap = yield call(
      rsf.firestore.getCollection,
      pushNotificationsRef,
    );
    const pushNotifications = pushNotificationsSnap.docs.map(
      (pushNotification) => pushNotificationTransformer(pushNotification),
    );
    yield put(
      pushNotificationsActions.fetchPushNotificationsSuccess(
        pushNotifications,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(pushNotificationsActions.fetchPushNotificationsFailure(error));
  }
}
function* pushNotificationSaga() {
  yield all([
    takeLatest(
      types.FETCH_PUSH_NOTIFICATIONS.REQUEST,
      fetchPushNotificationsSaga,
    ),
    takeLeading(
      types.CREATE_PUSH_NOTIFICATION.REQUEST,
      createPushNotificationSaga,
    ),
    takeLeading(
      types.UPDATE_PUSH_NOTIFICATION.REQUEST,
      updatePushNotificationSaga,
    ),
  ]);
}

export default pushNotificationSaga;
