import { createSelector } from 'reselect';

export const selectVideo = (id) =>
  createSelector(
    (state) => state.Video.videos,
    (videos) => {
      if (!id) return null;
      return videos.find((video) => video.id === id);
    },
  );

export const selectVideoContent = (id) =>
  createSelector(
    (state) => state.VideoContent.videoContents,
    (videoContents) => {
      if (!id) return null;
      return videoContents.find((videoContent) => videoContent.id === id);
    },
  );
