import { Timestamp } from 'firebase/firestore';
import isEqual from 'react-fast-compare';

export const toDateFirebase = (snap, data, key = 'createdAt') => {
  return (!data.createdAt && snap.metadata.hasPendingWrites) ||
    typeof data[key] === 'string'
    ? Timestamp.now()
    : data[key];
};

export const isDate = (date) =>
  new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));

export const DDMMYYYYToDate = (stringDate, separator = '-') => {
  //27-09-2020
  const day = stringDate.split(separator)[0];
  const month = stringDate.split(separator)[1];
  const year = stringDate.split(separator)[2];

  const date = `${year}-${month}-${day}`;

  return !isNaN(Date.parse(date)) ? new Date(date) : null;
};

export const dateToYYYYMMDD = (date, separator = '-') => {
  //date to yyyy-mm-dd
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const yyyy = date.getFullYear();

  return yyyy + separator + mm + separator + dd;
};

export const DDMMYYYYHHmmssToDateTime = (stringDate) => {
  //27-09-2020 00:24:10
  const date = stringDate.split(' ')[0];
  const time = stringDate.split(' ')[1];
  const day = date.split('-')[0];
  const month = date.split('-')[1];
  const year = date.split('-')[2];

  return new Date(`${year}-${month}-${day} ${time}`);
};

export const YYYYMMDDHHmmssToDateTime = (stringDate) => {
  //2020-09-27T00:24:10
  const date = stringDate.split('T')[0];
  const time = stringDate.split('T')[1];
  const day = date.split('-')[2];
  const month = date.split('-')[1];
  const year = date.split('-')[0];

  return new Date(`${year}-${month}-${day} ${time}`);
};

export const dateIsInRange = (date, startDate, endDate) => {
  const today = date.getTime();
  const from =
    typeof myVar === 'string'
      ? new Date(startDate).getTime()
      : startDate.getTime();
  const to =
    typeof myVar === 'string' ? new Date(endDate).getTime() : endDate.getTime();
  return today >= from && today <= to;
};

export const addHours = (date, hours) => {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return date;
};

export const arrayChunks = (array, chunk_size) =>
  Array(Math.ceil(array.length / chunk_size))
    .fill()
    .map((_, index) => index * chunk_size)
    .map((begin) => array.slice(begin, begin + chunk_size));

export const checkNullObject = (obj) => {
  for (let key in obj) {
    if (obj[key] !== null) return false;
  }
  return true;
};

export const checkEmptyObject = (obj) => {
  for (let key in obj) {
    if (obj[key] && obj[key].length) return false;
  }
  return true;
};

// Returns an array of dates between the two dates
export const getDates = (startDate, endDate) => {
  let dates = [],
    currentDate = startDate,
    addDays = function (days) {
      let date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
};

// Returns seconds between the two dates
export const getSeconds = (startDate, endDate) =>
  Math.abs((startDate.getTime() - endDate.getTime()) / 1000);

// Returns days between the two dates
export const getDays = (startDate, endDate) =>
  getSeconds(startDate, endDate) / 86400;

// Returns HH:MM:SS from seconds
export const secondsToHHMMSS = (timeSeconds, fromMinutes = false) => {
  var secNum = parseInt(timeSeconds, 10); // don't forget the second param
  var hours = Math.floor(secNum / 3600);
  var minutes = Math.floor((secNum - hours * 3600) / 60);
  var seconds = secNum - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return fromMinutes
    ? minutes + ':' + seconds
    : hours + ':' + minutes + ':' + seconds;
};

export const dateToTimeString = (date) =>
  `${String(date.getHours()).padStart(2, '0')}:${String(
    date.getMinutes(),
  ).padStart(2, '0')}`;

export const selectTranslation = (options, t) =>
  options.map(({ value, label }) => ({ value, label: t(label) }));

export const capitalize = ([first, ...rest], lowerRest = false) =>
  first.toUpperCase() +
  (lowerRest ? rest.join('').toLowerCase() : rest.join(''));

export const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const linkify = (text) => {
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  });
};

export const normalizePhone = (phoneNumber) => {
  // Remove all spaces
  let mobile = phoneNumber.replace(/ /g, '');

  if (mobile.substring(0, 2) === '00') return `+${mobile.substring(2)}`;

  if (mobile[0] !== '+' && mobile.length <= 10) return `+39${mobile}`; //assume that is a italian phone number

  return mobile[0] === '+' ? mobile : `+${mobile}`;
};

export const uniqueArrayByKey = (array, key) => {
  let uniques = [];
  array.forEach(
    (obj) =>
      !uniques.find((song) => song[key] === obj[key]) && uniques.push(obj),
  );
  return uniques;
};

export const convertCamelCaseString = (string) =>
  string.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());

//input: interleave ([0, 2, 4, 6], [1, 3, 5])) - output: [ 0 1 2 3 4 5 6 ]
export const interleave = ([x, ...xs], ys = []) =>
  x === undefined ? ys : [x, ...interleave(ys, xs)];

export const secondsToDhms = (seconds) => {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  /*
  const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : '';
  const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
  */

  const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : '';
  const hDisplay = h > 0 ? `${h} hr, ` : '';
  const mDisplay = m > 0 ? `${m} min, ` : '';
  const sDisplay = s > 0 ? `${s} sec ` : '';
  return dDisplay + hDisplay + mDisplay + sDisplay;
};

export const secondsToHms = (seconds) => {
  seconds = Number(seconds);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const hDisplay = h > 0 ? `${h} hr, ` : '';
  const mDisplay = m > 0 ? `${m} min, ` : '';
  const sDisplay = s > 0 ? `${s} sec ` : '';
  return hDisplay + mDisplay + sDisplay;
};

export const mergeAndSum = (arrayMaps) => {
  if (!Array.isArray(arrayMaps) || !arrayMaps.length) return {};

  let result = arrayMaps[0];
  for (let i = 1; i < arrayMaps.length; i++) {
    result = Object.entries(arrayMaps[i]).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: (acc[key] || 0) + value }),
      { ...result },
    );
  }
  return result;
};

//sort from highest to lowest
export const orderValueMap = (map, order = 'asc') =>
  Object.entries(map)
    .sort(([, a], [, b]) => (order === 'asc' ? a - b : b - a))
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

export const orderMapByKey = (o) =>
  Object.keys(o)
    .sort()
    .reduce((r, k) => ((r[k] = o[k]), r), {});

export const isEmptyObj = (obj) => !obj || Object.keys(obj).length === 0;

export const sliceObj = (obj, limit) =>
  Object.keys(obj)
    .slice(0, limit)
    .reduce((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});

export const splitStringInTwoPart = (string, nCharacters) => {
  string = string.replace(/\s+/g, ' ').trim().substring(0, nCharacters) + '...';
  let stringCopy = [...string.split(' ')];

  let newString = ['', ''];
  for (let chuck of string) {
    console.log(chuck);
    if (chuck.length + newString[0].length > (nCharacters * 1.2) / 2) break;
    newString[0] += `${chuck} `;
    stringCopy.shift();
  }
  newString[1] = stringCopy.join(' ');
  return newString;
};

export const checkField = (value, type = null, required = true) => {
  if (!required && !value) return true;
  if (value === null || value === undefined) return false;

  if (type)
    switch (type) {
      case 'date-string':
        return isDate(value);
      case 'numeric':
        return !isNaN(value);
      case 'id':
        return /^[a-z0-9]+$/i.test(value.trim());
      case 'string':
        return !!(typeof value === 'string' && value.trim());
      case 'percentage':
        return /^\d+(,\d{1,2})?%$/.test(value);
      default:
        return false;
    }

  return false;
};

export const addOrRemove = (arr, item) =>
  arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];

export const objMap = (obj, func) =>
  Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, func(v)]));

export const isEqualObjs = (a, b) => {
  if (a === b) return true;
  const one = Object.assign({}, a);
  const two = Object.assign({}, b);
  ['id', 'createdAt', 'updatedAt'].forEach((key) => {
    delete one[key];
    delete two[key];
  });
  return isEqual(one, two);
};

export const findAllParents = (tree, targetValues, parents = []) => {
  const foundParents = [];

  for (const node of tree) {
    if (targetValues.includes(node.value))
      foundParents.push(...parents, node.value);

    if (node.children) {
      const result = findAllParents(node.children, targetValues, [
        ...parents,
        node.value,
      ]);
      if (result.length > 0) foundParents.push(...result);
    }
  }
  return [...new Set(foundParents)];
};
