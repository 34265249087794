import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_SYMPTOM_CONTENT_STATE,
});

export const createSymptomContent = (symptomContent, symptom) => ({
  type: types.CREATE_SYMPTOM_CONTENT.REQUEST,
  symptomContent,
  symptom,
});

export const createSymptomContentSuccess = () => ({
  type: types.CREATE_SYMPTOM_CONTENT.SUCCESS,
});

export const createSymptomContentFailure = (error) => ({
  type: types.CREATE_SYMPTOM_CONTENT.FAILURE,
  error,
});

export const updateSymptomContent = (symptomContent) => ({
  type: types.UPDATE_SYMPTOM_CONTENT.REQUEST,
  symptomContent,
});

export const updateSymptomContentSuccess = () => ({
  type: types.UPDATE_SYMPTOM_CONTENT.SUCCESS,
});

export const updateSymptomContentFailure = (error) => ({
  type: types.UPDATE_SYMPTOM_CONTENT.FAILURE,
  error,
});

export const syncSymptomContents = () => ({
  type: types.SYNC_SYMPTOM_CONTENTS.REQUEST,
});

export const syncSymptomContentsSuccess = (symptomContents) => ({
  type: types.SYNC_SYMPTOM_CONTENTS.SUCCESS,
  symptomContents,
});

export const syncSymptomContentsFailure = (error) => ({
  type: types.SYNC_SYMPTOM_CONTENTS.FAILURE,
  error,
});

export const fetchSymptomContent = (symptomContentId) => ({
  type: types.FETCH_SYMPTOM_CONTENT.REQUEST,
  symptomContentId,
});

export const fetchSymptomContentSuccess = (symptomContent) => ({
  type: types.FETCH_SYMPTOM_CONTENT.SUCCESS,
  symptomContent,
});

export const fetchSymptomContentFailure = (error) => ({
  type: types.FETCH_SYMPTOM_CONTENT.FAILURE,
  error,
});
