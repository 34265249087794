import '@availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  gender: yup.number().required(),
  symptomType: yup.number().label('type').required(),
});

export default formValidation;
