import types from './actionTypes';

const initialState = {
  layoutType: 'vertical',
  layoutWidth: 'fluid',
  leftSideBarTheme: 'dark',
  leftSideBarType: 'condensed',
  topbarTheme: 'light',
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
  showSensitiveData: true,
};

const Layout = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };
    case types.CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.layout,
      };
    case types.CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.width,
      };
    case types.CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.theme,
      };
    case types.CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.sidebarType,
      };
    case types.CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      };
    case types.SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.isOpen,
      };
    case types.SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.isOpen,
      };
    case types.TOGGLE_LEFT_MENU:
      return {
        ...state,
        leftMenu: action.payload,
      };
    case types.TOGGLE_SENSITIVE_DATA:
      return {
        ...state,
        showSensitiveData: action.showData,
      };
    default:
      return state;
  }
};

export default Layout;
