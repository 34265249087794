import React, { useState, useEffect } from 'react';

import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';

import * as symptomsActions from '../../store/symptoms/actions';
import { useDispatch, useSelector } from 'react-redux';

import FormSymptom from '../../components/Symptoms/FormSymptom';
import FormSymptomContent from '../../components/Symptoms/contents/FormSymptomContent';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import createTableColumns from '../../components/Symptoms/tableColumns';
import { CreateButton } from '../../components/Common/DataTable/tableActionButtons';

const List = (props) => {
  const loading = useSelector((state) => state.Symptom.loading);
  const symptoms = useSelector((state) => state.Symptom.symptoms);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [langModal, setLangModal] = useState(false);
  const [selectedSymptom, setSelectedSymptom] = useState(null);
  const [selectedLang, setSelectedLang] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(symptomsActions.syncSymptoms());
  }, []);

  const openEditModal = (symptom = null) => {
    setSelectedSymptom(symptom);
    setEditModal(true);
  };

  const openViewModal = (symptom) => {
    setSelectedSymptom(symptom);
    setViewModal(true);
  };

  const openLangModal = (symptom, lang) => {
    setSelectedSymptom(symptom);
    setSelectedLang(lang);
    setLangModal(true);
  };

  const columns = createTableColumns(
    openViewModal,
    openEditModal,
    openLangModal,
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Symptoms" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="text-sm-right">
                    <CreateButton
                      label="Create new Symptom"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                  </div>
                  <DataTableNext
                    loading={loading}
                    rows={symptoms}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            title="Symptom"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedSymptom}
              icon="mdi mdi-hospital-box-outline"
            />
          </Modal>
          <Modal
            size="xl"
            title="Symptom"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormSymptom
              closeModal={() => setEditModal(false)}
              create={!selectedSymptom}
              loading={loading}
              symptom={selectedSymptom}
            />
          </Modal>
          <Modal
            size="xl"
            title={`${selectedSymptom?.name} - ${selectedLang?.label}`}
            isOpen={langModal}
            toggle={() => setLangModal(!langModal)}
            scrollable={false}>
            <FormSymptomContent
              closeModal={() => setLangModal(false)}
              symptom={selectedSymptom}
              lang={selectedLang?.value}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
