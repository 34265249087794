import '@availity/yup';
import * as yup from 'yup';

import roles from '../../config/roles';

const formValidation = (emailsInUse) =>
  yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    displayName: yup.string().required(),
    email: yup.string().email().notOneOf(emailsInUse),
    role: yup.string().required(),
    active: yup.boolean().required(),
  });

export default formValidation;
