import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Collapse,
  Alert,
} from 'reactstrap';
import { Form, Input } from '@availity/form';
import FileUpload from '../Common/FileUpload';
import FormErrorMessage from '../Common/FormErrorMessage';
import TreeSelector from '../Common/TreeSelector';
import formValidation from './formValidation';

import articleCategories from '../../config/articleCategories';
import timeTags from '../../config/timeTags';

import * as articlesActions from '../../store/articles/actions';
import { useDispatch } from 'react-redux';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { isEmptyObj } from '../../helpers/sharedFunction';

const FormArticle = ({ loading, create, article, closeModal }) => {
  const [files, setFiles] = useState(null);
  const [errorFile, setErrorFile] = useState('');
  const [categories, setCategories] = useState(article?.categories || []);
  const [tags, setTags] = useState(article?.timeTags || []);

  const categoryLabel = categories.length ? `(${categories.join(', ')})` : '';
  const tagsLabel = tags.length ? `(${tags.join(', ')})` : '';

  const handleFiles = (name, files) => {
    console.log(files[0]);
    setFiles((state) => ({ ...state, [name]: files[0] }));
    setErrorFile('');
  };

  const handleRemoveFiles = (name) => {
    let newFiles = { ...files };
    delete newFiles[name];
    setFiles(isEmptyObj(newFiles) ? null : newFiles);
  };

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    if (!article?.coverImage && !files?.coverImage)
      return setErrorFile('Insert cover image, please!');
    if (!categories.length)
      return setErrorFile('Insert at least one category, please!');
    if (!tags.length)
      return setErrorFile('Insert at least one time tag, please!');

    dispatch(
      create
        ? articlesActions.createArticle({
            ...values,
            files,
            categories,
            timeTags: tags,
          })
        : articlesActions.updateArticle({
            id: article.id,
            ...values,
            ...(files && { files }),
            categories,
            timeTags: tags,
          }),
    );
    closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={article || {}}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid }) => (
            <>
              <Collapse isOpen={!!errorFile}>
                <Alert color="danger">{errorFile}</Alert>
              </Collapse>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input type="text" name="name" id="name" />
                    <FormErrorMessage name="name" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="coverImage">Cover Image</Label>
                    <FileUpload
                      name="coverImage"
                      accept={{
                        'image/*': ['.png', '.jpeg', '.jpg'],
                      }}
                      maxFiles={1}
                      uploadMessage="Drop image here or click to upload."
                      handleFiles={handleFiles}
                      handleRemoveFiles={handleRemoveFiles}
                      showPreview
                      existingFile={article?.coverImage}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="categories">
                      Categories{' '}
                      <span className="font-size-11 text-muted">
                        {categoryLabel}
                      </span>
                    </Label>
                    <TreeSelector
                      tree={articleCategories}
                      selected={article?.categories}
                      onCheck={(checked) => setCategories(checked)}
                      checkModel="leaf"
                      onlyLeafCheckboxes
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="timeTags">
                      Time Tags{' '}
                      <span className="font-size-11 text-muted">
                        {tagsLabel}
                      </span>
                    </Label>
                    <TreeSelector
                      tree={timeTags}
                      selected={article?.timeTags}
                      onCheck={(checked) => setTags(checked)}
                      checkModel="leaf"
                      onlyLeafCheckboxes
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton disabled={!isValid || loading} create={create} />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormArticle;
