import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as symptomContentsActions from './actions';
import * as symptomsActions from '../actions';
import {
  selectSymptom,
  selectSymptomContent,
} from '../../../selectors/symptom';

import {
  getFirestore,
  query,
  collection,
  doc,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../../helpers/firebase';

import toastr from 'toastr';
import { toDateFirebase, isEqualObjs } from '../../../helpers/sharedFunction';

const db = getFirestore(rsf.app);

function* createSymptomContentSaga({ symptomContent, symptom }) {
  try {
    const symptomContentsRef = doc(db, 'symptomContents', symptomContent.id);

    delete symptomContent.id;

    const { bodyParts, periods, symptomType, gender, symptomIds } = symptom;

    yield call(rsf.firestore.setDocument, symptomContentsRef, {
      bodyParts,
      periods,
      symptomType,
      gender,
      symptomIds,
      ...symptomContent,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });

    if (symptomContent.published) {
      const symptom = yield select(selectSymptom(symptomContent.symptomId));
      yield put(
        symptomsActions.updateSymptom({
          id: symptom.id,
          langs: [...symptom.langs, symptomContent.lang],
        }),
      );
    }

    yield put(symptomContentsActions.createSymptomContentSuccess());
    toastr.success('SymptomContent created!', '');
  } catch (error) {
    yield put(symptomContentsActions.createSymptomContentFailure(error));
  }
}

function* updateSymptomContentSaga({ symptomContent }) {
  try {
    const { id } = symptomContent;
    const symptomContentsRef = doc(db, 'symptomContents', id);
    delete symptomContent.id;
    delete symptomContent.createdAt;

    const prevSymptomContent = yield select(selectSymptomContent(id));
    const newSymptomContent = {
      ...symptomContent,
      updatedAt: serverTimestamp(),
    };

    if (isEqualObjs(prevSymptomContent, newSymptomContent))
      return yield put(
        symptomContentsActions.updateSymptomContentSuccess(symptomContent),
      );

    yield call(
      rsf.firestore.setDocument,
      symptomContentsRef,
      newSymptomContent,
      { merge: true },
    );

    const symptom = yield select(selectSymptom(symptomContent.symptomId));
    if (prevSymptomContent.published !== newSymptomContent.published) {
      const langs = symptomContent.published
        ? [...new Set([...symptom.langs, symptomContent.lang])]
        : symptom.langs.filter((lang) => lang !== symptomContent.lang);
      yield put(
        symptomsActions.updateSymptom({
          id: symptom.id,
          langs,
        }),
      );
    }

    yield put(
      symptomContentsActions.updateSymptomContentSuccess(symptomContent),
    );
    toastr.success('SymptomContent updated!', '');
  } catch (error) {
    yield put(symptomContentsActions.updateSymptomContentFailure(error));
  }
}

const symptomContentTransformer = (snap) => {
  const data = snap.data();
  return {
    id: snap.id,
    ...data,
    ...(data.createdAt && {
      createdAt: toDateFirebase(snap, data).toDate(),
    }),
    ...(data.updatedAt && {
      updatedAt: toDateFirebase(snap, data, 'updatedAt').toDate(),
    }),
  };
};

function* syncSymptomContentsSaga() {
  const symptomContentsRef = query(
    collection(db, 'symptomContents'),
    orderBy('createdAt', 'desc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, symptomContentsRef, {
    successActionCreator: symptomContentsActions.syncSymptomContentsSuccess,
    failureActionCreator: symptomContentsActions.syncSymptomContentsFailure,
    transform: (payload) =>
      payload.docs.map((snap) => symptomContentTransformer(snap)),
  });

  yield take(types.RESET_SYMPTOM_CONTENT_STATE);
  yield cancel(task);
}

function* fetchSymptomContentSaga({ symptomContentId }) {
  try {
    const symptomContentRef = doc(db, 'symptomContents', symptomContentId);

    const symptomContentSnap = yield call(
      rsf.firestore.getDocument,
      symptomContentRef,
    );

    const symptomContent = symptomContentSnap.exists()
      ? symptomContentTransformer(symptomContentSnap)
      : null;

    yield put(
      symptomContentsActions.fetchSymptomContentSuccess(symptomContent),
    );
  } catch (error) {
    yield put(symptomContentsActions.fetchSymptomContentFailure(error));
  }
}

function* symptomContentSaga() {
  yield all([
    takeLatest(types.SYNC_SYMPTOM_CONTENTS.REQUEST, syncSymptomContentsSaga),
    takeLeading(types.CREATE_SYMPTOM_CONTENT.REQUEST, createSymptomContentSaga),
    takeLeading(types.UPDATE_SYMPTOM_CONTENT.REQUEST, updateSymptomContentSaga),
    takeLeading(types.FETCH_SYMPTOM_CONTENT.REQUEST, fetchSymptomContentSaga),
  ]);
}

export default symptomContentSaga;
