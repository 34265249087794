import { createSelector } from 'reselect';
import { generateDeeplink } from '../config/pushNotifications';

export const selectPushNotification = (id) =>
  createSelector(
    (state) => state.PushNotification.pushNotifications,
    (pushNotifications) => {
      if (!id) return null;
      return pushNotifications.find(
        (pushNotification) => pushNotification.id === id,
      );
    },
  );

export const selectPushNotificationContent = (contentId, contentType) =>
  createSelector(
    (state) => state.ArticleContent.articleContents,
    (state) => state.VideoContent.videoContents,
    (state) => state.SymptomContent.symptomContents,
    (articles, videos, symptoms) => {
      if (!contentId) return null;

      let content = null;
      switch (contentType) {
        case 'article':
          content = articles.find(({ id }) => id === contentId);
          break;
        case 'video':
          content = videos.find(({ id }) => id === contentId);
          break;
        case 'symptom':
          content = symptoms.find(({ id }) => id === contentId);
          break;
        default:
          break;
      }
      if (!content) return null;

      return {
        message: content.title,
        deeplink: generateDeeplink(contentId, contentType),
        image: content.coverImage,
      };
    },
  );
