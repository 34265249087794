import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as contestsActions from './actions';

import {
  getFirestore,
  query,
  collection,
  doc,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import { uploadFileToS3 } from '../../helpers/file';

import toastr from 'toastr';
import { toDateFirebase } from '../../helpers/sharedFunction';
import moment from 'moment';

const STORAGE_BASE_PATH = 'contests';
const db = getFirestore(rsf.app);

function* createContestSaga({ contest }) {
  try {
    const contestRef = collection(db, 'contests');
    const id = contestRef.id;

    let imageURL;
    if (contest?.files?.image) {
      const { image } = contest.files;
      [imageURL] = yield all(
        Object.values({ image }).map((file) => {
          const path = `${STORAGE_BASE_PATH}/${id}/${file.path}`;
          return call(uploadFileToS3, file, path);
        }),
      );
    }

    const { startDate, endDate } = contest;
    delete contest.files;

    yield call(rsf.firestore.addDocument, contestRef, {
      ...contest,
      ...(imageURL && {
        image: imageURL,
      }),
      startDate: moment(startDate).startOf('day').toDate(),
      endDate: moment(endDate).endOf('day').toDate(),
      createdAt: serverTimestamp(),
    });
    yield put(contestsActions.createContestSuccess(contest));
    toastr.success('Contest created!', '');
  } catch (error) {
    yield put(contestsActions.createContestFailure(error));
  }
}

function* updateContestSaga({ contest }) {
  try {
    const { id, files, startDate, endDate } = contest;
    const contestRef = doc(db, 'contests', id);

    let imageURL;
    if (files?.image) {
      const path = `${STORAGE_BASE_PATH}/${id}/${files.image.path}`;
      imageURL = yield call(uploadFileToS3, files.image, path);
    }

    delete contest.id;
    delete contest.files;
    delete contest.createdAt;

    yield call(
      rsf.firestore.setDocument,
      contestRef,
      {
        ...contest,
        ...(imageURL && {
          image: imageURL,
        }),
        startDate: moment(startDate).startOf('day').toDate(),
        endDate: moment(endDate).endOf('day').toDate(),
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(contestsActions.updateContestSuccess(contest));
    toastr.success('Contest updated!', '');
  } catch (error) {
    yield put(contestsActions.updateContestFailure(error));
  }
}

const contestTransformer = (payload) => {
  let contests = [];

  payload.forEach((contest) => {
    const data = contest.data();
    contests.push({
      id: contest.id,
      ...data,
      ...(data.startDate && {
        startDate: toDateFirebase(contest, data, 'startDate').toDate(),
      }),
      ...(data.endDate && {
        endDate: toDateFirebase(contest, data, 'endDate').toDate(),
      }),
      ...(data.createdAt && {
        createdAt: toDateFirebase(contest, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(contest, data, 'updatedAt').toDate(),
      }),
    });
  });

  return contests.sort((a, b) => a.priority - b.priority);
};

function* syncContestsSaga() {
  const contestsRef = query(
    collection(db, 'contests'),
    orderBy('createdAt', 'desc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, contestsRef, {
    successActionCreator: contestsActions.syncContestsSuccess,
    failureActionCreator: contestsActions.syncContestsFailure,
    transform: (payload) => contestTransformer(payload),
  });

  yield take(types.RESET_CONTEST_STATE);
  yield cancel(task);
}

function* contestSaga() {
  yield all([
    takeLatest(types.SYNC_CONTESTS.REQUEST, syncContestsSaga),
    takeLeading(types.CREATE_CONTEST.REQUEST, createContestSaga),
    takeLeading(types.UPDATE_CONTEST.REQUEST, updateContestSaga),
  ]);
}

export default contestSaga;
