import React, { useEffect } from 'react';
import { Row, Col, Alert, Card, CardBody, Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Form, Field } from '@availity/form';
import * as yup from 'yup';
import '@availity/yup';
import * as authActions from '../../store/auth/actions';
import DisclaimerFooter from '../../components/Common/DisclaimerFooter';
import logotype from '../../assets/images/media-data-factory.png';
import logo from '../../assets/images/logo.png';

const ForgetPassword = (props) => {
  const error = useSelector((state) => state.Auth.error);
  const success = useSelector((state) => state.Auth.success);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.resetMessages());
  }, []);

  const handleValidSubmit = (event, values) => {
    const { email } = values;

    dispatch(authActions.passwordForget(email));
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back!</h5>
                        <p>Recover your MammaCheApp CMS password.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={logotype} alt="" className="img-fluid p-2" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="75"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {error !== '' && (
                      <Alert color="danger" style={{ marginTop: '13px' }}>
                        {error}
                      </Alert>
                    )}
                    {success !== '' && (
                      <Alert color="success" style={{ marginTop: '13px' }}>
                        {success}
                      </Alert>
                    )}
                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={(e, v) => handleValidSubmit(e, v)}
                      validationSchema={yup.object().shape({
                        email: yup.string().email().required(),
                      })}>
                      <div className="form-group">
                        <Field
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit">
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{' '}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{' '}
                </p>
                <DisclaimerFooter />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ForgetPassword);
