import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_PUSH_NOTIFICATION_STATE,
});

export const createPushNotification = (pushNotification) => ({
  type: types.CREATE_PUSH_NOTIFICATION.REQUEST,
  pushNotification,
});

export const createPushNotificationSuccess = (pushNotification) => ({
  type: types.CREATE_PUSH_NOTIFICATION.SUCCESS,
  pushNotification,
});

export const createPushNotificationFailure = (error) => ({
  type: types.CREATE_PUSH_NOTIFICATION.FAILURE,
  error,
});

export const updatePushNotification = (pushNotification) => ({
  type: types.UPDATE_PUSH_NOTIFICATION.REQUEST,
  pushNotification,
});

export const updatePushNotificationSuccess = (pushNotification) => ({
  type: types.UPDATE_PUSH_NOTIFICATION.SUCCESS,
  pushNotification,
});

export const updatePushNotificationFailure = (error) => ({
  type: types.UPDATE_PUSH_NOTIFICATION.FAILURE,
  error,
});

export const fetchPushNotifications = (startDate, endDate, filters = null) => ({
  type: types.FETCH_PUSH_NOTIFICATIONS.REQUEST,
  startDate,
  endDate,
  filters,
});

export const fetchPushNotificationsSuccess = (
  pushNotifications,
  startDate,
  endDate,
  filters,
) => ({
  type: types.FETCH_PUSH_NOTIFICATIONS.SUCCESS,
  pushNotifications,
  startDate,
  endDate,
  filters,
});

export const fetchPushNotificationsFailure = (error) => ({
  type: types.FETCH_PUSH_NOTIFICATIONS.FAILURE,
  error,
});
