import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as membersActions from '../../store/members/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  countMember,
  countMemberWithPushToken,
  countMemberSeeking,
  countMemberPregnant,
  countMemberWithChildren,
  countMemberPregnantWithChildren,
  countMemberPregnantByQuarter,
  countMemberWithChildrenByAge,
} from '../../selectors/member';
import FormMember from '../../components/Members/FormMember';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import MiniCard from '../../components/Common/MiniCard';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Members/tableColumns';
import {
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import MembersGraph from '../../components/Members/MembersGraph';
import { addOrRemove } from '../../helpers/sharedFunction';
import PregnantPie from '../../components/Members/PregnantPie';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const List = (props) => {
  const loading = useSelector((state) => state.Member.loading);
  const members = useSelector((state) => state.Member.members);

  const totalMember = useSelector(countMember);

  const membersWithPushToken = useSelector(countMemberWithPushToken);
  const percentageMemberWithPushToken =
    (membersWithPushToken / totalMember) * 100;
  const membersSeeking = useSelector(countMemberSeeking);
  const percentageMemberSeeking = (membersSeeking / totalMember) * 100;
  const membersPregnant = useSelector(countMemberPregnant);
  const percentageMemberPregnant = (membersPregnant / totalMember) * 100;
  const membersWithChildren = useSelector(countMemberWithChildren);
  const percentageMemberWithChildren =
    (membersWithChildren / totalMember) * 100;
  const membersPregnantWithChildren = useSelector(
    countMemberPregnantWithChildren,
  );
  const percentageMemberPregnantWithChildren =
    (membersPregnantWithChildren / totalMember) * 100;

  const { firstQuarters, secondQuarters, thirdQuarters } = useSelector(
    countMemberPregnantByQuarter,
  );
  const { twelveMonths, firstYear, secondYear, thirdYear } = useSelector(
    countMemberWithChildrenByAge,
  );

  const [selectedMember, setSelectedMember] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const startDate = useSelector((state) => state.Member.startDate);
  const endDate = useSelector((state) => state.Member.endDate);
  const dataFilters = useSelector((state) => state.Member.filters);
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('month'),
    endDate: momentEndDate || moment.utc(),
    lang: dataFilters?.lang || '',
  });

  const dispatch = useDispatch();

  const refresh = useCallback(({ startDate, endDate, lang }) => {
    const selectedStartDate = startDate.utc().startOf('day').toDate();
    const selectedEndDate = endDate.utc().endOf('day').toDate();
    dispatch(
      membersActions.fetchMembers(selectedStartDate, selectedEndDate, {
        lang,
      }),
    );
    setSelectedFilters({ startDate, endDate, lang });
  });

  useEffect(() => {
    members.length === 0 && refresh(selectedFilters);
  }, []);

  const openViewModal = (member) => {
    setSelectedMember(member);
    setViewModal(true);
  };

  const openEditModal = (member = null) => {
    setSelectedMember(member);
    setEditModal(true);
  };

  const { t } = useTranslation();

  const metrics = [
    {
      title: t('Member'),
      text: totalMember,
      color: 'dark',
      icon: 'bx bx-street-view',
    },
    {
      title: t('Member With Push Token'),
      text: membersWithPushToken,
      color: 'primary',
      icon: 'bx bx-notification',
      percentage: `${
        isFinite(percentageMemberWithPushToken)
          ? percentageMemberWithPushToken.toFixed(0)
          : 0
      }%`,
    },
    {
      title: t('Member Seeking'),
      text: membersSeeking,
      color: 'warning',
      icon: 'bx bx-search-alt',
      percentage: `${
        isFinite(percentageMemberSeeking)
          ? percentageMemberSeeking.toFixed(0)
          : 0
      }%`,
    },
    {
      title: t('Member Pregnant'),
      text: membersPregnant,
      color: 'info',
      icon: 'mdi mdi-account-child',
      percentage: `${
        isFinite(percentageMemberPregnant)
          ? percentageMemberPregnant.toFixed(0)
          : 0
      }%`,
    },
    {
      title: t('Member With Children'),
      text: membersWithChildren,
      color: 'success',
      icon: 'mdi mdi-human-male-child',
      percentage: `${
        isFinite(percentageMemberWithChildren)
          ? percentageMemberWithChildren.toFixed(0)
          : 0
      }%`,
    },
    {
      title: t('Member Pregnant With Children'),
      text: membersPregnantWithChildren,
      color: 'danger',
      icon: 'mdi mdi-ninja',
      percentage: `${
        isFinite(percentageMemberPregnantWithChildren)
          ? percentageMemberPregnantWithChildren.toFixed(0)
          : 0
      }%`,
    },
  ];

  const columns = useMemo(
    () => createTableColumns(openViewModal, openEditModal),
    [],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Members"
            breadcrumbItem="List"
            onRefresh={() => refresh(selectedFilters)}
            loading={loading}
          />
          <DateRangeFilter
            onUpdate={refresh}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            minDate={moment.utc('15/04/2024', 'DD/MM/YYYY')}
            loading={loading}
            showFilters
            initialFilters={selectedFilters}
          />
          <Row>
            {metrics.slice(0, 3).map((metric, index) => (
              <Col key={'metric_' + index}>
                <MiniCard {...metric} loading={loading} />
              </Col>
            ))}
          </Row>
          <Row>
            {metrics.slice(3, 6).map((metric, index) => (
              <Col key={'metric_' + index}>
                <MiniCard {...metric} loading={loading} />
              </Col>
            ))}
          </Row>
          <Row>
            <Col md="5">
              <PregnantPie
                title="Pregnant members by quarter"
                series={[firstQuarters, secondQuarters, thirdQuarters]}
                labels={['First quarter', 'Second quarter', 'Third quarter']}
              />
              <PregnantPie
                title="Members with children by age"
                series={[twelveMonths, firstYear, secondYear, thirdYear]}
                labels={[
                  '0 - 12 months',
                  'First year',
                  'Second year',
                  'Third year',
                ]}
              />
            </Col>
            <Col md="7">
              <MembersGraph dateRange={selectedFilters} />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={members}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Member"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement element={selectedMember} icon="bx bx-user-pin" />
          </Modal>
          <Modal
            size="xl"
            title="Member"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormMember
              closeModal={() => setEditModal(false)}
              create={!selectedMember}
              loading={loading}
              member={selectedMember}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
