import React from 'react';
import { ViewButton, EditButton } from '../Common/DataTable/tableActionButtons';
import {
  getLabel,
  getLabels,
  customTextFilter,
  generateFlags,
} from '../Common/DataTable/tableHelpers';
import {
  GENDERS,
  SYMPTOM_TYPES,
  TIME_PERIODS,
  BODY_PARTS,
} from '../../config/symptoms';

const createTableColumns = (openViewModal, openEditModal, openLangModal) => {
  return [
    {
      title: 'Name',
      name: 'name',
      wordWrapEnabled: true,
    },
    {
      title: 'Gender',
      name: 'gender',
      width: 180,
      formatterComponent: ({ row, value }) =>
        getLabel(value, GENDERS, 'value', 'label'),
    },
    {
      title: 'Type',
      name: 'symptomType',
      width: 180,
      formatterComponent: ({ row, value }) =>
        getLabel(value, SYMPTOM_TYPES, 'value', 'label'),
    },
    {
      title: 'Periods',
      name: 'periods',
      width: 180,
      formatterComponent: ({ row, value }) =>
        getLabels(value, TIME_PERIODS, 'value', 'label'),
      wordWrapEnabled: true,
    },
    {
      title: 'Body Parts',
      name: 'bodyParts',
      width: 180,
      formatterComponent: ({ row, value }) =>
        getLabels(value, BODY_PARTS, 'value', 'label'),
      wordWrapEnabled: true,
    },
    {
      title: 'Languages',
      name: 'langs',
      width: 140,
      align: 'center',
      wordWrapEnabled: true,
      formatterComponent: ({ row }) => generateFlags(row, openLangModal),
      filter: 'disabled',
      sorting: 'disabled',
    },
    {
      title: 'Action',
      name: 'action',
      width: 120,
      align: 'center',
      formatterComponent: ({ row }) => (
        <>
          <ul className="list-inline font-size-20 contact-links mb-0">
            <ViewButton row={row} onPress={openViewModal} />
            <EditButton row={row} onPress={openEditModal} />
          </ul>
        </>
      ),
      filter: 'disabled',
      sorting: 'disabled',
    },
  ];
};

export default createTableColumns;
