import React from 'react';
import { ViewButton, EditButton } from '../Common/DataTable/tableActionButtons';
import { getFilter } from '../Common/DataTable/tableHelpers';

const createTableColumns = (openViewModal, openEditModal) => {
  return [
    {
      title: 'Name',
      name: 'name',
      sort: true,
    },
    {
      title: 'Language',
      name: 'lang',
      width: 140,
      sort: true,
    },
    {
      title: 'Privacy',
      name: 'privacies',
      formatterComponent: ({ value }) =>
        Array.isArray(value) ? value.length : 0,
      filter: 'disabled',
    },
    {
      title: 'Walkthrough',
      name: 'walkthroughs',
      formatterComponent: ({ value }) =>
        Array.isArray(value) ? value.length : 0,
      filter: 'disabled',
    },
    {
      title: 'Active',
      name: 'active',
      width: 100,
      sort: true,
      formatterComponent: ({ value }) =>
        value ? (
          <span className="badge badge-success font-size-12">YES</span>
        ) : (
          <span className="badge badge-danger font-size-12">NO</span>
        ),
      filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
    },
    {
      title: 'Action',
      name: 'action',
      width: 120,
      align: 'center',
      formatterComponent: ({ row }) => (
        <ul className="list-inline font-size-20 contact-links mb-0">
          <ViewButton row={row} onPress={openViewModal} />
          <EditButton row={row} onPress={openEditModal} />
        </ul>
      ),
      filter: 'disabled',
      sorting: 'disabled',
    },
  ];
};

export default createTableColumns;
