const types = {
  RESET_USER_STATE: 'RESET_USER_STATE',
  CREATE_USER: {
    REQUEST: 'CREATE_USER.REQUEST',
    SUCCESS: 'CREATE_USER.SUCCESS',
    FAILURE: 'CREATE_USER.FAILURE',
  },
  UPDATE_USER: {
    REQUEST: 'UPDATE_USER.REQUEST',
    SUCCESS: 'UPDATE_USER.SUCCESS',
    FAILURE: 'UPDATE_USER.FAILURE',
  },
  SYNC_USERS: {
    REQUEST: 'SYNC_USERS.REQUEST',
    SUCCESS: 'SYNC_USERS.SUCCESS',
    FAILURE: 'SYNC_USERS.FAILURE',
  },
  FETCH_USER: {
    REQUEST: 'FETCH_USER.REQUEST',
    SUCCESS: 'FETCH_USER.SUCCESS',
    FAILURE: 'FETCH_USER.FAILURE',
  },
};

export default types;
