import { call, fork, put, take, takeEvery, all } from 'redux-saga/effects';
import { GoogleAuthProvider, getAdditionalUserInfo } from 'firebase/auth';
import { serverTimestamp } from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import types from './actionTypes';
import * as authActions from './actions';

import * as usersActions from '../users/actions';
import * as dashboardActions from '../dashboard/actions';
import * as articlesActions from '../articles/actions';
import * as articleContentsActions from '../articles/contents/actions';
import * as videosActions from '../videos/actions';
import * as videoContentsActions from '../videos/contents/actions';
import * as symptomsActions from '../symptoms/actions';
import * as contestsActions from '../contests/actions';
import * as pushNotificationsActions from '../pushNotifications/actions';
import * as membersActions from '../members/actions';
import * as countriesActions from '../countries/actions';

import { toDateFirebase } from '../../helpers/sharedFunction';
import roles from '../../config/roles';

const authGoogleProvider = new GoogleAuthProvider();
authGoogleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
authGoogleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');

function* loginWithGoogleSaga({ history }) {
  try {
    //const { user, additionalUserInfo } = yield call(
    const result = yield call(rsf.auth.signInWithPopup, authGoogleProvider);
    const { isNewUser } = getAdditionalUserInfo(result);
    isNewUser
      ? yield call(createAdminSaga, result.user, 'google')
      : yield call(fetchAdminSaga, result.user.uid, history);
  } catch (error) {
    yield put(authActions.loginWithGoogleFailure(error));
  }
}

function* loginWithEmailSaga({ email, password, history }) {
  try {
    const { user } = yield call(
      rsf.auth.signInWithEmailAndPassword,
      email,
      password,
    );
    yield call(fetchAdminSaga, user.uid, history);
  } catch (error) {
    yield put(authActions.loginWithEmailFailure(error));
  }
}

function* registerWithEmailSaga({ email, password, displayName }) {
  try {
    const { user } = yield call(
      rsf.auth.createUserWithEmailAndPassword,
      email,
      password,
    );
    yield call(createAdminSaga, { ...user, displayName }, 'email');
  } catch (error) {
    yield put(authActions.registerWithEmailFailure(error));
  }
}

function* logoutSaga({ history }) {
  try {
    yield put(usersActions.resetState());
    yield put(dashboardActions.resetState());
    yield put(articlesActions.resetState());
    yield put(articleContentsActions.resetState());
    yield put(videosActions.resetState());
    yield put(videoContentsActions.resetState());
    yield put(symptomsActions.resetState());
    yield put(contestsActions.resetState());
    yield put(pushNotificationsActions.resetState());
    yield put(membersActions.resetState());
    yield put(countriesActions.resetState());

    yield call(rsf.auth.signOut);
    yield put(authActions.logoutSuccess());
    history.push('/login');
  } catch (error) {
    yield put(authActions.logoutFailure(error));
  }
}

function* syncAdminSaga() {
  const channel = yield call(rsf.auth.channel);

  while (true) {
    const { error, user } = yield take(channel);

    if (user) {
      yield put(authActions.syncAdmin(user));
    } else {
      yield put(authActions.syncAdmin(null));
    }
  }
}

function* fetchAdminSaga(userId, history) {
  try {
    const adminSnap = yield call(rsf.firestore.getDocument, `users/${userId}`);
    const data = adminSnap.data();

    const admin = {
      id: userId,
      ...data,
      createdAt: toDateFirebase(adminSnap, data).toDate(),
      ...(data.updateAt && {
        updateAt: toDateFirebase(adminSnap, data, 'updatedAt').toDate(),
      }),
    };
    if (admin.role && admin.active) {
      yield put(authActions.fetchAdminSuccess(admin, ''));
      history.push('/dashboard');
    } else {
      yield put(
        authActions.fetchAdminSuccess(
          null,
          'Contact MammaCheApp staff to request access',
        ),
      );
    }
  } catch (error) {
    yield put(authActions.fetchAdminFailure(error));
  }
}

function* createAdminSaga(userData, provider) {
  try {
    const admin = {
      email: userData.email,
      displayName: userData.displayName,
      provider,
      active: false,
      role: roles.EDITOR,
      ...(userData.photoURL && { photoURL: userData.photoURL }),
      createdAt: serverTimestamp(),
    };
    yield call(rsf.firestore.setDocument, `users/${userData.uid}`, admin);
    yield put(
      authActions.createAdminSuccess(
        'Contact MammaCheApp staff to request access',
      ),
    );
  } catch (error) {
    yield put(authActions.createAdminFailure(error));
  }
}

function* passwordChangeSaga({ password }) {
  try {
    yield call(rsf.auth.updatePassword, password);
    yield put(authActions.passwordChangeSuccess());
  } catch (error) {
    yield put(authActions.passwordChangeFailure(error));
  }
}

function* passwordForgetSaga({ email }) {
  try {
    yield call(rsf.auth.sendPasswordResetEmail, email);
    yield put(
      authActions.passwordForgetSuccess(
        'Reset link are sended to your mailbox, check there first',
      ),
    );
  } catch (error) {
    yield put(authActions.passwordForgetFailure(error));
  }
}

export default function* authSaga() {
  yield fork(syncAdminSaga);
  yield all([
    takeEvery(types.LOGIN_WITH_GOOGLE.REQUEST, loginWithGoogleSaga),
    takeEvery(types.LOGIN_WITH_EMAIL.REQUEST, loginWithEmailSaga),
    takeEvery(types.REGISTER_WITH_EMAIL.REQUEST, registerWithEmailSaga),
    takeEvery(types.REGISTER_WITH_EMAIL.SUCCESS, createAdminSaga),
    takeEvery(types.FETCH_ADMIN.REQUEST, fetchAdminSaga),
    takeEvery(types.LOGOUT.REQUEST, logoutSaga),
    takeEvery(types.PASSWORD_CHANGE.REQUEST, passwordChangeSaga),
    takeEvery(types.PASSWORD_FORGET.REQUEST, passwordForgetSaga),
  ]);
}
