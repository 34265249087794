import { initializeApp } from 'firebase/app';

// Add the Firebase products that you want to use
// import 'firebase/auth';
// import 'firebase/firestore';
// import 'firebase/functions';

// New modular firebase API: https://modularfirebase.web.app/common-use-cases/firestore/

import ReduxSagaFirebase from '../lib/redux-saga-firebase';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
};

const firebaseApp = initializeApp(firebaseConfig);

const rsf = new ReduxSagaFirebase(firebaseApp);

export default rsf;
