import types from './actionTypes';

const initialState = {
  loading: false,
  contests: [],
  error: '',
};

const contestReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_CONTEST_STATE:
      return initialState;
    case types.CREATE_CONTEST.REQUEST:
    case types.UPDATE_CONTEST.REQUEST:
    case types.SYNC_CONTESTS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_CONTEST.SUCCESS:
    case types.UPDATE_CONTEST.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SYNC_CONTESTS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        contests: [...action.contests],
      };
    case types.CREATE_CONTEST.FAILURE:
    case types.UPDATE_CONTEST.FAILURE:
    case types.SYNC_CONTESTS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default contestReducer;
