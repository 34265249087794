import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_VIDEO_CONTENT_STATE,
});

export const createVideoContent = (videoContent, video) => ({
  type: types.CREATE_VIDEO_CONTENT.REQUEST,
  videoContent,
  video,
});

export const createVideoContentSuccess = () => ({
  type: types.CREATE_VIDEO_CONTENT.SUCCESS,
});

export const createVideoContentFailure = (error) => ({
  type: types.CREATE_VIDEO_CONTENT.FAILURE,
  error,
});

export const updateVideoContent = () => ({
  type: types.UPDATE_VIDEO_CONTENT.REQUEST,
});

export const updateVideoContentSuccess = () => ({
  type: types.UPDATE_VIDEO_CONTENT.SUCCESS,
});

export const updateVideoContentFailure = (error) => ({
  type: types.UPDATE_VIDEO_CONTENT.FAILURE,
  error,
});

export const syncVideoContents = () => ({
  type: types.SYNC_VIDEO_CONTENTS.REQUEST,
});

export const syncVideoContentsSuccess = (videoContents) => ({
  type: types.SYNC_VIDEO_CONTENTS.SUCCESS,
  videoContents,
});

export const syncVideoContentsFailure = (error) => ({
  type: types.SYNC_VIDEO_CONTENTS.FAILURE,
  error,
});

export const fetchVideoContent = (videoContentId) => ({
  type: types.FETCH_VIDEO_CONTENT.REQUEST,
  videoContentId,
});

export const fetchVideoContentSuccess = (videoContent) => ({
  type: types.FETCH_VIDEO_CONTENT.SUCCESS,
  videoContent,
});

export const fetchVideoContentFailure = (error) => ({
  type: types.FETCH_VIDEO_CONTENT.FAILURE,
  error,
});
