import React from 'react';
import { ViewButton, EditButton } from '../Common/DataTable/tableActionButtons';
import moment from 'moment';

const createTableColumns = (openViewModal, openEditModal, user) => {
  return [
    {
      title: 'Display Name',
      name: 'displayName',
    },
    {
      title: 'Role',
      name: 'role',
      width: 180,
    },
    {
      title: 'Active',
      name: 'active',
      width: 120,
      align: 'center',
      formatterComponent: ({ row, value }) =>
        value ? (
          <span className="badge badge-success font-size-12">YES</span>
        ) : (
          <span className="badge badge-danger font-size-12">NO</span>
        ),
    },
    {
      title: 'Created At',
      name: 'createdAt',
      width: 180,
      formatterComponent: ({ row, value }) => `${moment(value).fromNow()}`,
      sort: true,
    },
    {
      title: 'Action',
      name: 'action',
      width: 120,
      align: 'center',
      formatterComponent: ({ row }) => (
        <ul className="list-inline font-size-20 contact-links mb-0">
          <ViewButton row={row} onPress={openViewModal} />
          {(user.role !== row.role || user.id === row.id) && (
            <EditButton row={row} onPress={openEditModal} />
          )}
        </ul>
      ),
      filter: 'disabled',
      sorting: 'disabled',
    },
  ];
};

export default createTableColumns;
