import '@availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  lang: yup.string().required(),
  title: yup.string().required().max(30),
  message: yup.string().required().max(140),
  deeplink: yup.string().required(),
  sendImmediately: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  sendDate: yup.date().when('sendImmediately', {
    is: false,
    then: () => yup.date().required(),
  }),
});

export default formValidation;
