import React, { useState } from 'react';
import Select from 'react-select';
import { cdnUrl } from '../../helpers/file';
import logo from '../../assets/images/logo.png';
import './PushNotification.scss';

const DEVICES = [
  { value: 'ios', label: 'iOS' },
  { value: 'android', label: 'Android' },
];

const PushNotificationPreview = ({ title = '', message = '', image }) => {
  const [device, setDevice] = useState(DEVICES[0]);

  title = title.substring(0, 30);
  message = message.substring(0, 140);

  return (
    <div className="notification-preview">
      <div className={`phone-frame ${device.value}`}>
        <div className="notification-container">
          <div className="notification">
            <div className="notification-wrapper">
              <div className="notification-icon">
                <img src={logo} />
              </div>
              <div className="notification-content">
                <h5>{title || 'Title'}</h5>
                <p>{message || 'Message'}</p>
              </div>
              <div className="notification-time">
                <p>now</p>
              </div>
            </div>
            {image && <img src={cdnUrl(image)} className={device.value} />}
          </div>
        </div>
      </div>
      <div className="device-select">
        <Select options={DEVICES} defaultValue={device} onChange={setDevice} />
      </div>
    </div>
  );
};

export default PushNotificationPreview;
