import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as articlesActions from './actions';
import { selectArticle } from '../../selectors/article';

import {
  getFirestore,
  query,
  collection,
  doc,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import { uploadFileToS3 } from '../../helpers/file';

import toastr from 'toastr';
import { toDateFirebase, isEqualObjs } from '../../helpers/sharedFunction';

const STORAGE_BASE_PATH = 'articles';
const db = getFirestore(rsf.app);

function* createArticleSaga({ article }) {
  try {
    const articlesRef = doc(collection(db, 'articles'));
    const id = articlesRef.id;

    let coverImageURL;
    if (article.files) {
      const { coverImage } = article.files;
      [coverImageURL] = yield all(
        Object.values({ coverImage }).map((file) => {
          const path = `${STORAGE_BASE_PATH}/${id}/coverImages/${file.path}`;
          return call(uploadFileToS3, file, path);
        }),
      );
    }

    delete article.files;

    yield call(rsf.firestore.setDocument, articlesRef, {
      ...article,
      langs: [],
      ...(coverImageURL && { coverImage: coverImageURL }),
      createdAt: serverTimestamp(),
    });
    yield put(articlesActions.createArticleSuccess(article));
    toastr.success('Article created!', '');
  } catch (error) {
    yield put(articlesActions.createArticleFailure(error));
  }
}

function* updateArticleSaga({ article }) {
  try {
    const { id, files } = article;
    const articlesRef = doc(db, 'articles', id);

    let coverImageURL;
    if (files?.coverImage) {
      const path = `${STORAGE_BASE_PATH}/${id}/coverImages/${files.coverImage.path}`;
      coverImageURL = yield call(uploadFileToS3, files.coverImage, path);
    }

    delete article.id;
    delete article.files;
    delete article.createdAt;

    const prevArticle = yield select(selectArticle(id));
    const newArticle = {
      ...article,
      ...(coverImageURL && { coverImage: coverImageURL }),
      updatedAt: serverTimestamp(),
    };

    if (isEqualObjs(prevArticle, newArticle))
      return yield put(articlesActions.updateArticleSuccess(article));

    yield call(rsf.firestore.setDocument, articlesRef, newArticle, {
      merge: true,
    });
    yield put(articlesActions.updateArticleSuccess(article));
    toastr.success('Article updated!', '');
  } catch (error) {
    yield put(articlesActions.updateArticleFailure(error));
  }
}

const articleTransformer = (payload) => {
  let articles = [];

  payload.forEach((article) => {
    const data = article.data();
    articles.push({
      id: article.id,
      ...data,
      ...(data.createdAt && {
        createdAt: toDateFirebase(article, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(article, data, 'updatedAt').toDate(),
      }),
    });
  });

  return articles;
};

function* syncArticlesSaga() {
  const articlesRef = query(
    collection(db, 'articles'),
    orderBy('createdAt', 'desc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, articlesRef, {
    successActionCreator: articlesActions.syncArticlesSuccess,
    failureActionCreator: articlesActions.syncArticlesFailure,
    transform: (payload) => articleTransformer(payload),
  });

  yield take(types.RESET_ARTICLE_STATE);
  yield cancel(task);
}

function* articleSaga() {
  yield all([
    takeLatest(types.SYNC_ARTICLES.REQUEST, syncArticlesSaga),
    takeLeading(types.CREATE_ARTICLE.REQUEST, createArticleSaga),
    takeLeading(types.UPDATE_ARTICLE.REQUEST, updateArticleSaga),
  ]);
}

export default articleSaga;
