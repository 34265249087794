import { createSelector } from 'reselect';
import roles from '../config/roles';

export const hasRole = (roles) =>
  createSelector(
    (state) => state.Auth.admin,
    (admin) => roles.includes(admin.role),
  );

export const isAdmin = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.ADMIN || admin.role === roles.SUPER_ADMIN,
);

export const isSuperAdmin = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.SUPER_ADMIN,
);

export const isManager = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.MANAGER,
);

export const isEditor = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.EDITOR,
);
