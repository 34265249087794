export default [
  {
    value: 'video-gravidanza',
    label: 'Gravidanza',
    children: [
      {
        value: 'video-gravidanza-40-settimane',
        label: '40 settimane',
      },
      {
        value: 'video-gravidanza-fitness',
        label: 'Fitness in gravidanza',
        children: [
          {
            value: 'video-gravidanza-fitness-respirazione',
            label: 'Respirazione',
          },
          {
            value: 'video-gravidanza-fitness-stretching',
            label: 'Stretching',
          },
          {
            value: 'video-gravidanza-fitness-mobilita',
            label: 'Mobilità articolare',
          },
          {
            value: 'video-gravidanza-fitness-total-body',
            label: 'Total body',
          },
          {
            value: 'video-gravidanza-fitness-arti-inferiori',
            label: 'Glutei e arti inferiori',
          },
          {
            value: 'video-gravidanza-fitness-arti-superiori',
            label: 'Arti superiori',
          },
        ],
      },
    ],
  },
  {
    value: 'video-bambino',
    label: 'Bambino',
    children: [
      {
        value: 'video-bambino-primo-anno',
        label: 'Primo anno',
      },
      {
        value: 'video-bambino-tappe-dello-sviluppo',
        label: 'Tappe dello sviluppo',
      },
      {
        value: 'video-bambino-temi-della-crescita',
        label: 'Temi della crescita',
      },
      {
        value: 'video-bambino-bilanci-di-salute',
        label: 'Bilanci di salute',
      },
      {
        value: 'video-bambino-coccole-sonore',
        label: 'Coccole sonore',
        children: [
          {
            value: 'video-bambino-coccole-sonore-ninnenanne',
            label: 'Le ninnenanne',
          },
          {
            value: 'video-bambino-coccole-sonore-canzoncine',
            label: 'Le canzoncine',
          },
          {
            value: 'video-bambino-coccole-sonore-canzoncine-inglese',
            label: 'Le canzoncine in inglese',
          },
          {
            value: 'video-bambino-coccole-greta',
            label: 'Balliamo con Greta',
          },
        ],
      },
    ],
  },
];
