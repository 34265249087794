import types from './actionTypes';
import initialFilters from '../../config/filters';
import { addOrReplaceObjectInArray } from '../../helpers/reducerHelper';

const initialState = {
  loading: false,
  webContents: [],
  startDate: null,
  endDate: null,
  filters: { ...initialFilters },
  error: '',
};

const webContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_WEB_CONTENT_STATE:
      return initialState;
    case types.CREATE_WEB_CONTENT.REQUEST:
    case types.FETCH_WEB_CONTENTS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_WEB_CONTENT.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        webContents: [...state.webContents, action.webContent],
      };
    case types.UPDATE_WEB_CONTENT.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        webContents: addOrReplaceObjectInArray(
          state.webContents,
          action.webContent,
          'id',
          'merge',
        ),
      };
    case types.FETCH_WEB_CONTENTS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        webContents: action.webContents,
        startDate: action.startDate,
        endDate: action.endDate,
        filters: { ...action.filters },
      };
    case types.CREATE_WEB_CONTENT.FAILURE:
    case types.UPDATE_WEB_CONTENT.FAILURE:
    case types.FETCH_WEB_CONTENTS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default webContentReducer;
