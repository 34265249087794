import { dateRange } from '@availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  lang: yup.string().required(),
  dates: yup.object().shape({
    startDate: yup.date().label('start date').required(),
    endDate: yup.date().label('end date').required(),
  }),
  priority: yup.number().required().positive(),
  link: yup.string().url().required(),
  showEndDate: yup.boolean(),
});

export default formValidation;
