import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { userRoutes } from '../../routes/allRoutes';
import { getAllowedRoutes, isAllowedRoute } from '../../helpers/authHelper';

const SidebarContent = (props) => {
  const admin = useSelector((state) => state.Auth.admin);

  useEffect(() => {
    var pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu('#side-menu');
      var matchingMenuItem = null;
      var ul = document.getElementById('side-menu');
      var items = ul.getElementsByTagName('a');
      for (var i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  }

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{t('Menu')} </li>

          {admin.role &&
            userRoutes.map((route, index) => {
              if (route.component && isAllowedRoute(route, admin.role))
                return (
                  <li key={'route_' + index}>
                    <Link to={route.path} className=" waves-effect">
                      <i className={route.icon}></i>
                      <span>{t(route.title)}</span>
                    </Link>
                  </li>
                );

              if (
                route.children &&
                getAllowedRoutes(route.children, admin).length
              )
                return (
                  <li key={'route_' + index}>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className={route.icon}></i>
                      <span>{t(route.group)}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      {getAllowedRoutes(route.children, admin).map(
                        (route, index) => (
                          <li key={'route_children_' + index}>
                            <Link to={route.path}>{t(route.title)}</Link>
                          </li>
                        ),
                      )}
                    </ul>
                  </li>
                );
            })}

          <li>
            <div style={{ height: '30px' }}></div>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SidebarContent);
