export default [
  {
    value: 'gravidanza',
    label: 'Gravidanza',
    children: [
      {
        value: 'come cresce il tuo bambino-gravidanza',
        label: 'Come cresce il tuo bambino',
      },
      {
        value: 'cosa cambia in te-gravidanza',
        label: 'Cosa cambia in te',
      },
      {
        value: 'alimentazione-gravidanza',
        label: 'Alimentazione',
      },
      {
        value: 'benessere e forma fisica-gravidanza',
        label: 'Benessere e forma fisica',
      },
      {
        value: 'esami-gravidanza',
        label: 'Esami',
      },
      {
        value: 'disturbi e rimedi-gravidanza',
        label: 'Disturbi e rimedi',
      },
      {
        value: 'sessualità-gravidanza',
        label: 'Sessualità',
      },
      {
        value: 'psiche-gravidanza',
        label: 'Psiche',
      },
      {
        value: 'donna-mamma-gravidanza',
        label: 'Donna e mamma',
      },
    ],
  },
  {
    value: 'bambino',
    label: 'Bambino',
    children: [
      {
        value: 'come cresce il tuo bambino-bambino',
        label: 'Come cresce il tuo bambino',
      },
      {
        value: 'alimentazione-bambino',
        label: 'Alimentazione',
      },
      {
        value: 'visite ed esami-bambino',
        label: 'Visite ed esami',
      },
      {
        value: 'piccoli disturbi e malattie-bambino',
        label: 'Piccoli disturbi e malattie',
      },
      {
        value: 'vaccinazioni-bambino',
        label: 'Vaccinazioni',
      },
      {
        value: 'sicurezza e prevenzione degli incidenti-bambino',
        label: 'Sicurezza e prevenzione degli incidenti',
      },
      {
        value: 'come fare per…-bambino',
        label: 'Come fare per…',
      },
      {
        value: 'donna-mamma-bambino',
        label: 'Donna e mamma',
      },
    ],
  },
];
