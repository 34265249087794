import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import ReactCountryFlag from 'react-country-flag';
import allowedLanguages from '../../../config/allowedLanguages';
import { STATUS } from '../../../config/pushNotifications';

export const stringFilter = (value, filter) => {
  const toLowerCase = (value) => String(value).toLowerCase();
  return toLowerCase(value).startsWith(toLowerCase(filter.value));
};

export const getLabels = (cell, collection, key = 'id', label = 'name') =>
  Array.isArray(cell) && cell.length
    ? collection
        .filter((doc) => cell.includes(doc[key]))
        .map((doc) => doc[label])
        .join(', ')
    : '';

export const getLabel = (cell, collection, key = 'id', label = 'name') => {
  const doc = collection.find((doc) => doc[key] === cell);
  return doc ? doc[label] : '';
};

export const getFilter = (text, string) =>
  text.toLowerCase().includes(string.toLowerCase());

export const getSort = (a, b) => {
  if (a === b) return 0;
  return a < b ? -1 : 1;
};

export const hideSensitiveData = (cell, characters) =>
  cell ? cell.replace(cell.substring(characters), '***') : '';

export const userName = (cell, users) => {
  const user = users.filter((user) => user.id === cell);
  return user.length ? user[0].displayName : '';
};
export const generateFlags = (row, openLangModal) => {
  if (!allowedLanguages.length || !Array.isArray(row.langs)) return '';

  return allowedLanguages.map(({ label, value }, id) => (
    <React.Fragment key={id}>
      <ReactCountryFlag
        id={`flag_${value}`}
        className="mr-3"
        style={{
          fontSize: '1.75em',
          filter: `grayscale(${row.langs.includes(value) ? 0 : 1})`,
          cursor: 'pointer',
        }}
        countryCode={value}
        aria-label={label}
        onClick={() => openLangModal(row, { label, value })}
      />
      {false && (
        <UncontrolledTooltip placement="top" target={'flag_' + value}>
          {`${label}: ${row.langs.includes(value) ? '' : 'not'} published`}
        </UncontrolledTooltip>
      )}
    </React.Fragment>
  ));
};

export const pushNotificationStatus = (cell, formatted = false) => {
  let color = '';
  switch (cell) {
    case STATUS.SCHEDULED:
      color = 'info';
      break;
    case STATUS.SENT:
      color = 'success';
      break;
    case STATUS.DELIVERING:
      color = 'secondary';
      break;
    case STATUS.ERROR:
      color = 'danger';
      break;
    default:
      color = 'warning';
  }

  return formatted ? (
    <span className={`badge badge-${color} font-size-12 text-wrap`}>
      {cell.toUpperCase()}
    </span>
  ) : (
    cell
  );
};
