import React from 'react';
import { ViewButton, EditButton } from '../Common/DataTable/tableActionButtons';
import { getFilter, getSort } from '../Common/DataTable/tableHelpers';
import moment from 'moment';

const createTableColumns = (openViewModal, openEditModal) => [
  {
    title: 'First Name',
    name: 'firstName',
    width: 150,
  },
  {
    title: 'Last Name',
    name: 'lastName',
    width: 150,
  },
  {
    title: 'Email',
    name: 'email',
  },
  {
    title: 'Language',
    name: 'lang',
    width: 80,
  },
  {
    title: 'Birthdate',
    name: 'birthdate',
    width: 150,
    formatterComponent: ({ value }) => moment(value).format('DD/MM/YYYY'),
    sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
    filter: (value, filter) =>
      getFilter(moment(value).format('DD/MM/YYYY'), filter.value),
  },
  {
    title: 'Pregnant',
    name: 'isPregnant',
    width: 110,
    formatterComponent: ({ value }) =>
      value ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-warning font-size-12">NO</span>
      ),
    filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
    sorting: (a, b) => getSort(a ? 'yes' : 'no', b ? 'yes' : 'no'),
  },
  {
    title: 'Seeking',
    name: 'isLooking',
    width: 110,
    formatterComponent: ({ value }) =>
      value ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-warning font-size-12">NO</span>
      ),
    filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
    sorting: (a, b) => getSort(a ? 'yes' : 'no', b ? 'yes' : 'no'),
  },
  {
    title: 'Children',
    name: 'children',
    width: 80,
  },
  {
    title: 'User Site Id',
    name: 'userSiteId',
    width: 80,
    hidden: true,
  },
  {
    title: 'User Login Id',
    name: 'userLoginId',
    width: 80,
    hidden: true,
  },
  {
    title: 'OneSignal External ID',
    name: 'oneSignalExternalId',
    width: 80,
    hidden: true,
  },
  {
    title: 'Children Birthdates',
    name: 'childrenBirthdates',
    width: 150,
    formatterComponent: ({ value }) =>
      Array.isArray(value)
        ? value.map((date) => moment(date).format('DD/MM/YYYY'))
        : '',
    filter: 'disabled',
    sorting: 'disabled',
    hidden: true,
  },
  {
    title: 'Action',
    name: 'action',
    width: 120,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        <EditButton row={row} onPress={openEditModal} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
