import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Alert,
  Collapse,
  InputGroup,
} from 'reactstrap';
import { deleteField } from 'firebase/firestore';
import { Form, Input } from '@availity/form';
import { FieldArray } from 'formik';
import FileUpload from '../../Common/FileUpload';
import FormErrorMessage from '../../Common/FormErrorMessage';
import ReactPlayer from 'react-player';
import CenterBox from '../../Common/CenterBox';
import { Dots } from 'react-activity';
import formValidation from './formValidation';

import * as videoContentsActions from '../../../store/videos/contents/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectVideoContent } from '../../../selectors/video';
import { SubmitButton } from '../../Common/Modal/modalActionButtons';
import { isEmptyObj } from '../../../helpers/sharedFunction';
import { cdnUrl } from '../../../helpers/file';
import colors from '../../CommonStyle/colors';

const URL_REGEXP =
  /(?:blob:)?(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/;

const FormVideoContentContent = ({ video, lang, closeModal }) => {
  const videoContentId = `${video.id}-${lang}`;
  const loading = useSelector((state) => state.VideoContent.loading);

  const [files, setFiles] = useState(null);
  const [errorFile, setErrorFile] = useState('');
  const videoContent = useSelector(selectVideoContent(videoContentId));
  const create = !videoContent;
  const editingVideoContent = videoContent || {};

  const handleFiles = (name, files) => {
    setFiles((state) => ({ ...state, [name]: files[0] }));
    setErrorFile('');
  };

  const handleRemoveFiles = (name) => {
    let newFiles = { ...files };
    delete newFiles[name];
    setFiles(isEmptyObj(newFiles) ? null : newFiles);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(videoContentsActions.fetchVideoContent(videoContentId));
  }, []);

  const handleSubmit = (values) => {
    const { published, ballonTags, videoUrl } = values;

    if (!videoUrl && !files?.videoFile)
      return setErrorFile('Insert a video file or URL, please!');

    dispatch(
      create
        ? videoContentsActions.createVideoContent(
            {
              id: videoContentId,
              ...values,
              videoId: video.id,
              lang,
              ...(files && { files }),
              published: published || false,
              ...(Array.isArray(ballonTags) &&
                !ballonTags.length && { ballonTags: deleteField() }),
            },
            video,
          )
        : videoContentsActions.updateVideoContent({
            id: videoContentId,
            ...values,
            ...(files && { files }),
            published: published || false,
            ...(Array.isArray(ballonTags) &&
              !ballonTags.length && { ballonTags: deleteField() }),
          }),
    );
    closeModal();
  };

  return (
    <Card>
      <CardBody>
        {loading ? (
          <CenterBox height={500}>
            <Dots color={colors.info} size={30} />
          </CenterBox>
        ) : (
          <Form
            initialValues={editingVideoContent}
            validationSchema={formValidation}
            onSubmit={handleSubmit}>
            {({ isValid, values }) => (
              <>
                <Collapse isOpen={!!errorFile}>
                  <Alert color="danger">{errorFile}</Alert>
                </Collapse>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="title">Video Title</Label>
                      <Input type="text" name="title" id="title" />
                      <FormErrorMessage name="title" />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label htmlFor="videoBridId">Brid ID</Label>
                      <Input
                        type="text"
                        name="videoBridId"
                        id="videoBridId"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <div
                        className="custom-control custom-switch mb-2"
                        dir="ltr">
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          id="published"
                          name="published"
                          checked={!!values.published}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="published">
                          Published?
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                  {editingVideoContent.wpPostId && (
                    <Col md="2">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-secondary"
                        onClick={() =>
                          window.open(
                            `https://www.mammacheapp.com/wp-admin/post.php?post=${editingVideoContent.wpPostId}&action=edit`,
                            '_blank',
                          )
                        }
                        disabled={loading}>
                        <i className="bx bxl-wordpress mr-1"></i>
                        Open old CMS
                      </button>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md="12">
                    <FieldArray
                      name="ballonTags"
                      render={(arrayHelpers) => {
                        const tags = values.ballonTags;
                        return (
                          <FormGroup>
                            <Label htmlFor="ballonTags">Ballon Tags</Label>
                            <button
                              type="button"
                              className="btn btn-sm btn-outline btn-light ml-4"
                              onClick={() =>
                                arrayHelpers.push({
                                  order: 1,
                                  title: '',
                                })
                              }>
                              <i className="bx bx-plus font-size-11 align-middle"></i>{' '}
                              Add
                            </button>
                            {Array.isArray(tags) &&
                              tags.map((tag, index) => (
                                <Row key={index} className="mb-2">
                                  <Col md="2">
                                    <FormGroup>
                                      <Label
                                        htmlFor={`ballonTags.${index}.order`}>
                                        Order
                                      </Label>
                                      <InputGroup>
                                        <div className="input-group-append">
                                          <button
                                            className="btn btn-danger p-0"
                                            style={{ width: '31px' }}
                                            type="button"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }>
                                            <i className="bx bx-trash d-block font-size-15" />
                                          </button>
                                        </div>
                                        <Input
                                          type="number"
                                          step="1"
                                          min={1}
                                          max={8}
                                          name={`ballonTags.${index}.order`}
                                          id={`ballonTags.${index}.order`}
                                        />
                                        <FormErrorMessage
                                          name={`ballonTags.${index}.order`}
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </Col>
                                  <Col md="5">
                                    <FormGroup>
                                      <Label
                                        htmlFor={`ballonTags.${index}.title`}>
                                        Title
                                      </Label>
                                      <Input
                                        type="text"
                                        name={`ballonTags.${index}.title`}
                                        id={`ballonTags.${index}.title`}
                                      />
                                      <FormErrorMessage
                                        name={`ballonTags.${index}.title`}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              ))}
                          </FormGroup>
                        );
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="videoFile">Video</Label>
                          <FileUpload
                            name="videoFile"
                            accept={{
                              'video/mp4': ['.mp4', '.m4v'],
                            }}
                            maxFiles={1}
                            maxSize={100 * 1024 * 1024} //100 MB
                            uploadMessage="Drop video here or click to upload."
                            handleFiles={handleFiles}
                            handleRemoveFiles={handleRemoveFiles}
                            existingFile={editingVideoContent.videoFile}
                            showPreview
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="videoUrl">Video URL</Label>
                          <Input
                            type="textarea"
                            name="videoUrl"
                            id="videoUrl"
                            style={{ height: '150px' }}
                          />
                          <FormErrorMessage name="videoUrl" />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                    <Collapse
                      isOpen={URL_REGEXP.test(
                        files?.videoFile?.preview || values.videoUrl,
                      )}>
                      <ReactPlayer
                        url={
                          files?.videoFile?.preview || cdnUrl(values.videoUrl)
                        }
                        width="100%"
                        controls
                      />
                    </Collapse>
                  </Col>
                </Row>
                <div className="modal-footer">
                  <SubmitButton
                    disabled={!isValid || loading}
                    create={create}
                  />
                </div>
              </>
            )}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};

export default FormVideoContentContent;
