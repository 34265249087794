import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_VIDEO_STATE,
});

export const createVideo = (video) => ({
  type: types.CREATE_VIDEO.REQUEST,
  video,
});

export const createVideoSuccess = (video) => ({
  type: types.CREATE_VIDEO.SUCCESS,
  video,
});

export const createVideoFailure = (error) => ({
  type: types.CREATE_VIDEO.FAILURE,
  error,
});

export const updateVideo = (video) => ({
  type: types.UPDATE_VIDEO.REQUEST,
  video,
});

export const updateVideoSuccess = () => ({
  type: types.UPDATE_VIDEO.SUCCESS,
});

export const updateVideoFailure = (error) => ({
  type: types.UPDATE_VIDEO.FAILURE,
  error,
});

export const syncVideos = () => ({
  type: types.SYNC_VIDEOS.REQUEST,
});

export const syncVideosSuccess = (videos) => ({
  type: types.SYNC_VIDEOS.SUCCESS,
  videos,
});

export const syncVideosFailure = (error) => ({
  type: types.SYNC_VIDEOS.FAILURE,
  error,
});

export const fetchVideo = (videoId) => ({
  type: types.FETCH_VIDEO.REQUEST,
  videoId,
});

export const fetchVideoSuccess = (video) => ({
  type: types.FETCH_VIDEO.SUCCESS,
  video,
});

export const fetchVideoFailure = (error) => ({
  type: types.FETCH_VIDEO.FAILURE,
  error,
});
