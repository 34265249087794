import React from 'react';
import { Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import { FieldArray } from 'formik';
import { Form, Input } from '@availity/form';
import { Date } from '../../lib/form-field/';
import '@availity/yup';
import formValidation from './formValidation';
import FormErrorMessage from '../Common/FormErrorMessage';
import * as membersActions from '../../store/members/actions';
import { useDispatch } from 'react-redux';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import moment from 'moment';

const FormMember = ({ member, loading, create, closeModal }) => {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(
      create
        ? membersActions.createMember(values)
        : membersActions.updateMember({
            id: member.id,
            ...values,
          }),
    );
    closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={member || {}}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values }) => (
            <>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="firstName">First Name</Label>
                    <Input type="text" name="firstName" id="firstName" />
                    <FormErrorMessage name="firstName" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="lastName">Last Name</Label>
                    <Input type="text" name="lastName" id="lastName" />
                    <FormErrorMessage name="lastName" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="email">Email</Label>
                    <Input type="text" name="email" id="email" />
                    <FormErrorMessage name="email" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="lang">Language</Label>
                    <Input type="text" name="lang" id="lang" disabled />
                    <FormErrorMessage name="lang" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Date
                    id="birthdate"
                    name="birthdate"
                    format="DD/MM/YYYY"
                    max={moment()}
                  />
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="userSiteId">User Site Id</Label>
                    <Input
                      type="text"
                      name="userSiteId"
                      id="userSiteId"
                      disabled
                    />
                    <FormErrorMessage name="userSiteId" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="userLoginId">User Login Id</Label>
                    <Input
                      type="text"
                      name="userLoginId"
                      id="userLoginId"
                      disabled
                    />
                    <FormErrorMessage name="userLoginId" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="oneSignalExternalId">
                      OneSignal External ID
                    </Label>
                    <Input
                      type="text"
                      name="oneSignalExternalId"
                      id="oneSignalExternalId"
                      disabled
                    />
                    <FormErrorMessage name="oneSignalExternalId" />
                  </FormGroup>
                </Col>
                <Col md="12" className="mt-2">
                  <FieldArray
                    name="childrenBirthdates"
                    render={(arrayHelpers) => {
                      const { childrenBirthdates } = values;
                      return (
                        <FormGroup>
                          <Label htmlFor="childrenBirthdates">
                            Children Birthdates
                          </Label>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline btn-light ml-4"
                            onClick={() => arrayHelpers.push(moment())}>
                            <i className="bx bx-plus font-size-11 align-middle"></i>{' '}
                            Add
                          </button>
                          {Array.isArray(childrenBirthdates) &&
                            childrenBirthdates.map(
                              (childrenBirthdate, index) => (
                                <Row key={index} className="mb-2">
                                  <Col md="9">
                                    <Date
                                      id={`childrenBirthdates.${index}`}
                                      name={`childrenBirthdates.${index}`}
                                      label="Birthdate"
                                      format="DD/MM/YYYY"
                                      max={moment()}
                                      addonType="prepend">
                                      <button
                                        className="btn btn-danger p-0"
                                        style={{ width: '31px' }}
                                        type="button"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }>
                                        <i className="bx bx-trash d-block font-size-15" />
                                      </button>
                                    </Date>
                                  </Col>
                                </Row>
                              ),
                            )}
                        </FormGroup>
                      );
                    }}
                  />
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton disabled={!isValid || loading} create={create} />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormMember;
