const types = {
  RESET_DASHBOARD_STATE: 'RESET_DASHBOARD_STATE',
  SET_DASHBOARD_FILTERS: {
    REQUEST: 'SET_DASHBOARD_FILTERS.REQUEST',
    SUCCESS: 'SET_DASHBOARD_FILTERS.SUCCESS',
    FAILURE: 'SET_DASHBOARD_FILTERS.FAILURE',
  },
};

export default types;
