import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Alert,
  Collapse,
} from 'reactstrap';
import Select from '@availity/select';
import { DateRange } from '@availity/date';
import { Form, Input } from '@availity/form';
import FormErrorMessage from '../Common/FormErrorMessage';
import FileUpload from '../Common/FileUpload';
import formValidation from './formValidation';

import * as contestsActions from '../../store/contests/actions';
import { useDispatch } from 'react-redux';

import langs from '../../config/allowedLanguages';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { isEmptyObj } from '../../helpers/sharedFunction';

import '@availity/date/styles.scss';

const FormContest = ({ loading, contest, create, closeModal }) => {
  const [files, setFiles] = useState(null);
  const [errorFile, setErrorFile] = useState('');

  const handleFiles = (name, files) => {
    setFiles((state) => ({ ...state, [name]: files[0] }));
    setErrorFile('');
  };

  const handleRemoveFiles = (name) => {
    let newFiles = { ...files };
    delete newFiles[name];
    setFiles(isEmptyObj(newFiles) ? null : newFiles);
  };

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    if (!contest?.image && !files?.image)
      return setErrorFile('Insert contest image, please!');

    const { startDate, endDate, showEndDate } = values.dates;
    delete values.dates;

    dispatch(
      create
        ? contestsActions.createContest({
            ...values,
            startDate,
            endDate,
            files,
            showEndDate: showEndDate ?? false,
          })
        : contestsActions.updateContest({
            id: contest.id,
            ...values,
            startDate,
            endDate,
            ...(files && { files }),
          }),
    );
    closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{
            ...contest,
            dates: {
              startDate: contest?.startDate,
              endDate: contest?.endDate,
            },
          }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values }) => (
            <>
              <Collapse isOpen={!!errorFile}>
                <Alert color="danger">{errorFile}</Alert>
              </Collapse>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input type="textarea" name="name" id="name" />
                    <FormErrorMessage name="name" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="lang">Language</Label>
                    <Select name="lang" id="lang" options={langs} />
                    <FormErrorMessage name="lang" />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="dates">Date</Label>
                    <DateRange
                      id="dates"
                      name="dates"
                      datepickerProps={{
                        displayFormat: 'DD/MM/YYYY',
                      }}
                    />
                    <FormErrorMessage name="dates" />
                  </FormGroup>
                </Col>
                <Col md="1">
                  <FormGroup>
                    <Label htmlFor="priority">Priority</Label>
                    <Input
                      id="priority"
                      name="priority"
                      type="number"
                      step="1"
                      min={1}
                    />
                    <p className="font-size-12 text-muted">
                      1 = highest priority
                    </p>
                    <FormErrorMessage name="priority" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="image">Image</Label>
                    <FileUpload
                      name="image"
                      accept={{
                        'image/*': ['.png', '.jpeg', '.jpg'],
                      }}
                      maxFiles={1}
                      uploadMessage="Drop image here or click to upload."
                      handleFiles={handleFiles}
                      handleRemoveFiles={handleRemoveFiles}
                      showPreview
                      existingFile={values?.image}
                    />
                  </FormGroup>
                </Col>
                <Col md="5">
                  <FormGroup>
                    <Label htmlFor="link">Link</Label>
                    <Input type="textarea" name="link" id="link" />
                    <FormErrorMessage name="link" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="showEndDate"
                        name="showEndDate"
                        checked={!!values.showEndDate}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="showEndDate">
                        Show End Date?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton disabled={!isValid || loading} create={create} />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormContest;
