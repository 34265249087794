import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Collapse,
  Alert,
  Button,
} from 'reactstrap';
import FileUpload from '../Common/FileUpload';
import { Form, Input } from '@availity/form';
import Select from '@availity/select';
import { Switch } from '../../lib/form-field';
import '@availity/yup';
import formValidation from './formValidation';
import TreeSelector from '../Common/TreeSelector';
import FormErrorMessage from '../Common/FormErrorMessage';
import * as pushNotificationsActions from '../../store/pushNotifications/actions';
import * as articleContentsActions from '../../store/articles/contents/actions';
import * as videoContentsActions from '../../store/videos/contents/actions';
import * as symptomContentsActions from '../../store/symptoms/contents/actions';
import * as countriesActions from '../../store/countries/actions';
import { selectPushNotificationContent } from '../../selectors/pushNotification';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { isEmptyObj } from '../../helpers/sharedFunction';
import timeTags from '../../config/timeTags';
import { LINK_PRESETS } from '../../config/pushNotifications';
import moment from 'moment';
import PushNotificationPreview from './PushNotificationPreview';

const URL_REGEX =
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;

const FormPushNotification = ({
  pushNotification,
  content,
  contentType,
  loading,
  create,
  closeModal,
}) => {
  const [files, setFiles] = useState(null);
  const [errorFile, setErrorFile] = useState('');
  const [lang, setLang] = useState(pushNotification?.lang);
  const [tags, setTags] = useState(pushNotification?.timeTags || []);

  const countries = useSelector((state) => state.Country.countries);

  const contentId = content && lang ? `${content.id}-${lang}` : null;
  const pushNotificationContent = useSelector(
    selectPushNotificationContent(contentId, contentType),
  );

  const tagsLabel = tags.length ? `(${tags.slice(0, 4).join(', ')}, ...)` : '';

  const handleFiles = (name, files) => {
    setFiles((state) => ({ ...state, [name]: files[0] }));
    setErrorFile('');
  };

  const handleRemoveFiles = (name) => {
    let newFiles = { ...files };
    delete newFiles[name];
    setFiles(isEmptyObj(newFiles) ? null : newFiles);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(countriesActions.syncCountries());
  }, []);

  useEffect(() => {
    if (!contentId) return;

    contentType === 'article' &&
      dispatch(articleContentsActions.fetchArticleContent(contentId));
    contentType === 'video' &&
      dispatch(videoContentsActions.fetchVideoContent(contentId));
    contentType === 'symptom' &&
      dispatch(symptomContentsActions.fetchSymptomContent(contentId));
  }, [contentId, contentType, lang]);

  const handleLangChange = (value, setFieldValue) => {
    setFieldValue('lang', value, false);
    setLang(value);
  };

  const handleSubmit = (values) => {
    if (!tags.length)
      return setErrorFile('Insert at least one time tag, please!');
    if (
      !pushNotification?.image &&
      !pushNotificationContent?.image &&
      !files?.image
    )
      return setErrorFile('Insert image, please!');

    values.isMDFLandingPage = URL_REGEX.test(values.deeplink)
      ? values.isMDFLandingPage || false
      : false;

    values.sendDate = values.sendImmediately
      ? moment().utc().toDate()
      : moment(values.sendDate).utc().toDate();
    delete values.sendImmediately;

    dispatch(
      create
        ? pushNotificationsActions.createPushNotification({
            ...values,
            files,
            timeTags: tags,
            ...(pushNotificationContent?.image && {
              image: pushNotificationContent.image,
            }),
            lang,
          })
        : pushNotificationsActions.updatePushNotification({
            id: pushNotification.id,
            ...values,
            ...(files && { files }),
            timeTags: tags,
            lang,
          }),
    );
    closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{
            ...pushNotification,
            ...pushNotificationContent,
            lang,
          }}
          validationSchema={formValidation}
          enableReinitialize={true}
          onSubmit={handleSubmit}>
          {({ isValid, values, setFieldValue }) => (
            <>
              <Collapse isOpen={!!errorFile}>
                <Alert color="danger">{errorFile}</Alert>
              </Collapse>
              <Row>
                <Col md="8">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="name">Name</Label>
                        <Input type="text" name="name" id="name" />
                        <FormErrorMessage name="name" />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="lang">Language</Label>
                        <Select
                          name="lang"
                          id="lang"
                          isSearchable
                          onChange={(value) =>
                            handleLangChange(value, setFieldValue)
                          }
                          options={countries.map(({ name, lang }) => ({
                            label: name,
                            value: lang,
                          }))}
                        />
                        <FormErrorMessage name="lang" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label htmlFor="timeTags">
                          Time Tags{' '}
                          <span className="font-size-11 text-muted truncate truncate-2">
                            {tagsLabel}
                          </span>
                        </Label>
                        <TreeSelector
                          tree={timeTags}
                          selected={pushNotification?.timeTags}
                          onCheck={(checked) => setTags(checked)}
                          checkModel="leaf"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="title">Title</Label>
                        <Input type="text" name="title" id="title" />
                        <FormErrorMessage name="title" />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="message">Message</Label>
                        <Input type="textarea" name="message" id="message" />
                        <FormErrorMessage name="message" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="7">
                      <FormGroup>
                        <Label htmlFor="deeplink">Deeplink</Label>
                        {!content && (
                          <div>
                            {LINK_PRESETS.map(({ label, link }, index) => (
                              <Button
                                key={index}
                                color="secondary"
                                className="btn btn-sm waves-effect mb-1"
                                onClick={() => setFieldValue('deeplink', link)}
                                style={{ marginRight: '10px' }}>
                                {label}
                              </Button>
                            ))}
                          </div>
                        )}
                        <Input type="textarea" name="deeplink" id="deeplink" />
                        <FormErrorMessage name="deeplink" />
                      </FormGroup>
                      <Collapse isOpen={URL_REGEX.test(values.deeplink)}>
                        <Switch
                          name="isMDFLandingPage"
                          label="MDF Landing Page?"
                          defaultChecked={values?.isMDFLandingPage}
                        />
                      </Collapse>
                    </Col>
                    <Col md="5">
                      <FormGroup>
                        <Label htmlFor="image">Image</Label>
                        <FileUpload
                          name="image"
                          accept={{
                            'image/*': ['.png', '.jpeg', '.jpg'],
                          }}
                          maxFiles={1}
                          uploadMessage="Drop image here or click to upload."
                          handleFiles={handleFiles}
                          handleRemoveFiles={handleRemoveFiles}
                          showPreview
                          existingFile={
                            pushNotification?.image ||
                            pushNotificationContent?.image
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <Switch
                        name="sendImmediately"
                        label="Send Immediately?"
                        defaultChecked={values?.sendImmediately}
                      />
                    </Col>
                    <Col md="4">
                      <Collapse isOpen={!values?.sendImmediately}>
                        <FormGroup>
                          <Label htmlFor="sendDate">
                            Send at
                            {` ${
                              pushNotification?.sendDate
                                ? moment(pushNotification.sendDate).format(
                                    'DD/MM/YYYY HH:mm',
                                  )
                                : ''
                            }`}
                          </Label>
                          <Input
                            id="sendDate"
                            name="sendDate"
                            className="form-control"
                            type="datetime-local"
                          />
                        </FormGroup>
                      </Collapse>
                    </Col>
                  </Row>
                </Col>
                <Col md="4">
                  <div style={{ position: 'sticky', top: '0' }}>
                    <PushNotificationPreview
                      title={values?.title}
                      message={values?.message}
                      image={
                        files?.image?.preview ||
                        pushNotification?.image ||
                        pushNotificationContent?.image
                      }
                    />
                  </div>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton disabled={!isValid || loading} create={create} />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormPushNotification;
